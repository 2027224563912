import React from 'react';
import { useTranslation } from 'react-i18next';
import { URL_FRO } from '../../../constants';

export default function Footer() {
    const { t } = useTranslation();

    return (
        <footer id='unalBottom' className='clear container-fluid'>
            <div className='row'>
                <nav className='col-lg-3 col-md-3 col-sm-4 col-6 gobiernoLinea'>
                    <a href='http://www.legal.unal.edu.co' rel='noreferrer' target='_top'>{t('footer.legalRegime')}</a> 
                    <a href='http://personal.unal.edu.co' rel='noreferrer' target='_top'>{t('footer.humanTalent')}</a>
                    <a href='https://portaladquisiciones.unal.edu.co/' rel='noreferrer' target='_top'>{t('footer.hiring')}</a>
                    <a href='http://personal.unal.edu.co' rel='noreferrer' target='_top'>{t('footer.jobVacancies')}</a>
                    <a href='http://launalcuenta.unal.edu.co/' rel='noreferrer' target='_top'>{t('footer.accountability')}</a>
                    <a href='http://docentes.unal.edu.co/concurso-profesoral/' rel='noreferrer' target='_top'>{t('footer.teachingCompetition')}</a>
                    <a href='http://www.pagovirtual.unal.edu.co/' rel='noreferrer' target='_top'>{t('footer.virtualPayment')}</a>
                    <a href='http://controlinterno.unal.edu.co/' rel='noreferrer' target='_top'>{t('footer.internalControl')}</a>
                    <a href='http://siga.unal.edu.co' rel='noreferrer' target='_top'>{t('footer.quality')}</a>
                    <a href='http://unal.edu.co/buzon-de-notificaciones/' rel='noreferrer' target='_self'>{t('footer.notificationMailbox')}</a>
                </nav>
                <nav className='col-lg-3 col-md-3 col-sm-4 col-6 gobiernoLinea'>
                    <a href='https://smartkey.xertica.com/cloudkey/a/unal.edu.co/user/login' rel='noreferrer' target='_top'>{t('footer.institutionalEmail')}</a>
                    <a href='index.html#' rel='noreferrer'>{t('footer.siteMap')}</a>
                    <a href='http://redessociales.unal.edu.co' rel='noreferrer' target='_top'>{t('footer.socialNetworks')}</a>
                    <a href='mailto:horus_fibog@unal.edu.co' rel='noreferrer'>{t('footer.faq')}</a>
                    <a href='http://quejasyreclamos.unal.edu.co/' rel='noreferrer' target='_self'>{t('footer.complaintsClaims')}</a>
                    <a href='http://unal.edu.co/atencion-en-linea/' rel='noreferrer' target='_self'>{t('footer.onlineAttention')}</a>
                    <a href='http://unal.edu.co/encuesta/' rel='noreferrer' target='_self'>{t('footer.survey')}</a>
                    <a href='mailto:horus_fibog@unal.edu.co' rel='noreferrer'>{t('footer.contactUs')}</a>
                    <a href='http://estadisticas.unal.edu.co/' rel='noreferrer' target='_top'>{t('footer.statistics')}</a>
                    <a href='index.html#' rel='noreferrer'>{t('footer.glossary')}</a>
                </nav>
                <div className='col-lg-4 col-md-4 col-sm-4 col-12 footer-info'>
                    <div className='row footer-info-spacing'>
                        <p className='col-lg-6 col-md-12 col-sm-12 col-6 contacto'>
                            <b>{t('footer.contactWebsite')}:</b><br />
                            <a title={t('footer.contactInfo')} href='mailto:horus_fibog@unal.edu.co'>horus_fibog@unal.edu.co</a><br />
                            Carrera 45 # 26-85<br />Edificio 453 (Aulas de Ingeniería) Oficina 206<br />Bogotá D.C., Colombia<br />(+57 1) 316 5000 Ext. 14084/11
                        </p>
                        <p className='col-lg-6 col-md-12 col-sm-12 col-6 derechos'>
                            <a href='https://unal.edu.co/fileadmin/user_upload/docs/legal.pdf' target='_blank' rel='noreferrer'>&copy; Copyright 2019</a><br />
                            <label dangerouslySetInnerHTML={{ __html: t('footer.author') }} /><br />
                            <a href={URL_FRO + '/wiki/'} rel='noreferrer'>{t('footer.wiki')}</a><br />
                            <label>{t('footer.update')}: 20/12/2024</label>
                        </p>
                    </div>
                </div>
                <div className='col-lg-2 col-md-2 col-sm-12 col-12 logos'>
                    <div className='row px-3'>
                        <div className='col-lg-6 col-md-12 col-sm-6 col-6 no-padding'>
                            <div className='row mx-1'>
                                <a className='col-md-12 col-sm-6 col-6' href='http://orgullo.unal.edu.co'>
                                    <img className='hidden-print' alt='Orgullo UN' src={require('../../../assets/images/unal/log_orgullo.png')} width='78'
                                        height='21' />
                                    <img className='d-none d-print-block' alt='Orgullo UN' src={require('../../../assets/images/unal/log_orgullo_black.png')}
                                        width='94' height='37' />
                                </a>
                                <a className='col-md-12 col-sm-6 col-6 imgAgencia' href='https://agenciadenoticias.unal.edu.co'>
                                    <img className='hidden-print' alt='Agencia de Noticias' src={require('../../../assets/images/unal/log_agenc.png')}
                                        width='94' height='25' />
                                    <img className='d-none d-print-block' alt='Agencia de Noticias'
                                        src={require('../../../assets/images/unal/log_agenc_black.png')} width='94' height='37' />
                                </a>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12 col-sm-6 col-6 no-padding'>
                            <div className='row mx-1'>
                                <a className='col-md-12 col-sm-6 col-6' href='https://www.gov.co/'>
                                    <img alt='Portal Único del Estado Colombiano' src={require('../../../assets/images/unal/log_gobiern.png')} width='67'
                                        height='51' />
                                </a>
                                <a className='col-md-12 col-sm-6 col-6' href='http://www.contaduria.gov.co/'>
                                    <img alt='Contaduría General de la República' src={require('../../../assets/images/unal/log_contra.png')} width='67'
                                        height='51' />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}