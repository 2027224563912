import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Grid } from '@mui/material';
import CardGraphics from '../../../../components/card/CardGraphics';
import Column from '../../../../components/graphics/Column';
import ColumnStacked from '../../../../components/graphics/ColumnStacked';
import BarGradient from '../../../../components/graphics/BarGradient';
import WordCloud from '../../../../components/graphics/WordCloud';
import { requestPost } from '../../../../../utils/request';
import { metricsExt, formatMessage, objectAssignExt } from '../../../../../utils/utils';
import { constants, URL_API_EXT, URL_API_INV } from '../../../../../constants';

function Projects(props) {
    const { extension, settings, setExtension, setSettings } = props;

    const { t } = useTranslation();

    const handleOnChange = (value, state) => {
        const newExtension = objectAssignExt(extension, {
            metrics: { projects: { [state]: value } },
        });
        setExtension(
            metricsExt(
                [state],
                extension.metrics.projects.source,
                extension.hierarchy,
                newExtension
            )
        );
    };
    useEffect(() => {
        setSettings({ backdrop: formatMessage(true, '', t('dependence.extension.consultingInfoProjects')) });
        if (extension.hierarchy.stepKey) {
            const hierarchy = {
                ...extension.hierarchy,
                resource: 'Proyectos',
            };
            Promise.all([
                requestPost(`${URL_API_EXT}/extension/projects`, { search: extension.search.search }),
                requestPost(`${URL_API_INV}/usability/resource`, { record: hierarchy }),
            ])
                .then(res => {
                    setExtension(
                        metricsExt(
                            Object.keys(extension.metrics.projects),
                            res[0].data,
                            hierarchy,
                            extension
                        )
                    );
                    setSettings({ backdrop: formatMessage(false, '', '') });
                })
                .catch(err => {
                    setSettings({
                        backdrop: formatMessage(false, '', ''),
                        snackbar: formatMessage(true, 'error', err),
                    });
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [extension.hierarchy.stepKey, extension.search.search]);
    return (
        <Box>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <CardGraphics
                        title={t('dependence.extension.projectsMain.numberProjects')}
                        tools={{
                            usability: true,
                            rangeTime: true,
                            gender: false,
                            type: false,
                            accumulate: true,
                            wordCloud: false,
                            graph: false,
                            chart: false,
                            download: true,
                            dialog: false,
                            dataTable: true
                        }}
                        infoDialog={{
                            label: t('dependence.extension.projectsMain.numberProjectsInfo'),
                            gif: '',
                            notice: t('dependence.extension.projectsMain.numberProjectsNotice')
                        }}
                        infoChart={{
                            data: extension.metrics.projects[constants.METRIC_COUNT_FIELD],
                            onChange: (value) => handleOnChange(value, constants.METRIC_COUNT_FIELD),
                            columns: {
                                axis: extension.filters.intervalTime.applied.value[settings.language === 'es' ? 1 : 2],
                                value: t('dependence.extension.projectsMain.numberProjectsValue')
                            },
                            formatValue: 'number',
                            sortField: 'axis'
                        }}
                    >
                        <Column
                            infoChart={{
                                data: extension.metrics.projects[constants.METRIC_COUNT_FIELD].processed,
                                colorGender: false,
                                axis: {
                                    xAxis: extension.filters.intervalTime.applied.value[settings.language === 'es' ? 1 : 2],
                                    yAxis: t('dependence.extension.projectsMain.numberProjectsAxisY')
                                },
                                formatValue: 'number',
                                label: false
                            }}
                        />
                    </CardGraphics>
                </Grid>
                <Grid item xs={12} md={6}>
                    <CardGraphics
                        title={t('dependence.extension.projectsMain.valueProjects')}
                        tools={{
                            usability: true,
                            rangeTime: true,
                            gender: false,
                            type: false,
                            accumulate: true,
                            wordCloud: false,
                            graph: false,
                            chart: false,
                            download: true,
                            dialog: false,
                            dataTable: true
                        }}
                        infoDialog={{
                            label: t('dependence.extension.projectsMain.valueProjectsInfo'),
                            gif: '',
                            notice: t('dependence.extension.projectsMain.valueProjectsNotice')
                        }}
                        infoChart={{
                            data: extension.metrics.projects[constants.METRIC_VALUE_FIELD],
                            onChange: (value) => handleOnChange(value, constants.METRIC_VALUE_FIELD),
                            columns: {
                                axis: extension.filters.intervalTime.applied.value[settings.language === 'es' ? 1 : 2],
                                value: t('dependence.extension.projectsMain.valueProjectsValue')
                            },
                            formatValue: 'money',
                            sortField: 'axis'
                        }}
                    >
                        <Column
                            infoChart={{
                                data: extension.metrics.projects[constants.METRIC_VALUE_FIELD].processed,
                                colorGender: false,
                                axis: {
                                    xAxis: extension.filters.intervalTime.applied.value[settings.language === 'es' ? 1 : 2],
                                    yAxis: t('dependence.extension.projectsMain.valueProjectsAxisY')
                                },
                                formatValue: 'money',
                                label: false
                            }}
                        />
                    </CardGraphics>
                </Grid>
            </Grid>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={3} sx={{ mt: 3 }}>
                <CardGraphics
                    title={t('dependence.extension.projectsMain.classificationProjects')}
                    tools={{
                        usability: true,
                        rangeTime: true,
                        gender: false,
                        type: false,
                        accumulate: true,
                        wordCloud: false,
                        graph: false,
                        chart: false,
                        download: true,
                        dialog: false,
                        dataTable: true
                    }}
                    infoDialog={{
                        label: t('dependence.extension.projectsMain.classificationProjectsInfo'),
                        gif: '',
                        notice: t('dependence.extension.projectsMain.classificationProjectsNotice')
                    }}
                    infoChart={{
                        data: extension.metrics.projects[constants.METRIC_CLASSIFICATION_FIELD],
                        onChange: (value) => handleOnChange(value, constants.METRIC_CLASSIFICATION_FIELD),
                        columns: {
                            axis: extension.filters.intervalTime.applied.value[settings.language === 'es' ? 1 : 2],
                            type: t('dependence.extension.projectsMain.classificationProjectsType'),
                            value: t('dependence.extension.projectsMain.classificationProjectsValue')
                        },
                        formatValue: 'number',
                        sortField: 'axis'
                    }}
                >
                    <ColumnStacked
                        infoChart={{
                            data: extension.metrics.projects[constants.METRIC_CLASSIFICATION_FIELD].processed,
                            colorGender: false,
                            axis: {
                                xAxis: extension.filters.intervalTime.applied.value[settings.language === 'es' ? 1 : 2],
                                yAxis: t('dependence.extension.projectsMain.classificationProjectsAxisY')
                            },
                        }}
                    />
                </CardGraphics>
            </Stack>
            <Grid container spacing={3} sx={{ mt: 0.5 }}>
                <Grid item xs={12} md={6}>
                    <CardGraphics
                        title={t('dependence.extension.projectsMain.managerProjects')}
                        tools={{
                            usability: true,
                            rangeTime: true,
                            gender: false,
                            type: false,
                            accumulate: false,
                            wordCloud: false,
                            graph: false,
                            chart: false,
                            download: true,
                            dialog: false,
                            dataTable: true
                        }}
                        infoDialog={{
                            label: t('dependence.extension.projectsMain.managerProjectsInfo'),
                            gif: '',
                            notice: t('dependence.extension.projectsMain.managerProjectsNotice')
                        }}
                        infoChart={{
                            data: extension.metrics.projects[constants.METRIC_TOP_FIELD],
                            onChange: (value) => handleOnChange(value, constants.METRIC_TOP_FIELD),
                            columns: {
                                type: t('dependence.extension.projectsMain.managerProjectsType'),
                                value: t('dependence.extension.projectsMain.managerProjectsValue')
                            },
                            formatValue: 'number',
                            sortField: 'value'
                        }}
                    >
                        <BarGradient
                            infoChart={{
                                data: extension.metrics.projects[constants.METRIC_TOP_FIELD].processed,
                                formatValue: 'number',
                                sortField: 'value'
                            }}
                        />
                    </CardGraphics>
                </Grid>
                <Grid item xs={12} md={6}>
                    <CardGraphics
                        title={t('dependence.extension.projectsMain.origenProjects')}
                        tools={{
                            usability: true,
                            rangeTime: true,
                            gender: false,
                            type: false,
                            accumulate: false,
                            wordCloud: false,
                            graph: false,
                            chart: false,
                            download: true,
                            dialog: false,
                            dataTable: true
                        }}
                        infoDialog={{
                            label: t('dependence.extension.projectsMain.origenProjectsInfo'),
                            gif: '',
                            notice: t('dependence.extension.projectsMain.origenProjectsNotice')
                        }}
                        infoChart={{
                            data: extension.metrics.projects[constants.METRIC_ORIGEN_FIELD],
                            onChange: (value) => handleOnChange(value, constants.METRIC_ORIGEN_FIELD),
                            columns: {
                                type: t('dependence.extension.projectsMain.origenProjectsType'),
                                value: t('dependence.extension.projectsMain.origenProjectsValue')
                            },
                            formatValue: 'number',
                            sortField: 'value'
                        }}
                    >
                        <BarGradient
                            infoChart={{
                                data: extension.metrics.projects[constants.METRIC_ORIGEN_FIELD].processed,
                                formatValue: 'number',
                                sortField: 'value'
                            }}
                        />
                    </CardGraphics>
                </Grid >
            </Grid >
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={3} sx={{ mt: 3 }}>
                <CardGraphics
                    title={t('dependence.extension.projectsMain.wordCloudProjects')}
                    tools={{
                        usability: true,
                        rangeTime: false,
                        gender: false,
                        type: false,
                        accumulate: false,
                        wordCloud: true,
                        graph: false,
                        chart: false,
                        download: true,
                        dialog: true,
                        dataTable: true
                    }}
                    infoDialog={{
                        label: t('dependence.extension.projectsMain.wordCloudProjectsInfo'),
                        gif: require('../../../../../assets/images/animation/nube.gif'),
                        notice: t('dependence.extension.projectsMain.wordCloudProjectsNotice')
                    }}
                    infoChart={{
                        data: extension.metrics.projects[constants.METRIC_WORDCLOUD_FIELD],
                        onChange: (value) => handleOnChange(value, constants.METRIC_WORDCLOUD_FIELD),
                        columns: {
                            type: '',
                            value: '',
                        },
                        formatValue: 'number',
                        sortField: 'value'
                    }}
                >
                    <WordCloud
                        infoChart={{
                            data: extension.metrics.projects[constants.METRIC_WORDCLOUD_FIELD].processed.slice(0, 400),
                        }}
                    />
                </CardGraphics>
            </Stack>
        </Box>
    );
}
const mapStateToProps = (state) => ({
    extension: state.extension,
    settings: state.settings
});
const mapDispatchToProps = (dispatch) => ({
    setExtension(state) {
        dispatch({
            type: 'SET_EXTENSION',
            state,
        });
    },
    setSettings(state) {
        dispatch({
            type: 'SET_SETTINGS',
            state,
        });
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(Projects);