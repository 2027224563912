import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Avatar } from '@mui/material';
import { Person3TwoTone, PersonOutlineTwoTone } from '@mui/icons-material';
import CardGraphics from '../../../../components/card/CardGraphics';
import PieDonut from '../../../../components/graphics/PieDonut';
import DataTable from '../../../../components/DataTable';
import { formatMGroups } from '../../../../../utils/utils';
import { requestPost } from '../../../../../utils/request';
import { constants, URL_API_INV } from '../../../../../constants';

function Members(props) {
    const { investigation, setInvestigation } = props;
    const { t } = useTranslation();
    const group = investigation.metrics.general[constants.METRIC_GROUPS_INFO_FIELD].processed;
    const handleOnChange = (value, state) => { };

    const columnsMembers = [
        {
            field: constants.AUTHOR_IMAGE_FIELD,
            headerName: '',
            width: 50,
            renderCell: (params) => (
                <Avatar
                    sx={{
                        width: 30,
                        height: 30,
                        bgcolor: theme =>
                            theme.palette[params.row[constants.AUTHOR_GENDER_FIELD] === 'F'
                                ? 'female'
                                : params.row[constants.AUTHOR_GENDER_FIELD] === 'M'
                                    ? 'male'
                                    : 'info'].main,
                    }}
                >
                    {params.row[constants.AUTHOR_IMAGE_FIELD] ? (
                        <Box
                            component='img'
                            width='100%'
                            height='100%'
                            src={
                                params.row[constants.AUTHOR_IMAGE_FIELD].includes('http')
                                    ? params.row[constants.AUTHOR_IMAGE_FIELD]
                                    : require('../../../../../assets/images/' + params.row[constants.AUTHOR_IMAGE_FIELD])
                            }
                        />
                    ) : (
                        <Box
                            component={
                                params.row[constants.AUTHOR_GENDER_FIELD] === 'F'
                                    ? Person3TwoTone
                                    : PersonOutlineTwoTone
                            }
                        />
                    )}
                </Avatar>
            ),
        },
        {
            field: constants.AUTHOR_NAME_FIELD,
            headerName: t('dependence.investigation.researchGroups.name'),
            flex: 1
        },
        {
            field: constants.AUTHOR_POSITION_FIELD,
            headerName: t('dependence.investigation.researchGroups.memberType'), flex: 1,
            renderCell: (params) => t('components.graphics.' + params.row[constants.AUTHOR_POSITION_FIELD]),
        }
    ];

    useEffect(() => {
        const hierarchy = {
            ...investigation.hierarchy,
            resource: 'Miembros del Grupo'
        };
        setInvestigation({ hierarchy: hierarchy });
        requestPost(`${URL_API_INV}/usability/resource`, { record: hierarchy });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <CardGraphics
                        title={t('dependence.investigation.researchGroups.membersType')}
                        tools={{
                            usability: true,
                            rangeTime: false,
                            gender: false,
                            type: false,
                            accumulate: false,
                            wordCloud: false,
                            graph: false,
                            download: true,
                            dialog: false,
                            dataTable: false
                        }}
                        infoDialog={{
                            label: '',
                            gif: '',
                            notice: ''
                        }}
                        infoChart={{
                            data: investigation.metrics.general[constants.METRIC_GROUPS_INFO_FIELD],
                            onChange: (value) => handleOnChange(value, constants.GROUP_MEMBERS_FIELD),
                            columns: {
                                type: t('dependence.investigation.researchGroups.membersType'),
                                value: t('dependence.investigation.researchGroups.amountMembers'),
                            },
                            formatValue: 'number',
                            sortField: 'value'
                        }}
                    >
                        <PieDonut
                            infoChart={{ data: formatMGroups(group[constants.GROUP_MEMBERS_FIELD], t) }}
                        />
                    </CardGraphics>
                </Grid>
                <Grid item xs={12} md={6} >
                    <CardGraphics
                        title={t('dependence.investigation.researchGroups.groupMembersList')}
                        tools={{
                            usability: true,
                            rangeTime: false,
                            gender: false,
                            type: false,
                            accumulate: false,
                            wordCloud: false,
                            chart: false,
                            download: true,
                            dialog: false,
                            dataTable: false
                        }}
                        infoDialog={{
                            label: '',
                            gif: '',
                            notice: ''
                        }}
                        infoChart={{
                            data: investigation.metrics.general[constants.METRIC_GROUPS_INFO_FIELD],
                            onChange: (value) => handleOnChange(value, constants.GROUP_MEMBERS_FIELD),
                            columns: {
                                type: '',
                                value: '',
                            },
                            formatValue: 'number',
                            sortField: 'value'
                        }}
                    >
                        <DataTable
                            id={constants.AUTHOR_NAME_FIELD}
                            rows={group[constants.GROUP_MEMBERS_FIELD]}
                            columns={columnsMembers}
                            sortModel={[{ field: 'name', sort: 'asc' }]}
                        />
                    </CardGraphics>
                </Grid>
            </Grid>
        </Box>
    )
}
const mapStateToProps = (state) => ({
    investigation: state.investigation,
});
const mapDispatchToProps = (dispatch) => ({
    setInvestigation(state) {
        dispatch({
            type: 'SET_INVESTIGATION',
            state,
        });
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(Members);