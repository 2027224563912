import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Stack, IconButton, Badge, styled, SpeedDial } from '@mui/material';
import { RouteTwoTone, PersonPinCircleTwoTone, Cached } from '@mui/icons-material';
import Tooltip from '../../../../../components/Tooltip';
import DataTable from '../../../../../components/DataTable';
import Dialog from '../../../../../components/Dialog';
import Timeline from '../../../../../components/timeline/Timeline';
import LeafletMap from '../../../../../components/maps/LeafletMap';
import { formatDate, formatMessage, formatTLUsability } from '../../../../../../utils/utils';
import { requestPost } from '../../../../../../utils/request';
import { constants, URL_API_INV } from '../../../../../../constants';


const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -6,
        top: 10,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));
function ReportUser(props) {
    const { login, setLogin, setSettings } = props;
    const [values, setValues] = useState({
        data: [],
        center: null,
        dialog: false
    });
    const navigate = useNavigate();
    const handleClose = () => {
        setValues({
            ...values,
            dialog: false
        });
    }
    const handleClick = (data) => {
        setValues({
            ...values,
            data: formatTLUsability(data),
            dialog: true
        });
    }
    const handleLocation = (data) => {
        setValues({
            ...values,
            center: data
        });
    }
    const handleRequest = () => {
        setSettings({ backdrop: formatMessage(true, '', 'Consultando información del reporte de usuarios.') });
        Promise.all([
            requestPost(`${URL_API_INV}/usability/report/user`, {}),
        ])
            .then(res => {
                if (res[0].data.access && res[0].data.infoUser.dependence === 'Administrador') {
                    setLogin({
                        usability: {
                            reportUser: {
                                table: res[0].data.infoUsability,
                                location: res[0].data.infoUsabilityLocation
                            }
                        }
                    });
                } else if (res[0].data.access) {
                    navigate('/login/Inicio de sesión', { state: { key: 'hierarchy' } });
                } else {
                    sessionStorage.removeItem('log-ide');
                    navigate('/login/Inicio de sesión', { state: { key: 'hierarchy' } });
                }
                setSettings({ backdrop: formatMessage(false, '', '') });
            })
            .catch(err => {
                setSettings({
                    snackbar: formatMessage(true, 'error', err),
                    backdrop: formatMessage(false, '', '')
                });
            });
    }
    const columnsTable = [
        {
            field: 'date', headerName: 'Fecha', flex: 1,
            valueGetter: (params) => formatDate(params.row[constants.USABILITY_DATE_FIELD])
        },
        {
            field: constants.USABILITY_INFO_FIELD, headerName: 'Ip Red', flex: 1,
            valueGetter: (params) => params.row[constants.USABILITY_INFO_FIELD].ip
        },
        {
            field: constants.USABILITY_INFO_FIELD + '.country_name', headerName: 'País', flex: 1,
            valueGetter: (params) => params.row[constants.USABILITY_INFO_FIELD].error ? params.row[constants.USABILITY_INFO_FIELD].reason : params.row[constants.USABILITY_INFO_FIELD].country_name
        },
        {
            field: constants.USABILITY_INFO_FIELD + '.region', headerName: 'Región', flex: 1,
            valueGetter: (params) => params.row[constants.USABILITY_INFO_FIELD].error ? params.row[constants.USABILITY_INFO_FIELD].reason : params.row[constants.USABILITY_INFO_FIELD].region
        },
        {
            field: constants.USABILITY_INFO_FIELD + '.city', headerName: 'Ciudad', flex: 1,
            valueGetter: (params) => params.row[constants.USABILITY_INFO_FIELD].error ? params.row[constants.USABILITY_INFO_FIELD].reason : params.row[constants.USABILITY_INFO_FIELD].city
        },
        {
            field: 'options', headerName: 'Opciones', flex: 1, align: 'center',
            valueGetter: (params) => params.row[constants.USABILITY_INFO_FIELD].error ? params.row[constants.USABILITY_INFO_FIELD].reason : params.row.timeLine.length,
            renderCell: (params) => (
                <Stack direction='row' spacing={0.5}>
                    {!params.row[constants.USABILITY_INFO_FIELD].error && <Tooltip title='Localizar usuario en el mapa'>
                        <IconButton
                            aria-label='Localizar usuario en el mapa'
                            onClick={() => handleLocation([params.row[constants.USABILITY_INFO_FIELD].latitude, params.row[constants.USABILITY_INFO_FIELD].longitude])}
                        >
                            <PersonPinCircleTwoTone />
                        </IconButton>
                    </Tooltip>}
                    <Tooltip title='Trazabilidad del usuario en HORUS'>
                        <IconButton
                            aria-label='Trazabilidad del usuario en HORUS'
                            onClick={() => handleClick(params.row.timeLine)}
                        >
                            <StyledBadge badgeContent={params.row.timeLine.length} color='primary'>
                                <RouteTwoTone />
                            </StyledBadge>
                        </IconButton>
                    </Tooltip>
                </Stack>
            )
        },
    ];
    useEffect(() => {
        handleRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Box sx={{ m: 2 }}>
            <Tooltip title='Presione para recargar la información de usabilidad.'>
                <SpeedDial
                    ariaLabel='requestUsability'
                    sx={{ position: 'relative', top: 10, right: 10 }}
                    direction='left'
                    icon={<Cached />}
                    onClick={() => handleRequest()}
                />
            </Tooltip>
            <Stack direction={{ xs: 'column' }} spacing={3}>
                <LeafletMap
                    data={login.usability.reportUser.location}
                    center={values.center}
                    handleClick={handleClick}
                    handleDefault={() => setValues({ ...values, center: null })}
                />
                <DataTable
                    id='_id'
                    rows={login.usability.reportUser.table}
                    columns={columnsTable}
                    sortModel={[{ field: 'date', sort: 'desc' }]}
                    fileName='Reporte_visitas_usabilidad'
                />
            </Stack>
            <Dialog
                _id={null}
                open={values.dialog}
                title='Trazabilidad de usuario en HORUS'
                width='md'
                handleClose={handleClose}
                handleBack={{
                    active: false,
                    label: '',
                    function: null
                }}
                handleNext={{
                    active: false,
                    label: '',
                    function: null
                }}
            >
                <Timeline
                    data={values.data}
                    handleClick={null}
                />
            </Dialog>
        </Box>
    );
}
const mapStateToProps = (state) => ({
    login: state.login,
});
const mapDispatchToProps = (dispatch) => ({
    setLogin(state) {
        dispatch({
            type: 'SET_LOGIN',
            state,
        });
    },
    setSettings(state) {
        dispatch({
            type: 'SET_SETTINGS',
            state,
        });
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(ReportUser);