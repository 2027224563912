import { createStore } from 'redux';
import dayjs from 'dayjs';
import { objectAssignInv, objectAssignExt, objectAssignLog } from '../utils/utils';
import { constants } from '../constants';

const optionsGender = [
    { label_es: 'Ambos géneros', label_en: 'Both genres', value: constants.METRIC_BOTH_FIELD },
    { label_es: 'Femenino', label_en: 'Female', value: constants.METRIC_FEMALE_FIELD },
    { label_es: 'Masculino', label_en: 'Male', value: constants.METRIC_MALE_FIELD }
];

const optionsType = [
    { label_es: 'Todos', label_en: 'All', value: constants.METRIC_TYPE_ALL_FIELD },
    { label_es: 'Revistas', label_en: 'Journals', value: constants.METRIC_TYPE_JOURNALS_FIELD },
    { label_es: 'Conferencias', label_en: 'Conferences', value: constants.METRIC_TYPE_CONFERENCE_FIELD },
    { label_es: 'Tesis', label_en: 'Thesis', value: constants.METRIC_TYPE_THESIS_FIELD }
];

const optionsWordCloudType = [
    { label_es: 'Temas primer nivel', label_en: 'First level topics', value: constants.METRIC_SUBJECTS_FIELD },
    { label_es: 'Temas segundo nivel', label_en: 'Second level topics', value: constants.METRIC_TOPICS_FIELD },
    { label_es: 'Palabras', label_en: 'Words', value: constants.METRIC_WORDCLOUD_FIELD }
];

export const optionsWordCloudLanguage = [
    { label_es: 'Español', label_en: 'Spanish', value: true },
    { label_es: 'Ingles', label_en: 'English', value: false }
];

const optionsGraphType = [
    { label_es: 'Fuerza', label_en: 'Force', value: constants.GRAPHS_TYPE.FORCE },
    { label_es: 'Arco', label_en: 'Arc', value: constants.GRAPHS_TYPE.ARC },
    { label_es: 'Acorde', label_en: 'Chord', value: constants.GRAPHS_TYPE.CHORD }
];

export const optionsInterval = [
    { label_es: 'Anual', label_en: 'Annual', value: ['year', 'Año', 'Year'] },
    { label_es: 'Semestral', label_en: 'Biannual', value: ['semester', 'Semestre', 'Semester'] },
    { label_es: 'Mensual', label_en: 'Monthly', value: ['month', 'Mes', 'Month'] }
];

const optionsChart = [
    { label_es: 'Cantidad', label_en: 'Quantity', value: 'quantity', graph: [] },
    { label_es: 'Proporción', label_en: 'Proportion', value: 'proportion', graph: [] },
];

const defaultTools = {
    gender: { value: optionsGender[0], options: optionsGender },
    type: { value: optionsType[0], options: optionsType },
    accumulate: { value: false },
    wordCloudType: { value: optionsWordCloudType[0], options: optionsWordCloudType },
    wordCloudLanguage: { value: optionsWordCloudLanguage[0], options: optionsWordCloudLanguage },
    graphType: { value: optionsGraphType[0], options: optionsGraphType },
    graphNumber: { value: 25 },
    chart: { value: optionsChart[0], options: optionsChart },
    dialog: { value: false },
    dataTable: { value: false }
};

export const graphDefault = {
    nodes: [],
    edges: [],
    links: []
};

export const ldaDefault = {
    'mdsDat': {
        'x': [],
        'y': [],
        'topics': [],
        'cluster': [],
        'Freq': []
    },
    'tinfo': {
        'Term': [],
        'Freq': [],
        'Total': [],
        'Category': [],
        'logprob': [],
        'loglift': []
    },
    'token.table': {
        'Topic': [],
        'Freq': [],
        'Term': []
    },
    'R': 0,
    'lambda.step': 0,
    'plot.opts': {
        'xlab': 'PC1',
        'ylab': 'PC2'
    },
    'topic.order': []
};

export const groupDefault = {
    [constants.GROUP_ID_FIELD]: '',
    [constants.GROUP_NAME_FIELD]: '',
    [constants.GROUP_EMAIL_FIELD]: '',
    [constants.GROUP_STATE_FIELD]: '',
    [constants.GROUP_URL_FIELD]: '',
    [constants.GROUP_DATE_FIELD]: '',
    [constants.GROUP_DESCRIPTION_FIELD]: '',
    [constants.GROUP_OCDE_FIELD]: '',
    [constants.GROUP_URL_GROUPLAC_FIELD]: '',
    [constants.GROUP_AGENDA_FIELD]: '',
    [constants.GROUP_LINES_FIELD]: [],
    [constants.GROUP_LEADER_FIELD]: [{
        [constants.AUTHOR_NAME_FIELD]: '',
        [constants.AUTHOR_CAMPUS_FIELD]: '',
        [constants.AUTHOR_FACULTY_FIELD]: '',
        [constants.AUTHOR_UAB_FIELD]: '',
        [constants.AUTHOR_GENDER_FIELD]: '',
        [constants.AUTHOR_IMAGE_FIELD]: ''
    }],
    [constants.GROUP_MEMBERS_FIELD]: [],
    [constants.GROUP_COL_CODE_FIELD]: '',
    [constants.GROUP_COL_CATEGORY_FIELD]: 'A',
    [constants.GROUP_COL_STATE_FIELD]: ''
};

export const authorDefault = {
    [constants.AUTHOR_ID_FIELD]: '',
    [constants.AUTHOR_NAME_FIELD]: '',
    [constants.AUTHOR_CAMPUS_FIELD]: '',
    [constants.AUTHOR_FACULTY_FIELD]: '',
    [constants.AUTHOR_UAB_FIELD]: '',
    [constants.AUTHOR_GENDER_FIELD]: '',
    [constants.AUTHOR_STATE_FIELD]: '',
    [constants.AUTHOR_IMAGE_FIELD]: '',
    [constants.AUTHOR_CONTRACT_FIELD]: '',
    [constants.AUTHOR_POSITION_FIELD]: '',
    [constants.AUTHOR_EMAIL_FIELD]: '',
    [constants.AUTHOR_URL_RG_FIELD]: [],
    [constants.AUTHOR_URL_GS_FIELD]: [],
    [constants.AUTHOR_URL_WS_FIELD]: [],
    [constants.AUTHOR_URL_CVLAC_FIELD]: [],
    [constants.AUTHOR_URL_ORCID_FIELD]: [],
    [constants.AUTHOR_H_INDEX_SCOPUS_FIELD]: 'No Reportado',
    [constants.AUTHOR_H_INDEX_GS_FIELD]: 'No Reportado',
    [constants.AUTHOR_GROUPS_FIELD]: []
};

export const publicationDefault = {
    [constants.PUBLICATION_ID_FIELD]: '',
    [constants.PUBLICATION_TITLE_FIELD]: '',
    [constants.PUBLICATION_SPANISH_TITLE_FIELD]: '',
    [constants.PUBLICATION_ENGLISH_TITLE_FIELD]: '',
    [constants.PUBLICATION_ABSTRACT_FIELD]: '',
    [constants.PUBLICATION_SPANISH_ABSTRACT_FIELD]: '',
    [constants.PUBLICATION_ENGLISH_ABSTRACT_FIELD]: '',
    [constants.PUBLICATION_PUBNAME_FIELD]: {
        [constants.PUBLICATION_ORIGINAL_SUBFIELD]: '',
        [constants.PUBLICATION_NORMALIZED_SUBFIELD]: '',
        [constants.PUBLICATION_SCORE_SUBFIELD]: 0
    },
    [constants.PUBLICATION_AFFILIATION_FIELD]: {
        [constants.PUBLICATION_AFFIL_COUNTRY_FIELD]: '',
        [constants.PUBLICATION_AFFIL_CITY_FIELD]: '',
        [constants.PUBLICATION_AFFIL_NAME_FIELD]: ''
    },
    [constants.PUBLICATION_AUTHORS_FIELD]: [],
    [constants.PUBLICATION_DOI_FIELD]: '',
    [constants.PUBLICATION_DATE_FIELD]: '',
    [constants.PUBLICATION_LANGUAGE_FIELD]: '',
    [constants.PUBLICATION_PUBLISHER_FIELD]: '',
    [constants.PUBLICATION_THESAURUS_FIELD]: {},
    [constants.PUBLICATION_SOURCE_FIELD]: '',
    [constants.PUBLICATION_RANGE_FIELD]: '-',
    [constants.PUBLICATION_SOURCE_TYPE_FIELD]: '',
    [constants.PUBLICATION_VOLUME_FIELD]: '',
    [constants.PUBLICATION_ISBN_FIELD]: [],
    [constants.PUBLICATION_ISSN_FIELD]: [],
    [constants.PUBLICATION_CITATION_FIELD]: 0,
    [constants.PUBLICATION_URL_FIELD]: '',
    [constants.PUBLICATION_SPANISH_KEYWORDS_FIELD]: [],
    [constants.PUBLICATION_ENGLISH_KEYWORDS_FIELD]: []
};

const metricsInvDefault = {
    general: {
        source: {},
        [constants.METRIC_PUBLICATIONS_FIELD]: { tools: defaultTools, processed: 0 },
        [constants.METRIC_THESIS_FIELD]: { tools: defaultTools, processed: 0 },
        [constants.METRIC_CITATIONS_FIELD]: { tools: defaultTools, processed: 0 },
        [constants.METRIC_AUTHORS_VALUE_FIELD]: { tools: defaultTools, processed: 0 },
        [constants.METRIC_GROUPS_FIELD]: { tools: defaultTools, processed: 0 },
        [constants.METRIC_PROJECTS_FIELD]: { tools: defaultTools, processed: 0 },
        [constants.METRIC_AUTHORS_LIST_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_TYPE_LIST_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_PUBLICATIONS_LIST_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_AUTHORS_INFO_FIELD]: { tools: defaultTools, processed: authorDefault },
        [constants.METRIC_GROUPS_INFO_FIELD]: { tools: defaultTools, processed: groupDefault }
    },
    products: {
        source: {},
        [constants.METRIC_TYPE_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_TYPE_YEAR_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_AVERAGE_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_JOURNALS_CLASS_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_SCIMAGO_CLASS_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_JOURNALS_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_INSTITUTIONS_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_COUNTRIES_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_RATE_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_PRODUCTION_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_GENDER_THESIS_FIELD]: { tools: defaultTools, processed: [] }
    },
    researchers: {
        source: {},
        [constants.METRIC_AUTHORS_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_PERCENTAGE_PUBLISHING_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_ACTIVE_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_GENDER_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_AGE_FIELD]: { tools: defaultTools, processed: [] }
    },
    groups: {
        source: {},
        [constants.METRIC_GROUPS_LIST_INFO_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_TYPE_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_OCDE_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_AGENDA_FIELD]: { tools: defaultTools, processed: [] }
    },
    projects: {
        source: {},
        [constants.METRIC_APPROBATION_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_APPROBATION_YEAR_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_STATE_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_STATE_YEAR_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_DURATION_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_TYPOLOGY_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_TYPOLOGY_YEAR_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_GENDER_LEADERS_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_GENDER_TYPES_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_GRAPH_FIELD]: { tools: defaultTools, processed: graphDefault }
    },
    areas: {
        source: {},
        [constants.METRIC_WORDCLOUD_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_GRAPH_FIELD]: { tools: defaultTools, processed: graphDefault },
        [constants.METRIC_GRAPH_UAB_FIELD]: { tools: defaultTools, processed: graphDefault },
        [constants.METRIC_GRAPH_KEYWORDS_FIELD]: { tools: defaultTools, processed: graphDefault },
        [constants.METRIC_LDA_FIELD]: { tools: defaultTools, processed: ldaDefault }
    },
    productsList: {
        source: {},
        [constants.METRIC_METRICS_FIELD]: { tools: defaultTools, processed: 0 },
        [constants.METRIC_PUBLICATIONS_LIST_FIELD]: { tools: defaultTools, processed: [] }
    }
};

const metricsExtDefault = {
    general: {
        source: [],
        [constants.METRIC_COST_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_ALL_VALUE_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_ALLIES_COUNT_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_ALLIES_VALUE_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_LOCATIONS_FIELD]: { tools: defaultTools, processed: [] }
    },
    projects: {
        source: [],
        [constants.METRIC_COUNT_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_VALUE_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_CLASSIFICATION_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_TOP_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_ORIGEN_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_WORDCLOUD_FIELD]: { tools: defaultTools, processed: [] }
    },
    opportunities: {
        source: [],
        [constants.METRIC_COUNTS_FIELD]: { tools: defaultTools, processed: [] }
    },
    activities: {
        source: [],
        [constants.METRIC_COUNT_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_ENROLLED_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_TYPE_COUNT_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_MOD_INTENSITY_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_MOD_ENROLLED_FIELD]: { tools: defaultTools, processed: [] },
        [constants.METRIC_MOD_COUNT_FIELD]: { tools: defaultTools, processed: [] }
    },
    author: {
        dialog: false,
        data: {
            profile: authorDefault,
            projects: []
        }
    }
};

const hierarchyDefault = {
    metrics: {
        general: '',
        products: '',
        researchers: '',
        groups: '',
        projects: '',
        areas: '',
        author: ''
    },
    hierarchy: {
        init: '',
        dependence: [false, ''],
        hierarchy: ['', '', ''],
    },
    resource: '',
    subResource: '',
    action: { type: '', value: '' },
    params: {},
    infoClient: {}
};

const filtersDefault = {
    rangeTime: {
        type: false,
        default: [dayjs().year(), dayjs().year()],
        applied: [dayjs().year(), dayjs().year()],
    },
    intervalTime: {
        default: optionsInterval,
        applied: optionsInterval[0]
    },
    dependence: {
        default: [],
        applied: {
            id: '',
            name: '',
            level: ''
        }
    }
};

const adminDefault = {
    stepPanel: 0,
    infoUser: {
        user: '',
        names: '',
        surnames: '',
        dependence: '',
    },
    infoUsers: [],
};

const usabiltyDefault = {
    reportUser: {
        table: [],
        location: [],
    },
    reportIndicator: {
        tree: { tools: defaultTools, processed: [] },
        dependence: { tools: defaultTools, processed: [] },
        hierarchy: { tools: defaultTools, processed: [] },
        hierarchyType: { tools: defaultTools, processed: [] },
        hierarchySearch: { tools: defaultTools, processed: [] },
        resource: { tools: defaultTools, processed: [] },
        subResource: { tools: defaultTools, processed: [] },
        action: { tools: defaultTools, processed: [] },
        actionType: { tools: defaultTools, processed: [] },
    },
    reportTree: {
        treeInit: { tools: defaultTools, processed: [] },
        treeDependence: { tools: defaultTools, processed: [] },
        treeHierarchy: { tools: defaultTools, processed: [] },
        treeHierarchyType: { tools: defaultTools, processed: [] },
        treeHierarchySearch: { tools: defaultTools, processed: [] },
        treeResource: { tools: defaultTools, processed: [] },
        treeSubResource: { tools: defaultTools, processed: [] },
        treeAction: { tools: defaultTools, processed: [] },
    },
};

const feedbackDefault = {
    source: [],
    qg1: { tools: defaultTools, processed: [], label: '', options: {} },
    qg2: { tools: defaultTools, processed: [], label: '', options: {} },
    qg3: { tools: defaultTools, processed: [], label: '', options: {} },
    qg4: { tools: defaultTools, processed: [], label: '', options: {} },
    qg5: { tools: defaultTools, processed: [], label: '', options: {} },
    qg6: { tools: defaultTools, processed: [], label: '', options: {} },
    qg7: { tools: defaultTools, processed: [], label: '', options: {} },
    qg8: { tools: defaultTools, processed: [], label: '', options: {} },
    qg9: { tools: defaultTools, processed: [], label: '', options: {} },
    qg10: { tools: defaultTools, processed: [], label: '', options: {} },
    qg11: { tools: defaultTools, processed: [], label: '', options: {} },
    qg12: { tools: defaultTools, processed: [], label: '', options: {} },
    qt1: { tools: defaultTools, processed: [], label: '', options: {} },
    qt2: { tools: defaultTools, processed: [], label: '', options: {} },
    qt3: { tools: defaultTools, processed: [], label: '', options: {} },
    qt4: { tools: defaultTools, processed: [], label: '', options: {} },
    qt5: { tools: defaultTools, processed: [], label: '', options: {} },
    qt6: { tools: defaultTools, processed: [], label: '', options: {} },
    qt7: { tools: defaultTools, processed: [], label: '', options: {} },
    qt8: { tools: defaultTools, processed: [], label: '', options: {} }
};

export const curatorDefault = {
    author: [],
    type: [],
    publications: [],
    total: 0,
    infoPublication: {
        publicationId: '',
        publication: publicationDefault,
        corrections: []
    }
};

const newsDefault = {
    infoNews: []
};

export const initialState = {
    dependence: [],
    investigation: {
        search: {},
        metrics: metricsInvDefault,
        hierarchy: hierarchyDefault,
        filters: filtersDefault
    },
    extension: {
        search: {},
        metrics: metricsExtDefault,
        hierarchy: hierarchyDefault,
        filters: filtersDefault
    },
    login: {
        admin: adminDefault,
        feedback: feedbackDefault,
        curator: curatorDefault,
        usability: usabiltyDefault,
        news: newsDefault
    },
    reports: {
        url: '',
        params: {},
        report: {},
        options: {
            institucion: null,
            sede: null,
            facultad: null,
            departamento: null,
            _id: [],
            correo: [],
            genero: null,
            vinculacion: null
        },
        response: {},
    },
    settings: {
        heightTop: 0,
        heightBottom: 0,
        openDrawer: true,
        widthDrawer: 280,
        language: 'es',
        backdrop: { open: false, severity: '', message: '' },
        snackbar: { open: false, severity: 'success', message: '' }
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SETTINGS':
            return {
                ...state,
                settings: { ...state.settings, ...action.state },
            };
        case 'SET_DEPENDENCE':
            return {
                ...state,
                dependence: action.state,
            };
        case 'SET_INVESTIGATION':
            return {
                ...state,
                investigation: objectAssignInv({ ...state.investigation }, { ...action.state }),
            };
        case 'SET_EXTENSION':
            return {
                ...state,
                extension: objectAssignExt({ ...state.extension }, { ...action.state }),
            };
        case 'SET_METRICS_DEFAULT':
            return {
                ...state,
                investigation: objectAssignInv({ ...state.investigation }, {
                    search: { search: null },
                    metrics: metricsInvDefault,
                    hierarchy: {
                        metrics: hierarchyDefault.metrics,
                        hierarchy: { dependence: hierarchyDefault.hierarchy.dependence }
                    }
                }),
                extension: objectAssignExt({ ...state.extension }, {
                    search: { search: null },
                    metrics: metricsExtDefault,
                    hierarchy: {
                        hierarchy: { dependence: hierarchyDefault.hierarchy.dependence }
                    }
                })
            };
        case 'SET_LOGIN':
            return {
                ...state,
                login: objectAssignLog({ ...state.login }, { ...action.state }),
            };
        case 'SET_LOGIN_DEFAULT':
            return {
                ...state,
                login: initialState.login,
            };
        case 'SET_REPORTS':
            return {
                ...state,
                reports: { ...state.reports, ...action.state }
            };
        default:
            break;
    }
    return state;
};

export default createStore(reducer);