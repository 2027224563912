import React from 'react';
import PropTypes from 'prop-types';
import { Pie } from '@antv/g2plot';
import ReactG2Plot from 'react-g2plot';
import { configPie, configColorGender } from '../../../assets/theme/g2Theme';
import { formatCurrency, formatNumber, formatPercent } from '../../../utils/utils';

function customPie(props) {
    const { infoChart } = props;
    return (
        <ReactG2Plot
            Ctor={Pie}
            options={{
                ...{
                    data: infoChart.data,
                    ...(infoChart.colorGender ? configColorGender : {}),
                    tooltip: {
                        formatter: (item) => {
                            return {
                                name: item.type,
                                value: infoChart.formatValue === 'money'
                                    ? formatCurrency(item.value)
                                    : infoChart.formatValue === 'number'
                                        ? formatNumber(item.value)
                                        : infoChart.formatValue === 'percent'
                                            ? formatPercent(item.value / 100)
                                            : item.value,
                            };
                        },
                    },
                },
                ...configPie
            }}
        />
    );
}
customPie.propTypes = {
    infoChart: PropTypes.shape({
        data: PropTypes.array,
        colorGender: PropTypes.bool,
        formatValue: PropTypes.string
    })
};
export default customPie;
