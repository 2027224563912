import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, Chip, Avatar, Grid } from '@mui/material';
import { GroupAddTwoTone, GroupRemoveTwoTone, PersonOffTwoTone, CheckTwoTone, CloseTwoTone } from '@mui/icons-material';
import CardGraphics from '../../../../components/card/CardGraphics';
import DataTable from '../../../../components/DataTable';
import BarGradient from '../../../../components/graphics/BarGradient';
import PieDonut from '../../../../components/graphics/PieDonut';
import { requestPost } from '../../../../../utils/request';
import { metricsInv, formatMessage, objectAssignInv, getParams, getParamsRouteString } from '../../../../../utils/utils';
import { constants, URL_API_INV } from '../../../../../constants';

function Groups(props) {
    const { dependence, investigation, settings, setMetricsDefault, setInvestigation, setSettings } = props;
    const [isSearch, setIsSearch] = useState(false);

    const { t } = useTranslation();
    const activeHierarchy = investigation.hierarchy.hierarchy.hierarchy[2];
    const params = useParams();
    const navigate = useNavigate();

    const handleOnChange = (value, state) => {
        const newInvestigation = objectAssignInv(investigation, {
            metrics: { groups: { [state]: value } },
        });
        newInvestigation.filters.rangeTime.type = true;
        setInvestigation(
            metricsInv(
                [state],
                investigation.metrics.groups.source,
                investigation.hierarchy,
                newInvestigation,
                settings
            )
        );
    };

    const handleRoute = (group) => {
        setMetricsDefault();
        let route = getParams(dependence, group[constants.GROUP_LEADER_FIELD]);
        route = [route, 'group', getParamsRouteString(group[constants.GROUP_NAME_FIELD]), 'resource', getParamsRouteString('calls')].join('/');
        navigate(route, { state: { key: 'hierarchy' } });
    };

    const handleAuthor = (group) => {
        setMetricsDefault();
        let route = getParams(dependence, group[constants.GROUP_LEADER_FIELD]);
        route = [route, 'resource', getParamsRouteString(params.resource)].join('/');
        navigate(route, { state: { key: 'hierarchy' } });
    };

    const columnsGroups = [
        {
            field: constants.GROUP_COL_CODE_FIELD,
            headerName: 'Colciencias',
            width: 100,
            hide: true
        },
        {
            field: constants.GROUP_NAME_FIELD,
            headerName: t('dependence.investigation.researchGroups.name'),
            width: 600,
            hide: true,
            renderCell: (params) => (
                <Typography
                    fontSize='inherit'
                    color='primary'
                    sx={{
                        '&:hover': {
                            textDecoration: 'underline',
                        },
                        cursor: 'pointer',
                    }}
                    onClick={() => handleRoute(params.row)}
                >
                    {params.row[constants.GROUP_NAME_FIELD]}
                </Typography>
            ),
        },
        {
            field: constants.GROUP_LEADER_FIELD,
            headerName: t('dependence.investigation.researchGroups.leader'),
            width: 300,
            hide: true,
            renderCell: (params) => (
                <Typography
                    fontSize='inherit'
                    color='primary'
                    sx={{
                        '&:hover': {
                            textDecoration: 'underline',
                        },
                        cursor: 'pointer',
                    }}
                    onClick={() => handleAuthor(params.row)}
                >
                    {params.row[constants.GROUP_LEADER_FIELD]}
                </Typography>
            ),
        },
        {
            field: constants.GROUP_MEMBERS_FIELD,
            headerName: t('dependence.investigation.researchGroups.associated') + constants.DEPENDENCE_INFO_FIELD[activeHierarchy]?.['name_' + settings.language],
            flex: 1,
            hide: !isSearch,
            renderCell: (params) => (
                <Chip
                    size='small'
                    variant='outlined'
                    sx={{ pl: 0.25 }}
                    label={t('components.graphics.' + params.row[constants.GROUP_MEMBERS_FIELD])}
                    color={params.row[constants.GROUP_MEMBERS_FIELD] ? 'primary' : 'secondary'}
                    avatar={
                        <Avatar>
                            {
                                params.row[constants.GROUP_MEMBERS_FIELD]
                                    ? <CheckTwoTone fontSize='small' />
                                    : <CloseTwoTone fontSize='small' />
                            }
                        </Avatar>
                    }
                />
            ),
        },
        {
            field: constants.GROUP_STATE_FIELD,
            headerName: t('dependence.investigation.researchGroups.status'),
            flex: 1,
            minWidth: 50,
            hide: true,
            renderCell: (params) => (
                <Chip
                    size='small'
                    variant='outlined'
                    sx={{ pl: 0.25 }}
                    label={t('components.graphics.' + params.row[constants.GROUP_STATE_FIELD])}
                    color={
                        params.row[constants.GROUP_STATE_FIELD] === 'Activo'
                            ? 'primary'
                            : params.row[constants.GROUP_STATE_FIELD] === 'Retirado'
                                ? 'secondary'
                                : 'dark'
                    }
                    avatar={
                        <Avatar>
                            {
                                params.row[constants.GROUP_STATE_FIELD] === 'Activo'
                                    ? <GroupAddTwoTone fontSize='small' />
                                    : params.row[constants.GROUP_STATE_FIELD] === 'Retirado'
                                        ? <GroupRemoveTwoTone fontSize='small' />
                                        : <PersonOffTwoTone fontSize='small' />
                            }
                        </Avatar>
                    }
                />
            ),
        }
    ];

    useEffect(() => {
        const isSearch = 'query' in params;
        setIsSearch(isSearch);

        setSettings({ backdrop: formatMessage(true, '', t('dependence.investigation.consultingInfoGroups')) });
        if (investigation.hierarchy.metrics.groups || investigation.search.search) {
            const hierarchy = {
                ...investigation.hierarchy,
                resource: 'Grupos'
            };
            Promise.all([
                requestPost(`${URL_API_INV}/investigation/groups_metrics`, {
                    _id: investigation.hierarchy.metrics.groups,
                    search: investigation.search.search,
                    record: hierarchy
                }),
            ])
                .then(res => {
                    setInvestigation(
                        metricsInv(
                            Object.keys(investigation.metrics.groups),
                            res[0].data,
                            hierarchy,
                            investigation,
                            settings
                        )
                    );
                    setSettings({ backdrop: formatMessage(false, '', '') });
                })
                .catch(err => {
                    setSettings({
                        backdrop: formatMessage(false, '', ''),
                        snackbar: formatMessage(true, 'error', err),
                    });
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [investigation.hierarchy.metrics.groups, investigation.search.search, settings.language]);
    return (
        <Box>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <CardGraphics
                        title={(t('dependence.investigation.researchGroups.researchGroupsList'))}
                        tools={{
                            usability: true,
                            rangeTime: false,
                            gender: false,
                            type: false,
                            accumulate: false,
                            wordCloud: false,
                            graph: false,
                            chart: false,
                            download: true,
                            dialog: false,
                            dataTable: true
                        }}
                        infoDialog={{
                            label: '',
                            gif: '',
                            notice: ''
                        }}
                        infoChart={{
                            data: investigation.metrics.groups[constants.METRIC_GROUPS_LIST_INFO_FIELD],
                            onChange: (value) => handleOnChange(value, constants.METRIC_GROUPS_LIST_INFO_FIELD),
                            columns: {
                                [constants.GROUP_COL_CODE_FIELD]: 'Colciencias',
                                [constants.GROUP_NAME_FIELD]: t('dependence.investigation.researchGroups.name'),
                                [constants.GROUP_LEADER_FIELD]: t('dependence.investigation.researchGroups.leader'),
                                [constants.GROUP_MEMBERS_FIELD]: t('dependence.investigation.researchGroups.associated') + constants.DEPENDENCE_INFO_FIELD[activeHierarchy]?.['name_' + settings.language],
                                [constants.GROUP_STATE_FIELD]: t('dependence.investigation.researchGroups.status')
                            },
                            formatValue: 'number',
                            sortField: 'value'
                        }}
                    >
                        <DataTable
                            id='_id'
                            rows={investigation.metrics.groups[constants.METRIC_GROUPS_LIST_INFO_FIELD].processed}
                            columns={columnsGroups.filter(column => column.hide)}
                            sortModel={[]}
                        />
                    </CardGraphics>
                </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: 0.5 }}>
                <Grid item xs={12}>
                    <CardGraphics
                        title={t('dependence.investigation.researchGroups.amountGroupsByCategory')}
                        tools={{
                            usability: true,
                            rangeTime: false,
                            gender: false,
                            type: false,
                            accumulate: false,
                            wordCloud: false,
                            graph: false,
                            chart: false,
                            download: true,
                            dialog: true,
                            dataTable: true
                        }}
                        infoDialog={{
                            label: t('dependence.investigation.researchGroups.infoAmountGroupsByCategory'),
                            gif: require('../../../../../assets/images/animation/grupos.gif'),
                            notice: ''
                        }}
                        infoChart={{
                            data: investigation.metrics.groups[constants.METRIC_TYPE_FIELD],
                            onChange: (value) => handleOnChange(value, constants.METRIC_TYPE_FIELD),
                            columns: {
                                type: t('dependence.investigation.researchGroups.groupCategory'),
                                value: t('dependence.investigation.researchGroups.groupAmount'),
                            },
                            formatValue: 'number',
                            sortField: 'value'
                        }}
                    >
                        <PieDonut
                            infoChart={{
                                data: investigation.metrics.groups[constants.METRIC_TYPE_FIELD].processed
                            }}
                        />
                    </CardGraphics>
                </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: 0.5 }}>
                <Grid item xs={12} md={6}>
                    <CardGraphics
                        title={t('dependence.investigation.researchGroups.amountGroupsByOCDE')}
                        tools={{
                            usability: true,
                            rangeTime: false,
                            gender: false,
                            type: false,
                            accumulate: false,
                            wordCloud: false,
                            graph: false,
                            chart: false,
                            download: true,
                            dialog: true,
                            dataTable: true
                        }}
                        infoDialog={{
                            label: t('dependence.investigation.researchGroups.infoAmountGroupsByOCDE'),
                            gif: require('../../../../../assets/images/animation/grupos_ocde.gif'),
                            notice: ''
                        }}
                        infoChart={{
                            data: investigation.metrics.groups[constants.METRIC_OCDE_FIELD],
                            onChange: (value) => handleOnChange(value, constants.METRIC_OCDE_FIELD),
                            columns: {
                                type: t('dependence.investigation.researchGroups.OCDEArea'),
                                value: t('dependence.investigation.researchGroups.groupAmount'),
                            },
                            formatValue: 'number',
                            sortField: 'value'
                        }}
                    >
                        <BarGradient
                            infoChart={{
                                data: investigation.metrics.groups[constants.METRIC_OCDE_FIELD].processed,
                                formatValue: 'number',
                                sortField: 'value'
                            }}
                        />
                    </CardGraphics>
                </Grid>
                <Grid item xs={12} md={6}>
                    <CardGraphics
                        title={t('dependence.investigation.researchGroups.amountGroupsByAgenda')}
                        tools={{
                            usability: true,
                            rangeTime: false,
                            gender: false,
                            type: false,
                            accumulate: false,
                            wordCloud: false,
                            graph: false,
                            chart: false,
                            download: true,
                            dialog: true,
                            dataTable: true
                        }}
                        infoDialog={{
                            label: t('dependence.investigation.researchGroups.infoAmountGroupsByAgenda'),
                            gif: require('../../../../../assets/images/animation/grupos_agendas.gif'),
                            notice: ''
                        }}
                        infoChart={{
                            data: investigation.metrics.groups[constants.METRIC_AGENDA_FIELD],
                            onChange: (value) => handleOnChange(value, constants.METRIC_AGENDA_FIELD),
                            columns: {
                                type: t('dependence.investigation.researchGroups.knowledgeAgenda'),
                                value: t('dependence.investigation.researchGroups.groupAmount'),
                            },
                            formatValue: 'number',
                            sortField: 'value'
                        }}
                    >
                        <BarGradient
                            infoChart={{
                                data: investigation.metrics.groups[constants.METRIC_AGENDA_FIELD].processed,
                                formatValue: 'number',
                                sortField: 'value'
                            }}
                        />
                    </CardGraphics>
                </Grid>
            </Grid>
        </Box>
    )
}
const mapStateToProps = (state) => ({
    dependence: state.dependence,
    investigation: state.investigation,
    settings: state.settings
});
const mapDispatchToProps = (dispatch) => ({
    setMetricsDefault() {
        dispatch({
            type: 'SET_METRICS_DEFAULT',
        });
    },
    setInvestigation(state) {
        dispatch({
            type: 'SET_INVESTIGATION',
            state,
        });
    },
    setSettings(state) {
        dispatch({
            type: 'SET_SETTINGS',
            state,
        });
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(Groups);