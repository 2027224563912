import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import numeral from 'numeral';
import printJS from 'print-js';
import Papa from 'papaparse';
import html2canvas from 'html2canvas';
import { Chip } from '@mui/material';
import { SwipeUpTwoTone, HistoryEduTwoTone, BorderColorTwoTone, CloudDoneTwoTone, PersonPinCircleTwoTone, CalendarMonthTwoTone, Filter1TwoTone, Filter2TwoTone, Filter3TwoTone, Filter4TwoTone, Filter5TwoTone, Filter6TwoTone, Filter7TwoTone, Filter8TwoTone } from '@mui/icons-material';
import palette, { COLORS_USABILITY, COLORS_GROUP } from '../assets/theme/palette';
import { graphDefault, ldaDefault, groupDefault, authorDefault } from '../redux/store';
import { constants } from '../constants';

import WorldMap from './../mui/components/maps/WorldMap.json';
import WorldName from './../mui/components/maps/WorldName.json';

require('dayjs/locale/es');
dayjs.locale('es');
dayjs.extend(utc);

export function filterDependencesInv(key, dependence, params, investigation, location, resources, navigate) {
    const fields = {
        DEPENDENCE_INSTITUTION_FIELD: { key: 'institution', value: 'Institución', search: 'DEPENDENCE_CAMPUS_FIELD' },
        DEPENDENCE_CAMPUS_FIELD: { key: 'campus', value: 'Sede', search: 'DEPENDENCE_FACULTY_FIELD' },
        DEPENDENCE_FACULTY_FIELD: { key: 'faculty', value: 'Facultad', search: 'DEPENDENCE_UAB_FIELD' },
        DEPENDENCE_UAB_FIELD: { key: 'uab', value: 'Uab', search: 'DEPENDENCE_AUTHOR_FIELD' },
        DEPENDENCE_AUTHOR_FIELD: { key: 'author', value: 'Profesor', search: '' },
    };
    let filterDependencesInv = {};
    const hierarchy = investigation.hierarchy;
    const keyDep = location?.state?.key === 'hierarchy' ? false : true;
    let findDependence = dependence?.find(item => item[constants.DEPENDENCE_INSTITUTION_FIELD] === params[fields.DEPENDENCE_INSTITUTION_FIELD.key]);

    if (findDependence) {
        for (let field in fields) {
            const fieldInfo = fields[field];
            if (fieldInfo.key === key) {
                const metrics = findDependence[constants.DEPENDENCE_METRICS_FIELD];
                const author = findDependence?.[constants.DEPENDENCE_AUTHOR_INFO_FIELD] || {};
                filterDependencesInv = {
                    ...hierarchy,
                    metrics: {
                        general: metrics[constants.COLLECTION_TOT_GENERAL_METRICS],
                        products: metrics[constants.COLLECTION_TOT_PRODUCTS_METRICS],
                        researchers: metrics[constants.COLLECTION_TOT_RESEARCHERS_METRICS],
                        groups: metrics[constants.COLLECTION_TOT_GROUPS_METRICS],
                        projects: metrics[constants.COLLECTION_TOT_PROJECTS_METRICS],
                        areas: metrics[constants.COLLECTION_TOT_AREAS_METRICS],
                        author: constants.AUTHOR_ID_FIELD in author ? author[constants.AUTHOR_ID_FIELD] : ''
                    },
                    hierarchy: {
                        ...hierarchy.hierarchy,
                        dependence: [keyDep, 'Investigación'],
                        hierarchy: [fieldInfo.value, params[fieldInfo.key], field]
                    },
                    params: params,
                    resource: 'General'
                };
                break;
            } else {
                findDependence = findDependence[constants.DEPENDENCE_CHILDREN_FIELD].find(item => item[constants[fieldInfo.search]] === params[fields[fieldInfo.search].key]);
            }
        }
    }

    if (navigate) {
        getStepResource(params, resources, navigate, location);
    }

    window.history.replaceState({}, document.title);
    return filterDependencesInv;
}

export function filterDependencesExt(data, hierarchy) {
    let newData = {
        hierarchy: hierarchy,
        filters: {
            dependence: {
                default: data,
                applied: formatDependencesExt([data])[0].info
            }
        }
    };
    return newData;
}

export function metricsInv(keys, metrics, hierarchy, investigation, settings) {
    const resource = constants.RESOURCES[hierarchy.resource];

    let metricsInv = {
        metrics: {},
        hierarchy: hierarchy,
        filters: investigation.filters
    };

    let rangeYear = metricsInv.filters.rangeTime.applied;
    if (resource === 'general' && !metricsInv.filters.rangeTime.type) {
        rangeYear = rangeYears(metrics[constants.METRIC_BOTH_FIELD]?.[constants.METRIC_YEAR_FIELD] || {});
        metricsInv.filters.rangeTime.default = rangeYear;
    }
    metricsInv.filters.rangeTime.type = false;
    metricsInv.filters.rangeTime.applied = rangeYear;

    const filterMetrics = filterMetricsInv([resource, keys], metrics, rangeYear, investigation, settings);
    metricsInv.metrics = generateMetric(keys, resource, filterMetrics, metrics, investigation.metrics);

    return metricsInv;
}

export function filterMetricsInv(keys, metrics, rangeYear, investigation, settings) {
    const metricsA = metrics[constants.METRIC_BOTH_FIELD];
    let filterMetricsInv = {};

    const tools = investigation.metrics;
    const resource = keys[0];
    const keysValue = keys[1];
    const translations = constants.TRANSLATIONS[settings.language].translation.components.graphics;

    for (let year = rangeYear[0]; year <= rangeYear[1]; year++) {
        if (resource === 'general') {
            if (keysValue.includes(constants.METRIC_PUBLICATIONS_FIELD)) {
                const value = metricsA[constants.METRIC_YEAR_FIELD]?.[year]?.[constants.METRIC_PUBLICATIONS_FIELD] || 0;
                addValue(constants.METRIC_PUBLICATIONS_FIELD, filterMetricsInv, value);
            }
            if (keysValue.includes(constants.METRIC_THESIS_FIELD)) {
                const value = metricsA[constants.METRIC_YEAR_FIELD]?.[year]?.[constants.METRIC_THESIS_FIELD] || 0;
                addValue(constants.METRIC_THESIS_FIELD, filterMetricsInv, value);
            }
            if (keysValue.includes(constants.METRIC_PROJECTS_FIELD)) {
                const value = metricsA[constants.METRIC_YEAR_FIELD]?.[year]?.[constants.METRIC_PROJECTS_FIELD] || 0;
                addValue(constants.METRIC_PROJECTS_FIELD, filterMetricsInv, value);
            }
        } else if (resource === 'products') {
            if (keysValue.includes(constants.METRIC_TYPE_FIELD)) {
                const keys = [resource, constants.METRIC_TYPE_FIELD, constants.METRIC_YEAR_FIELD];
                const value = genderMetric(metrics, tools, keys)?.[year]?.[constants.METRIC_TYPE_FIELD] || {};
                addValueDict(constants.METRIC_TYPE_FIELD + '_', filterMetricsInv, value);
            }
            if (keysValue.includes(constants.METRIC_TYPE_YEAR_FIELD)) {
                const keys = [resource, constants.METRIC_TYPE_YEAR_FIELD, constants.METRIC_YEAR_FIELD];
                const value = genderMetric(metrics, tools, keys)?.[year]?.[constants.METRIC_TYPE_FIELD] || {};
                Object.entries(value).forEach(([key, value]) => {
                    formatArrayTypeAxis(constants.METRIC_TYPE_YEAR_FIELD, filterMetricsInv, key, year, value);
                });
            }
            if (keysValue.includes(constants.METRIC_AVERAGE_FIELD)) {
                const value = metricsA[constants.METRIC_YEAR_FIELD]?.[year]?.[constants.METRIC_AVERAGE_FIELD] || 0;
                formatArrayAxis(constants.METRIC_AVERAGE_FIELD, filterMetricsInv, year, value);
            }
            if (keysValue.includes(constants.METRIC_JOURNALS_CLASS_FIELD)) {
                const keys = [resource, constants.METRIC_JOURNALS_CLASS_FIELD, constants.METRIC_YEAR_FIELD];
                const type = tools[keys[0]][keys[1]].tools.type.value.value;
                const value = genderMetric(metrics, tools, keys)?.[year]?.[constants.METRIC_JOURNALS_CLASS_FIELD]?.[type] || {};
                Object.entries(value).forEach(([key, value]) => {
                    formatArrayTypeAxis(constants.METRIC_JOURNALS_CLASS_FIELD, filterMetricsInv, key, year, value);
                });
            }
            if (keysValue.includes(constants.METRIC_SCIMAGO_CLASS_FIELD)) {
                const keys = [resource, constants.METRIC_SCIMAGO_CLASS_FIELD, constants.METRIC_YEAR_FIELD];
                const value = genderMetric(metrics, tools, keys)?.[year]?.[constants.METRIC_SCIMAGO_CLASS_FIELD] || {};
                Object.entries(value).forEach(([key, value]) => {
                    formatArrayTypeAxis(constants.METRIC_SCIMAGO_CLASS_FIELD, filterMetricsInv, key, year, value);
                });
            }
            if (keysValue.includes(constants.METRIC_COUNTRIES_FIELD)) {
                const keys = [resource, constants.METRIC_COUNTRIES_FIELD, constants.METRIC_YEAR_FIELD];
                const value = genderMetric(metrics, tools, keys)?.[year]?.[constants.METRIC_COUNTRIES_FIELD] || {};
                addValueDict(constants.METRIC_COUNTRIES_FIELD, filterMetricsInv, value);
            }
        } else if (resource === 'researchers') {
            if (keysValue.includes(constants.METRIC_PERCENTAGE_PUBLISHING_FIELD)) {
                const value = metricsA[constants.METRIC_YEAR_FIELD]?.[year]?.[constants.METRIC_PERCENTAGE_PUBLISHING_FIELD] || 0;
                formatArrayAxis(constants.METRIC_PERCENTAGE_PUBLISHING_FIELD, filterMetricsInv, year, value);
            }
            if (keysValue.includes(constants.METRIC_ACTIVE_FIELD)) {
                const value = metricsA[constants.METRIC_YEAR_FIELD]?.[year]?.[constants.METRIC_ACTIVE_FIELD] || 0;
                formatArrayAxis(constants.METRIC_ACTIVE_FIELD, filterMetricsInv, year, value);
            }
        } else if (resource === 'groups') {
        } else if (resource === 'projects') {
            if (keysValue.includes(constants.METRIC_APPROBATION_FIELD)) {
                const value = metricsA[constants.METRIC_YEAR_FIELD]?.[year]?.[constants.METRIC_APPROBATION_FIELD] || {};
                addValueDict(constants.METRIC_APPROBATION_FIELD + '_', filterMetricsInv, value);
            }
            if (keysValue.includes(constants.METRIC_APPROBATION_YEAR_FIELD)) {
                const value = metricsA[constants.METRIC_YEAR_FIELD]?.[year]?.[constants.METRIC_APPROBATION_FIELD] || {};
                Object.entries(value).forEach(([key, value]) => {
                    formatArrayTypeAxis(constants.METRIC_APPROBATION_YEAR_FIELD, filterMetricsInv, key, year, value);
                });
            }
            const keysState = ['Cancelado', 'Finalizado', 'Activo'];
            if (keysValue.includes(constants.METRIC_STATE_FIELD)) {
                const value = metricsA[constants.METRIC_YEAR_FIELD]?.[year]?.[constants.METRIC_STATE_FIELD] || {};
                const valueFilter = filterDict(value, keysState);
                addValueDict(constants.METRIC_STATE_FIELD + '_', filterMetricsInv, valueFilter);
            }
            if (keysValue.includes(constants.METRIC_STATE_YEAR_FIELD)) {
                const value = metricsA[constants.METRIC_YEAR_FIELD]?.[year]?.[constants.METRIC_STATE_FIELD] || {};
                const valueFilter = filterDict(value, keysState);
                Object.entries(valueFilter).forEach(([key, value]) => {
                    formatArrayTypeAxis(constants.METRIC_STATE_YEAR_FIELD, filterMetricsInv, key, year, value);
                });
            }
            if (keysValue.includes(constants.METRIC_DURATION_FIELD)) {
                const valueOri = metricsA[constants.METRIC_YEAR_FIELD]?.[year]?.[constants.METRIC_DURATION_FIELD] || {};
                addValueDict(constants.METRIC_DURATION_FIELD + '_', filterMetricsInv, valueOri);
                const valuePro = metricsA[constants.METRIC_YEAR_FIELD]?.[year]?.[constants.METRIC_DURATION_PRORROGA_FIELD] || {};
                addValueDict(constants.METRIC_DURATION_PRORROGA_FIELD + '_', filterMetricsInv, valuePro);
            }
            if (keysValue.includes(constants.METRIC_TYPOLOGY_FIELD)) {
                const value = metricsA[constants.METRIC_YEAR_FIELD]?.[year]?.[constants.METRIC_TYPOLOGY_FIELD] || {};
                addValueDict(constants.METRIC_TYPOLOGY_FIELD + '_', filterMetricsInv, value);
            }
            if (keysValue.includes(constants.METRIC_TYPOLOGY_YEAR_FIELD)) {
                const value = metricsA[constants.METRIC_YEAR_FIELD]?.[year]?.[constants.METRIC_TYPOLOGY_FIELD] || {};
                Object.entries(value).forEach(([key, value]) => {
                    formatArrayTypeAxis(constants.METRIC_TYPOLOGY_YEAR_FIELD, filterMetricsInv, key, year, value);
                });
            }
        } else if (resource === 'areas') {
        } else if (resource === 'productsList') {
        }
    }
    if (resource === 'general') {
        if (keysValue.includes(constants.METRIC_CITATIONS_FIELD)) {
            const value = metricsA[constants.METRIC_CITATIONS_FIELD];
            filterMetricsInv[constants.METRIC_CITATIONS_FIELD] = value;
        }
        if (keysValue.includes(constants.METRIC_AUTHORS_VALUE_FIELD)) {
            const value = metricsA[constants.METRIC_AUTHORS_VALUE_FIELD];
            filterMetricsInv[constants.METRIC_AUTHORS_VALUE_FIELD] = value;
        }
        if (keysValue.includes(constants.METRIC_GROUPS_FIELD)) {
            const value = metricsA[constants.METRIC_GROUPS_FIELD];
            filterMetricsInv[constants.METRIC_GROUPS_FIELD] = value;
        }
        if (keysValue.includes(constants.METRIC_AUTHORS_LIST_FIELD)) {
            const projects_authors = Object.keys(metricsA[constants.METRIC_PROJECTS_AUTHORS_FIELD]?.True || {}).map(item => parseInt(item, 10));
            const products_authors = Object.keys(metricsA[constants.METRIC_PRODUCTS_AUTHORS_FIELD]?.True || {}).map(item => parseInt(item, 10));
            const value = [...projects_authors, ...products_authors];
            filterMetricsInv[constants.METRIC_AUTHORS_LIST_FIELD] = value;
        }
        if (keysValue.includes(constants.METRIC_TYPE_LIST_FIELD)) {
            const value = [...metricsA[constants.METRIC_PRODUCTS_TYPE_FIELD] || [], ...metricsA[constants.METRIC_PROJECTS_TYPE_FIELD] || []];
            filterMetricsInv[constants.METRIC_TYPE_LIST_FIELD] = value;
        }
        if (keysValue.includes(constants.METRIC_PUBLICATIONS_LIST_FIELD)) {
            const value = [...metricsA[constants.METRIC_PUBLICATIONS_LIST_FIELD] || [], ...metricsA[constants.METRIC_PROJECTS_LIST_FIELD] || []];
            filterMetricsInv[constants.METRIC_PUBLICATIONS_LIST_FIELD] = value;
        }
        if (keysValue.includes(constants.METRIC_AUTHORS_INFO_FIELD)) {
            const value = { ...authorDefault, ...metrics[constants.METRIC_AUTHORS_INFO_FIELD] };
            filterMetricsInv[constants.METRIC_AUTHORS_INFO_FIELD] = value;
        }
        if (keysValue.includes(constants.METRIC_GROUPS_INFO_FIELD)) {
            const value = { ...groupDefault, ...metrics[constants.METRIC_GROUPS_INFO_FIELD] };
            filterMetricsInv[constants.METRIC_GROUPS_INFO_FIELD] = value;
        }
    } else if (resource === 'products') {
        if (keysValue.includes(constants.METRIC_TYPE_FIELD)) {
            if (Object.keys(filterMetricsInv[constants.METRIC_TYPE_FIELD + '_'] || {}).length === 0) {
                filterMetricsInv[constants.METRIC_TYPE_FIELD] = [];
            } else {
                Object.entries(filterMetricsInv[constants.METRIC_TYPE_FIELD + '_'] || {}).forEach(([key, value]) => {
                    const translatedKey = translations[key] || key;
                    formatArrayType(constants.METRIC_TYPE_FIELD, filterMetricsInv, translatedKey, value);
                });
            }
        }
        if (keysValue.includes(constants.METRIC_TYPE_YEAR_FIELD)) {
            const keys = [resource, constants.METRIC_TYPE_YEAR_FIELD];
            let value = accumulateMetric(filterMetricsInv[constants.METRIC_TYPE_YEAR_FIELD], tools, keys, 'axis');
            value = translateList(value, translations, ['type']);
            filterMetricsInv[constants.METRIC_TYPE_YEAR_FIELD] = value;
        }
        if (keysValue.includes(constants.METRIC_AVERAGE_FIELD)) {
            const keys = [resource, constants.METRIC_AVERAGE_FIELD];
            const value = accumulateMetric(filterMetricsInv[constants.METRIC_AVERAGE_FIELD], tools, keys, 'axis');
            filterMetricsInv[constants.METRIC_AVERAGE_FIELD] = value;
        }
        if (keysValue.includes(constants.METRIC_JOURNALS_CLASS_FIELD)) {
            const keys = [resource, constants.METRIC_JOURNALS_CLASS_FIELD];
            let value = accumulateMetric(filterMetricsInv[constants.METRIC_JOURNALS_CLASS_FIELD], tools, keys, 'axis');
            value = translateList(value, translations, ['type']);
            filterMetricsInv[constants.METRIC_JOURNALS_CLASS_FIELD] = value;
        }
        if (keysValue.includes(constants.METRIC_SCIMAGO_CLASS_FIELD)) {
            const keys = [resource, constants.METRIC_SCIMAGO_CLASS_FIELD];
            const value = accumulateMetric(filterMetricsInv[constants.METRIC_SCIMAGO_CLASS_FIELD], tools, keys, 'axis');
            filterMetricsInv[constants.METRIC_SCIMAGO_CLASS_FIELD] = value;
        }
        if (keysValue.includes(constants.METRIC_JOURNALS_FIELD)) {
            const keys = [resource, constants.METRIC_JOURNALS_FIELD, constants.METRIC_JOURNALS_FIELD];
            const value = genderMetric(metrics, tools, keys);
            filterMetricsInv[constants.METRIC_JOURNALS_FIELD] = value;
        }
        if (keysValue.includes(constants.METRIC_INSTITUTIONS_FIELD)) {
            const keys = [resource, constants.METRIC_INSTITUTIONS_FIELD, constants.METRIC_INSTITUTIONS_FIELD];
            const value = genderMetric(metrics, tools, keys);
            filterMetricsInv[constants.METRIC_INSTITUTIONS_FIELD] = value;
        }
        if (keysValue.includes(constants.METRIC_COUNTRIES_FIELD)) {
            const value = formatChoropleth(filterMetricsInv[constants.METRIC_COUNTRIES_FIELD] || {}, translations);
            filterMetricsInv[constants.METRIC_COUNTRIES_FIELD] = value;
        }
        if (keysValue.includes(constants.METRIC_RATE_FIELD)) {
            const value = metricsA[constants.METRIC_RATE_FIELD];
            filterMetricsInv[constants.METRIC_RATE_FIELD] = value;
        }
        if (keysValue.includes(constants.METRIC_PRODUCTION_FIELD)) {
            let value = metricsA[constants.METRIC_PRODUCTION_FIELD];
            value = translateList(value, translations, ['type']);
            value = translateDependences(value, ['group']);
            filterMetricsInv[constants.METRIC_PRODUCTION_FIELD] = value;
        }
        if (keysValue.includes(constants.METRIC_GENDER_THESIS_FIELD)) {
            const keys = [resource, constants.METRIC_GENDER_THESIS_FIELD];
            let value = formatThesis(metrics[constants.METRIC_GENDER_THESIS_FIELD], tools, keys);
            value = translateList(value, translations, ['axis', 'group', 'type']);
            filterMetricsInv[constants.METRIC_GENDER_THESIS_FIELD] = value;
        }
    } else if (resource === 'researchers') {
        if (keysValue.includes(constants.METRIC_AUTHORS_FIELD)) {
            const value = metricsA[constants.METRIC_AUTHORS_FIELD];
            filterMetricsInv[constants.METRIC_AUTHORS_FIELD] = value;
        }
        if (keysValue.includes(constants.METRIC_GENDER_FIELD)) {
            let value = metricsA[constants.METRIC_GENDER_FIELD];
            value = translateList(value, translations, ['type']);
            filterMetricsInv[constants.METRIC_GENDER_FIELD] = value;
        }
        if (keysValue.includes(constants.METRIC_AGE_FIELD)) {
            let value = metricsA[constants.METRIC_AGE_FIELD];
            value = translateList(value, translations, ['type']);
            filterMetricsInv[constants.METRIC_AGE_FIELD] = value;
        }
    } else if (resource === 'groups') {
        if (keysValue.includes(constants.METRIC_GROUPS_LIST_INFO_FIELD)) {
            const value = metricsA[constants.METRIC_GROUPS_LIST_INFO_FIELD];
            filterMetricsInv[constants.METRIC_GROUPS_LIST_INFO_FIELD] = value;
        }
        if (keysValue.includes(constants.METRIC_TYPE_FIELD)) {
            const value = metricsA[constants.METRIC_TYPE_FIELD];
            filterMetricsInv[constants.METRIC_TYPE_FIELD] = value.filter(item => item.type !== 'Sin Categoría').sort((a, b) => orderCategories(a, b));
        }
        if (keysValue.includes(constants.METRIC_OCDE_FIELD)) {
            let value = metricsA[constants.METRIC_OCDE_FIELD];
            value = translateList(value, translations, ['type']);
            filterMetricsInv[constants.METRIC_OCDE_FIELD] = value;
        }
        if (keysValue.includes(constants.METRIC_AGENDA_FIELD)) {
            let value = metricsA[constants.METRIC_AGENDA_FIELD];
            value = translateList(value, translations, ['type']);
            filterMetricsInv[constants.METRIC_AGENDA_FIELD] = value;
        }
    } else if (resource === 'projects') {
        if (keysValue.includes(constants.METRIC_APPROBATION_FIELD)) {
            if (Object.keys(filterMetricsInv[constants.METRIC_APPROBATION_FIELD + '_']).length === 0) {
                filterMetricsInv[constants.METRIC_APPROBATION_FIELD] = [];
            } else {
                Object.entries(filterMetricsInv[constants.METRIC_APPROBATION_FIELD + '_']).forEach(([key, value]) => {
                    const translatedKey = translations[key] || key;
                    formatArrayType(constants.METRIC_APPROBATION_FIELD, filterMetricsInv, translatedKey, value);
                });
            }
        }
        if (keysValue.includes(constants.METRIC_APPROBATION_YEAR_FIELD)) {
            const keys = [resource, constants.METRIC_APPROBATION_YEAR_FIELD];
            let value = accumulateMetric(filterMetricsInv[constants.METRIC_APPROBATION_YEAR_FIELD], tools, keys, 'axis');
            value = translateList(value, translations, ['type']);
            filterMetricsInv[constants.METRIC_APPROBATION_YEAR_FIELD] = value;
        }
        if (keysValue.includes(constants.METRIC_STATE_FIELD)) {
            if (Object.keys(filterMetricsInv[constants.METRIC_STATE_FIELD + '_']).length === 0) {
                filterMetricsInv[constants.METRIC_STATE_FIELD] = [];
            } else {
                Object.entries(filterMetricsInv[constants.METRIC_STATE_FIELD + '_']).forEach(([key, value]) => {
                    const translatedKey = translations[key] || key;
                    formatArrayType(constants.METRIC_STATE_FIELD, filterMetricsInv, translatedKey, value);
                });
            }
        }
        if (keysValue.includes(constants.METRIC_STATE_YEAR_FIELD)) {
            const keys = [resource, constants.METRIC_STATE_YEAR_FIELD];
            let value = accumulateMetric(filterMetricsInv[constants.METRIC_STATE_YEAR_FIELD], tools, keys, 'axis');
            value = translateList(value, translations, ['type']);
            filterMetricsInv[constants.METRIC_STATE_YEAR_FIELD] = value;
        }
        if (keysValue.includes(constants.METRIC_DURATION_FIELD)) {
            if (Object.keys(filterMetricsInv[constants.METRIC_DURATION_FIELD + '_']).length === 0) {
                filterMetricsInv[constants.METRIC_DURATION_FIELD] = [];
            } else {
                Object.entries(filterMetricsInv[constants.METRIC_DURATION_FIELD + '_']).forEach(([key, value]) => {
                    const translatedKey = translations['Duración Original'] || 'Duración Original';
                    formatArrayTypeGroup(constants.METRIC_DURATION_FIELD, filterMetricsInv, translatedKey, key, value);
                });
            }
            Object.entries(filterMetricsInv[constants.METRIC_DURATION_PRORROGA_FIELD + '_']).forEach(([key, value]) => {
                const translatedKey = translations['Duración Real'] || 'Duración Real';
                formatArrayTypeGroup(constants.METRIC_DURATION_FIELD, filterMetricsInv, translatedKey, key, value);
            });
        }
        if (keysValue.includes(constants.METRIC_TYPOLOGY_FIELD)) {
            if (Object.keys(filterMetricsInv[constants.METRIC_TYPOLOGY_FIELD + '_']).length === 0) {
                filterMetricsInv[constants.METRIC_TYPOLOGY_FIELD] = [];
            } else {
                Object.entries(filterMetricsInv[constants.METRIC_TYPOLOGY_FIELD + '_']).forEach(([key, value]) => {
                    const translatedKey = translations[key] || key;
                    formatArrayType(constants.METRIC_TYPOLOGY_FIELD, filterMetricsInv, translatedKey, value);
                });
            }
        }
        if (keysValue.includes(constants.METRIC_TYPOLOGY_YEAR_FIELD)) {
            const keys = [resource, constants.METRIC_TYPOLOGY_YEAR_FIELD];
            let value = accumulateMetric(filterMetricsInv[constants.METRIC_TYPOLOGY_YEAR_FIELD], tools, keys, 'axis');
            value = translateList(value, translations, ['type']);
            filterMetricsInv[constants.METRIC_TYPOLOGY_YEAR_FIELD] = value;
        }
        if (keysValue.includes(constants.METRIC_GENDER_LEADERS_FIELD)) {
            let value = formatLeaders(metrics[constants.METRIC_GENDER_LEADERS_FIELD]);
            value = translateList(value, translations, ['group', 'type']);
            filterMetricsInv[constants.METRIC_GENDER_LEADERS_FIELD] = value;
        }
        if (keysValue.includes(constants.METRIC_GENDER_TYPES_FIELD)) {
            let value = formatTypes(metrics[constants.METRIC_GENDER_TYPES_FIELD]);
            value = translateList(value, translations, ['axis', 'type']);
            filterMetricsInv[constants.METRIC_GENDER_TYPES_FIELD] = value;
        }
        if (keysValue.includes(constants.METRIC_GRAPH_FIELD)) {
            const keys = [resource, constants.METRIC_GRAPH_FIELD];
            const value = formatGraph(metricsA, tools, keys);
            filterMetricsInv[constants.METRIC_GRAPH_FIELD] = value;
        }
    } else if (resource === 'areas') {
        if (keysValue.includes(constants.METRIC_WORDCLOUD_FIELD)) {
            const keys = [resource, constants.METRIC_WORDCLOUD_FIELD];
            const value = formatWordcloud(metricsA, tools, keys);
            filterMetricsInv[constants.METRIC_WORDCLOUD_FIELD] = value;
        }
        if (keysValue.includes(constants.METRIC_GRAPH_FIELD)) {
            const keys = [resource, constants.METRIC_GRAPH_FIELD];
            const value = formatGraph(metricsA, tools, keys);
            filterMetricsInv[constants.METRIC_GRAPH_FIELD] = value;
        }
        if (keysValue.includes(constants.METRIC_GRAPH_UAB_FIELD)) {
            const value = metricsA[constants.METRIC_GRAPH_UAB_FIELD];
            filterMetricsInv[constants.METRIC_GRAPH_UAB_FIELD] = Object.keys(value).length === 0 ? graphDefault : value;
        }
        if (keysValue.includes(constants.METRIC_GRAPH_KEYWORDS_FIELD)) {
            const value = metricsA[constants.METRIC_GRAPH_KEYWORDS_FIELD];
            filterMetricsInv[constants.METRIC_GRAPH_KEYWORDS_FIELD] = Object.keys(value).length === 0 ? graphDefault : value;
        }
        if (keysValue.includes(constants.METRIC_LDA_FIELD)) {
            const value = metricsA[constants.METRIC_LDA_FIELD];
            filterMetricsInv[constants.METRIC_LDA_FIELD] = Object.keys(value).length === 0 ? ldaDefault : value;
        }
    } else if (resource === 'productsList') {
        if (keysValue.includes(constants.METRIC_PUBLICATIONS_LIST_FIELD)) {
            const relevance = investigation.search?.search?.publications || {};
            const value = addRelevance(metricsA[constants.METRIC_PUBLICATIONS_LIST_FIELD], relevance);
            filterMetricsInv[constants.METRIC_PUBLICATIONS_LIST_FIELD] = value;
        }
        if (keysValue.includes(constants.METRIC_METRICS_FIELD)) {
            const value = metricsA[constants.METRIC_METRICS_FIELD];
            filterMetricsInv[constants.METRIC_METRICS_FIELD] = value;
        }
    }
    return filterMetricsInv;
}

export function metricsExt(keys, metrics, hierarchy, extension) {
    const resource = constants.RESOURCES[hierarchy.resource];
    const resourceType = hierarchy.hierarchy.hierarchy[0].toLowerCase();
    const resourceValue = hierarchy.hierarchy.hierarchy[1];

    let metricsExt = {
        metrics: {},
        hierarchy: hierarchy,
        filters: extension.filters
    };

    let rangeYear = metricsExt.filters.rangeTime.applied;
    let intervalTime = metricsExt.filters.intervalTime.applied;
    let dependence = metricsExt.filters.dependence.applied;
    if (!metricsExt.filters.rangeTime.type) {
        rangeYear = rangeYearsExt(metrics.filter(item => item[resourceType] === resourceValue && (resourceType === 'facultad' ? !('uab' in item) : true))[0]);
        metricsExt.filters.rangeTime.default = rangeYear;
    }
    metricsExt.filters.rangeTime.type = false;
    metricsExt.filters.rangeTime.applied = rangeYear;

    const filterMetrics = filterMetricsExt([resource, keys], metrics, rangeYear, intervalTime, dependence, extension);
    metricsExt.metrics = generateMetric(keys, resource, filterMetrics, metrics, extension.metrics);

    return metricsExt;
}

export function filterMetricsExt(keys, metrics, rangeYear, intervalTime, dependence, extension) {
    let metricsA = {};
    let filterMetricsExt = {};

    const tools = extension.metrics;
    const resource = keys[0];
    const keysValue = keys[1];

    metrics.forEach(item => {
        if (dependence.level === 'DEPENDENCE_FACULTY_FIELD' && item.facultad === dependence.name && !('uab' in dependence)) {
            metricsA = item;
        } else if (dependence.level === 'DEPENDENCE_UAB_FIELD' && item.uab === dependence.name) {
            metricsA = item;
        }
    });

    for (let year = rangeYear[0]; year <= rangeYear[1]; year++) {
        if (resource === 'general') {
            if (keysValue.includes(constants.METRIC_COST_FIELD)) {
                const metric = metricsA[constants.METRIC_COST_FIELD][constants.METRIC_YEARS_FIELD][year] || {};
                const value = filterIntervalValue(intervalTime, year, metric, 'sum');
                value.forEach(item => {
                    formatArrayAxis(constants.METRIC_COST_FIELD, filterMetricsExt, item[0], item[1]);
                });
            }
            if (keysValue.includes(constants.METRIC_ALL_VALUE_FIELD)) {
                const metric = metricsA[constants.METRIC_ALL_VALUE_FIELD][constants.METRIC_YEARS_FIELD][year] || {};
                const value = filterIntervalDict(intervalTime, year, metric, 'sum');
                value.forEach(item => {
                    formatArrayTypeGroup(constants.METRIC_ALL_VALUE_FIELD, filterMetricsExt, item[1], item[0], item[2]);
                });
            }
            if (keysValue.includes(constants.METRIC_ALLIES_COUNT_FIELD)) {
                const value = metricsA[constants.METRIC_ALLIES_COUNT_FIELD][constants.METRIC_YEARS_FIELD][year]?.[constants.METRIC_COUNT_YEAR_FIELD] || 0;
                addValueDict(constants.METRIC_ALLIES_COUNT_FIELD + '_', filterMetricsExt, value);
            }
            if (keysValue.includes(constants.METRIC_ALLIES_VALUE_FIELD)) {
                const value = metricsA[constants.METRIC_ALLIES_VALUE_FIELD][constants.METRIC_YEARS_FIELD][year]?.[constants.METRIC_COUNT_YEAR_FIELD] || 0;
                addValueDict(constants.METRIC_ALLIES_VALUE_FIELD + '_', filterMetricsExt, value);
            }
            if (keysValue.includes(constants.METRIC_LOCATIONS_FIELD)) {
                const value = metricsA[constants.METRIC_LOCATIONS_FIELD][constants.METRIC_YEARS_FIELD][year]?.[constants.METRIC_COUNT_YEAR_FIELD] || [];
                addValueDictArrayConcat(constants.METRIC_LOCATIONS_FIELD + '_', filterMetricsExt, value, ['city', 'regionCode', 'qty']);
            }
        } else if (resource === 'projects') {
            if (keysValue.includes(constants.METRIC_COUNT_FIELD)) {
                const metric = metricsA[constants.METRIC_COUNT_FIELD][constants.METRIC_YEARS_FIELD][year] || {};
                const value = filterIntervalValue(intervalTime, year, metric, 'count');
                value.forEach(item => {
                    formatArrayAxis(constants.METRIC_COUNT_FIELD, filterMetricsExt, item[0], item[1]);
                });
            }
            if (keysValue.includes(constants.METRIC_VALUE_FIELD)) {
                const metric = metricsA[constants.METRIC_VALUE_FIELD][constants.METRIC_YEARS_FIELD][year] || {};
                const value = filterIntervalValue(intervalTime, year, metric, 'sum');
                value.forEach(item => {
                    formatArrayAxis(constants.METRIC_VALUE_FIELD, filterMetricsExt, item[0], item[1]);
                });
            }
            if (keysValue.includes(constants.METRIC_CLASSIFICATION_FIELD)) {
                const metric = metricsA[constants.METRIC_CLASSIFICATION_FIELD][constants.METRIC_YEARS_FIELD][year] || {};
                const value = filterIntervalDict(intervalTime, year, metric, 'count');
                value.forEach(item => {
                    formatArrayTypeAxis(constants.METRIC_CLASSIFICATION_FIELD, filterMetricsExt, formatCapitalize(item[1]), item[0], item[2]);
                });
            }
            if (keysValue.includes(constants.METRIC_ORIGEN_FIELD)) {
                const value = metricsA[constants.METRIC_ORIGEN_FIELD][constants.METRIC_YEARS_FIELD][year]?.[constants.METRIC_COUNT_YEAR_FIELD] || 0;
                addValueDict(constants.METRIC_ORIGEN_FIELD + '_', filterMetricsExt, value);
            }
        } else if (resource === 'opportunities') {
            if (keysValue.includes(constants.METRIC_COUNTS_FIELD)) {
                const metric = metricsA[constants.METRIC_COUNTS_FIELD][constants.METRIC_YEARS_FIELD][year] || {};
                const value = filterIntervalDict(intervalTime, year, metric, 'count');
                value.forEach(item => {
                    formatArrayTypeGroup(constants.METRIC_COUNTS_FIELD, filterMetricsExt, constants.OPPORTUNITIES_TYPE[item[1]], item[0], item[2]);
                });
            }
        } else if (resource === 'activities') {
            if (keysValue.includes(constants.METRIC_COUNT_FIELD)) {
                const metric = metricsA[constants.METRIC_COUNT_FIELD][constants.METRIC_YEARS_FIELD][year] || {};
                const value = filterIntervalValue(intervalTime, year, metric, 'count');
                value.forEach(item => {
                    formatArrayAxis(constants.METRIC_COUNT_FIELD, filterMetricsExt, item[0], item[1]);
                });
            }
            if (keysValue.includes(constants.METRIC_ENROLLED_FIELD)) {
                const metric = metricsA[constants.METRIC_ENROLLED_FIELD][constants.METRIC_YEARS_FIELD][year] || {};
                const value = filterIntervalValue(intervalTime, year, metric, 'count');
                value.forEach(item => {
                    formatArrayAxis(constants.METRIC_ENROLLED_FIELD, filterMetricsExt, item[0], item[1]);
                });
            }
            if (keysValue.includes(constants.METRIC_TYPE_COUNT_FIELD)) {
                const metric = metricsA[constants.METRIC_TYPE_COUNT_FIELD][constants.METRIC_YEARS_FIELD][year] || {};
                const value = filterIntervalDict(intervalTime, year, metric, 'count');
                value.forEach(item => {
                    formatArrayTypeGroup(constants.METRIC_TYPE_COUNT_FIELD, filterMetricsExt, item[1], item[0], item[2]);
                });
            }
            if (keysValue.includes(constants.METRIC_MOD_INTENSITY_FIELD)) {
                const metric = metricsA[constants.METRIC_MOD_INTENSITY_FIELD][constants.METRIC_YEARS_FIELD][year] || {};
                const value = filterIntervalDict(intervalTime, year, metric, 'value');
                value.forEach(item => {
                    Object.entries(item[2]).forEach(([subItem, value]) => {
                        formatArrayTypeAxis(constants.METRIC_MOD_INTENSITY_FIELD, filterMetricsExt, [item[1].replace('_', ' '), ' (', subItem, ')'].join(''), item[0], value);
                    });
                });
            }
            if (keysValue.includes(constants.METRIC_MOD_ENROLLED_FIELD)) {
                const metric = metricsA[constants.METRIC_MOD_ENROLLED_FIELD][constants.METRIC_YEARS_FIELD][year] || {};
                const value = filterIntervalDict(intervalTime, year, metric, 'count');
                value.forEach(item => {
                    formatArrayTypeAxis(constants.METRIC_MOD_ENROLLED_FIELD, filterMetricsExt, item[1], item[0], item[2]);
                });
            }
            if (keysValue.includes(constants.METRIC_MOD_COUNT_FIELD)) {
                const metric = metricsA[constants.METRIC_MOD_COUNT_FIELD][constants.METRIC_YEARS_FIELD][year] || {};
                const value = filterIntervalDict(intervalTime, year, metric, 'count');
                value.forEach(item => {
                    formatArrayTypeAxis(constants.METRIC_MOD_COUNT_FIELD, filterMetricsExt, item[1], item[0], item[2]);
                });
            }
        }
    }
    if (resource === 'general') {
        if (keysValue.includes(constants.METRIC_COST_FIELD)) {
            const keys = [resource, constants.METRIC_COST_FIELD];
            const value = accumulateMetric(filterMetricsExt[constants.METRIC_COST_FIELD], tools, keys, 'axis');
            filterMetricsExt[constants.METRIC_COST_FIELD] = value;
        }
        if (keysValue.includes(constants.METRIC_ALL_VALUE_FIELD)) {
            const keys = [resource, constants.METRIC_ALL_VALUE_FIELD];
            const value = accumulateMetric(filterMetricsExt[constants.METRIC_ALL_VALUE_FIELD], tools, keys, 'group');
            filterMetricsExt[constants.METRIC_ALL_VALUE_FIELD] = value;
        }
        if (keysValue.includes(constants.METRIC_ALLIES_COUNT_FIELD)) {
            Object.entries(filterMetricsExt[constants.METRIC_ALLIES_COUNT_FIELD + '_']).forEach(([key, value]) => {
                formatArrayType(constants.METRIC_ALLIES_COUNT_FIELD, filterMetricsExt, key, value);
            });
        }
        if (keysValue.includes(constants.METRIC_ALLIES_VALUE_FIELD)) {
            Object.entries(filterMetricsExt[constants.METRIC_ALLIES_VALUE_FIELD + '_']).forEach(([key, value]) => {
                formatArrayType(constants.METRIC_ALLIES_VALUE_FIELD, filterMetricsExt, key, value);
            });
        }
        if (keysValue.includes(constants.METRIC_LOCATIONS_FIELD)) {
            Object.entries(filterMetricsExt[constants.METRIC_LOCATIONS_FIELD + '_']).forEach(([key, value]) => {
                const keys = key.split('|');
                if (keys[0] !== 'Colombia') {
                    formatArrayTypeGroup(constants.METRIC_LOCATIONS_FIELD, filterMetricsExt, keys[0], keys[1], value);
                }
            });
        }
    } else if (resource === 'projects') {
        if (keysValue.includes(constants.METRIC_COUNT_FIELD)) {
            const keys = [resource, constants.METRIC_COUNT_FIELD];
            const value = accumulateMetric(filterMetricsExt[constants.METRIC_COUNT_FIELD], tools, keys, 'axis');
            filterMetricsExt[constants.METRIC_COUNT_FIELD] = value;
        }
        if (keysValue.includes(constants.METRIC_VALUE_FIELD)) {
            const keys = [resource, constants.METRIC_VALUE_FIELD];
            const value = accumulateMetric(filterMetricsExt[constants.METRIC_VALUE_FIELD], tools, keys, 'axis');
            filterMetricsExt[constants.METRIC_VALUE_FIELD] = value;
        }
        if (keysValue.includes(constants.METRIC_CLASSIFICATION_FIELD)) {
            const keys = [resource, constants.METRIC_CLASSIFICATION_FIELD];
            const value = accumulateMetric(filterMetricsExt[constants.METRIC_CLASSIFICATION_FIELD], tools, keys, 'axis');
            filterMetricsExt[constants.METRIC_CLASSIFICATION_FIELD] = value;
        }
        if (keysValue.includes(constants.METRIC_TOP_FIELD)) {
            const value = metricsA[constants.METRIC_TOP_FIELD][constants.METRIC_TOP_LIST_FIELD] || [];
            value.forEach(item => {
                formatArrayType(constants.METRIC_TOP_FIELD, filterMetricsExt, item['director'], item['participaciones']);
            });
        }
        if (keysValue.includes(constants.METRIC_ORIGEN_FIELD)) {
            Object.entries(filterMetricsExt[constants.METRIC_ORIGEN_FIELD + '_']).forEach(([key, value]) => {
                formatArrayType(constants.METRIC_ORIGEN_FIELD, filterMetricsExt, key, value);
            });
        }
        if (keysValue.includes(constants.METRIC_WORDCLOUD_FIELD)) {
            const clouds = {
                [constants.METRIC_SUBJECTS__FIELD]: constants.METRIC_SUBJECTS_FIELD,
                [constants.METRIC_TOPICS__FIELD]: constants.METRIC_TOPICS_FIELD,
                [constants.METRIC_WORDCLOUD_FIELD]: constants.METRIC_WORDCLOUD_FIELD
            };
            const languages = ['eng', 'spa'];
            Object.entries(clouds).forEach(([item, item_]) => {
                languages.forEach(language => {
                    const cloud = metricsA[constants.METRIC_WORDCLOUD_FIELD][item + language] || [];
                    metricsA[item_ + '_' + language] = [];
                    Object.entries(cloud).forEach(([key, value], index) => {
                        metricsA[item_ + '_' + language].push({
                            _id: index,
                            name: key,
                            value: value
                        });
                    });
                });
            });
            const keys = [resource, constants.METRIC_WORDCLOUD_FIELD];
            const value = formatWordcloud(metricsA, tools, keys);
            filterMetricsExt[constants.METRIC_WORDCLOUD_FIELD] = value;
        }
    } else if (resource === 'opportunities') {
        if (keysValue.includes(constants.METRIC_COUNTS_FIELD)) {
            const keys = [resource, constants.METRIC_COUNTS_FIELD];
            const value = accumulateMetric(filterMetricsExt[constants.METRIC_COUNTS_FIELD], tools, keys, 'group');
            filterMetricsExt[constants.METRIC_COUNTS_FIELD] = value;
        }
    } else if (resource === 'activities') {
        if (keysValue.includes(constants.METRIC_COUNT_FIELD)) {
            const keys = [resource, constants.METRIC_COUNT_FIELD];
            const value = accumulateMetric(filterMetricsExt[constants.METRIC_COUNT_FIELD], tools, keys, 'axis');
            filterMetricsExt[constants.METRIC_COUNT_FIELD] = value;
        }
        if (keysValue.includes(constants.METRIC_ENROLLED_FIELD)) {
            const keys = [resource, constants.METRIC_ENROLLED_FIELD];
            const value = accumulateMetric(filterMetricsExt[constants.METRIC_ENROLLED_FIELD], tools, keys, 'axis');
            filterMetricsExt[constants.METRIC_ENROLLED_FIELD] = value;
        }
        if (keysValue.includes(constants.METRIC_TYPE_COUNT_FIELD)) {
            const keys = [resource, constants.METRIC_TYPE_COUNT_FIELD];
            const value = accumulateMetric(filterMetricsExt[constants.METRIC_TYPE_COUNT_FIELD], tools, keys, 'group');
            filterMetricsExt[constants.METRIC_TYPE_COUNT_FIELD] = value;
        }
        if (keysValue.includes(constants.METRIC_MOD_INTENSITY_FIELD)) {
            const keys = [resource, constants.METRIC_MOD_INTENSITY_FIELD];
            const value = accumulateMetric(filterMetricsExt[constants.METRIC_MOD_INTENSITY_FIELD], tools, keys, 'axis');
            filterMetricsExt[constants.METRIC_MOD_INTENSITY_FIELD] = value;
        }
        if (keysValue.includes(constants.METRIC_MOD_ENROLLED_FIELD)) {
            const keys = [resource, constants.METRIC_MOD_ENROLLED_FIELD];
            const value = accumulateMetric(filterMetricsExt[constants.METRIC_MOD_ENROLLED_FIELD], tools, keys, 'axis');
            filterMetricsExt[constants.METRIC_MOD_ENROLLED_FIELD] = value;
        }
        if (keysValue.includes(constants.METRIC_MOD_COUNT_FIELD)) {
            const keys = [resource, constants.METRIC_MOD_COUNT_FIELD];
            const value = accumulateMetric(filterMetricsExt[constants.METRIC_MOD_COUNT_FIELD], tools, keys, 'axis');
            filterMetricsExt[constants.METRIC_MOD_COUNT_FIELD] = value;
        }
    }
    return filterMetricsExt;
}

export function rangeYears(years) {
    let range = Object.keys(years).map(Number).filter(key => Number.isInteger(key) && key > 0);
    return [Math.min(...range), Math.max(...range)];
}

export function rangeYearsExt(metrics) {
    let keys = Object.keys(metrics || {});
    let dates = {};
    keys.forEach(item => {
        if (typeof metrics[item] === 'object') {
            if ('years' in metrics[item]) {
                Object.keys(metrics[item].years).forEach((year) => {
                    dates[year] = [];
                })
            }
        }
    });
    return rangeYears(dates)
}

export function genderMetric(metric, tools, keys) {
    const gender = tools[keys[0]][keys[1]].tools.gender.value.value;
    if (gender === constants.METRIC_FEMALE_FIELD) {
        return metric[constants.METRIC_FEMALE_FIELD][keys[2]];
    } else if (gender === constants.METRIC_MALE_FIELD) {
        return metric[constants.METRIC_MALE_FIELD][keys[2]];
    } else {
        return metric[constants.METRIC_BOTH_FIELD][keys[2]];
    }
}

export function accumulateMetric(metric, tools, keys, axisValue) {
    let accumulateMetric = [];

    const metricOrdered = sortedArrayMultiple(metric, axisValue, 'type');
    const accumulate = tools[keys[0]][keys[1]].tools.accumulate.value;

    if (accumulate) {
        let metricAccumulate = {};
        const dataAxis = [...new Set(metricOrdered.map(item => item[axisValue]))];

        dataAxis.forEach(axis => {
            var dataAxisItemArray = metricOrdered.filter((val) => val?.[axisValue] === axis);
            var dataAxisItemObject = {};
            dataAxisItemArray.forEach(item => {
                dataAxisItemObject[item.type] = item.value;
            });
            Object.keys(metricAccumulate).forEach(item => {
                dataAxisItemObject[item] = metricAccumulate[item] + (dataAxisItemObject[item] || 0);
            });
            Object.keys(dataAxisItemObject).forEach(type => {
                metricAccumulate[type] = dataAxisItemObject[type];
                accumulateMetric.push({
                    _id: axis + type,
                    [axisValue]: axis,
                    type: type,
                    value: dataAxisItemObject[type],
                });
            });

        });
    } else {
        accumulateMetric = metricOrdered;
    }
    return accumulateMetric;
}

export function generateMetric(keys, resource, metrics, source, states) {
    let metric = { source: source };
    keys.forEach(item => {
        if (item !== 'source' && item in metrics) {
            metric[item] = {
                tools: states[resource][item].tools,
                processed: metrics[item]
            }
        }
    })

    return { [resource]: metric };
}

export function filterDict(dictValue, keys) {
    return Object.fromEntries(Object.entries(dictValue).filter(([item]) => keys.includes(item)));
}

export function filterIntervalValue(type, year, metric, variant) {
    let filterIntervalValue = [];
    let variants = {
        'sum': {
            'year': constants.METRIC_SUM_YEAR_FIELD,
            'month': constants.METRIC_SUM_MONTH_FIELD,
            'semester': constants.METRIC_SUM_SEMESTER_FIELD
        },
        'count': {
            'year': constants.METRIC_COUNT_YEAR_FIELD,
            'month': constants.METRIC_COUNT_MONTH_FIELD,
            'semester': constants.METRIC_COUNT_SEMESTER_FIELD
        },
        'value': {
            'year': constants.METRIC_VALUE_YEAR_FIELD,
            'month': constants.METRIC_VALUE_MONTH_FIELD,
            'semester': constants.METRIC_VALUE_SEMESTER_FIELD
        }
    };
    if (type.value[0] === 'year') {
        filterIntervalValue.push([year, metric[variants[variant]['year']] || 0]);
    } else if (type.value[0] === 'month') {
        const dict_value = metric[variants[variant]['month']] || {};
        Object.entries(dict_value).forEach(([key, value]) => {
            filterIntervalValue.push([[year, key].join('-'), value]);
        });
    } else if (type.value[0] === 'semester') {
        const dict_value = metric[variants[variant]['semester']] || {};
        Object.entries(dict_value).forEach(([key, value]) => {
            filterIntervalValue.push([[year, constants.SEMESTER_TYPE[key]].join('-'), value]);
        });
    }
    return filterIntervalValue;
}

export function filterIntervalDict(type, year, metric, variant) {
    let filterIntervalDict = [];
    let variants = {
        'sum': {
            'year': constants.METRIC_SUM_YEAR_FIELD,
            'month': constants.METRIC_SUM_MONTH_FIELD,
            'semester': constants.METRIC_SUM_SEMESTER_FIELD
        },
        'count': {
            'year': constants.METRIC_COUNT_YEAR_FIELD,
            'month': constants.METRIC_COUNT_MONTH_FIELD,
            'semester': constants.METRIC_COUNT_SEMESTER_FIELD
        },
        'value': {
            'year': constants.METRIC_VALUE_YEAR_FIELD,
            'month': constants.METRIC_VALUE_MONTH_FIELD,
            'semester': constants.METRIC_VALUE_SEMESTER_FIELD
        }
    };
    if (type.value[0] === 'year') {
        Object.entries(metric).forEach(([item, itemValue]) => {
            filterIntervalDict.push([year, item, itemValue[variants[variant]['year']] || {}]);
        });
    } else if (type.value[0] === 'month') {
        Object.entries(metric).forEach(([item, itemValue]) => {
            const dict_value = itemValue[variants[variant]['month']] || {};
            Object.entries(dict_value).forEach(([key, value]) => {
                filterIntervalDict.push([[year, key].join('-'), item, value]);
            });
        });
    } else if (type.value[0] === 'semester') {
        Object.entries(metric).forEach(([item, itemValue]) => {
            const dict_value = itemValue[variants[variant]['semester']] || {};
            Object.entries(dict_value).forEach(([key, value]) => {
                filterIntervalDict.push([[year, constants.SEMESTER_TYPE[key]].join('-'), item, value]);
            });
        });
    }
    return filterIntervalDict;
}

export function objectAssignInv(defaultData, sourceData) {
    const objectAssignInv = {
        ...defaultData,
        search: { ...defaultData?.search, ...sourceData?.search },
        metrics: {
            ...defaultData?.metrics,
            general: { ...defaultData?.metrics?.general, ...sourceData?.metrics?.general },
            products: { ...defaultData?.metrics?.products, ...sourceData?.metrics?.products },
            researchers: { ...defaultData?.metrics?.researchers, ...sourceData?.metrics?.researchers },
            groups: { ...defaultData?.metrics?.groups, ...sourceData?.metrics?.groups },
            projects: { ...defaultData?.metrics?.projects, ...sourceData?.metrics?.projects },
            areas: { ...defaultData?.metrics?.areas, ...sourceData?.metrics?.areas },
            productsList: { ...defaultData?.metrics?.productsList, ...sourceData?.metrics?.productsList }
        },
        hierarchy: {
            ...defaultData?.hierarchy, ...sourceData?.hierarchy,
            metrics: { ...defaultData?.hierarchy?.metrics, ...sourceData?.hierarchy?.metrics },
            hierarchy: { ...defaultData?.hierarchy?.hierarchy, ...sourceData?.hierarchy?.hierarchy }
        },
        filters: { ...defaultData?.filters, ...sourceData?.filters }
    };
    return objectAssignInv;
}

export function objectAssignExt(defaultData, sourceData) {
    const objectAssignExt = {
        ...defaultData,
        search: { ...defaultData?.search, ...sourceData?.search },
        metrics: {
            ...defaultData?.metrics,
            general: { ...defaultData?.metrics?.general, ...sourceData?.metrics?.general },
            projects: { ...defaultData?.metrics?.projects, ...sourceData?.metrics?.projects },
            opportunities: { ...defaultData?.metrics?.opportunities, ...sourceData?.metrics?.opportunities },
            activities: { ...defaultData?.metrics?.activities, ...sourceData?.metrics?.activities },
            teacher: { ...defaultData?.metrics?.teacher, ...sourceData?.metrics?.teacher },
        },
        hierarchy: {
            ...defaultData?.hierarchy, ...sourceData?.hierarchy,
            hierarchy: { ...defaultData?.hierarchy?.hierarchy, ...sourceData?.hierarchy?.hierarchy }
        },
        filters: { ...defaultData?.filters, ...sourceData?.filters },
    };
    return objectAssignExt;
}

export function objectAssignLog(defaultData, sourceData) {
    const objectAssignLog = {
        ...defaultData,
        admin: { ...defaultData?.admin, ...sourceData?.admin },
        curator: { ...defaultData?.curator, ...sourceData?.curator },
        usability: { ...defaultData?.usability, ...sourceData?.usability },
        feedback: { ...defaultData?.feedback, ...sourceData?.feedback },
        news: { ...defaultData?.news, ...sourceData?.news },
    };
    return objectAssignLog;
}

export function addValue(key, dict, value) {
    dict[key] = (dict[key] || 0) + value;
}

export function addValueDict(key, dict, dictValue, keysExclude = []) {
    if (!(key in dict)) dict[key] = {};
    Object.entries(dictValue).forEach(([key_, value]) => {
        if (!keysExclude.includes(key_)) {
            dict[key][key_] = (dict[key][key_] || 0) + value;
        }
    });
}

export function addValueDictArrayConcat(key, dict, arrayValue, keys, keysExclude = []) {
    if (!(key in dict)) dict[key] = {};
    arrayValue.forEach(item => {
        if (!keysExclude.includes(item)) {
            const keyDict = item[keys[0]];
            const keyConcat = item[keys[1]];
            const keyValue = [keyDict, keyConcat].join('|');
            const value = item[keys[2]];
            dict[key][keyValue] = (dict[key][keyValue] || 0) + value;
        }
    });
}

export function addValueDictArray(key, dict, arrayValue, keys, keysExclude = []) {
    if (!(key in dict)) dict[key] = {};
    arrayValue.forEach(item => {
        if (!keysExclude.includes(item)) {
            const keyDict = item[keys[0]];
            const value = item[keys[1]];
            dict[key][keyDict] = (dict[key][keyDict] || 0) + value;
        }
    });
}

export function addRelevance(data, relevance) {
    let value = data;
    if (Object.keys(relevance).length > 0) {
        value?.forEach(item => {
            item.relevance = relevance?.[item[constants.PUBLICATION_ID_FIELD]] || 0
        });
    }
    return value;
}

export function addAuthorValueDependences(data) {
    let value = data;
    value?.forEach(item => {
        let authors = item[constants.PUBLICATION_AUTHORS_FIELD];
        let authorsValue = [];

        authors.forEach(author => {
            const name = author[constants.AUTHOR_NAME_FIELD] || author.name;
            const strName = typeof name === 'object' ? name[constants.PUBLICATION_NORMALIZED_SUBFIELD] || '' : name;
            authorsValue.push(strName);

            author[constants.AUTHOR_NAME_FIELD] = strName;
            if (!(constants.AUTHOR_INSTITUTION_FIELD in author)) {
                author[constants.AUTHOR_INSTITUTION_FIELD] = constants.DEPENDENCE_INSTITUTION_VALUE_FIELD;
            }
            if (!(constants.AUTHOR_CAMPUS_FIELD in author)) {
                author[constants.AUTHOR_CAMPUS_FIELD] = constants.DEPENDENCE_INSTITUTION_VALUE_FIELD;
            }
            if (!(constants.AUTHOR_FACULTY_FIELD in author)) {
                author[constants.AUTHOR_FACULTY_FIELD] = constants.DEPENDENCE_INSTITUTION_VALUE_FIELD;
            }
            if (!(constants.AUTHOR_UAB_FIELD in author)) {
                author[constants.AUTHOR_UAB_FIELD] = constants.DEPENDENCE_INSTITUTION_VALUE_FIELD;
            }
            if (!(constants.AUTHOR_GENDER_FIELD in author)) {
                author[constants.AUTHOR_GENDER_FIELD] = 'F';
            }
            if (!(constants.AUTHOR_IMAGE_FIELD in author)) {
                author[constants.AUTHOR_IMAGE_FIELD] = '';
            }
        });

        if (!(constants.PUBLICATION_AUTHORS_VALUE_FIELD in item)) {
            item[constants.PUBLICATION_AUTHORS_VALUE_FIELD] = authorsValue;
        }
    });
    return value;
}

export function formatMessage(open, severity, message) {
    return {
        open: open,
        severity: severity,
        message: '' + message,
    };
}

export function formatNumber(number) {
    return numeral(number).format('0,0');
}

export function formatNumberFloat(number) {
    return numeral(number).format('0.00');
}

export function formatArrayType(key, dict, type, value) {
    if (!(key in dict)) dict[key] = [];
    const _id = dict[key].length + 1;
    dict[key].push({
        _id: _id,
        type: type,
        value: value,
    });
}

export function formatArrayTypeAxis(key, dict, type, axis, value) {
    if (!(key in dict)) dict[key] = [];
    const _id = dict[key].length + 1;
    dict[key].push({
        _id: _id,
        type: type,
        axis: axis,
        value: value,
    });
}

export function formatArrayTypeGroup(key, dict, type, group, value) {
    if (!(key in dict)) dict[key] = [];
    const _id = dict[key].length + 1;
    dict[key].push({
        _id: _id,
        type: type,
        group: group,
        value: value,
    });
}

export function formatArrayAxis(key, dict, axis, value) {
    if (!(key in dict)) dict[key] = [];
    const _id = dict[key].length + 1;
    dict[key].push({
        _id: _id,
        axis: axis,
        value: value,
    });
}

export function formatGraph(metricsA, tools, keys) {
    const graph = metricsA[constants.METRIC_GRAPH_FIELD];
    const number = tools[keys[0]][keys[1]].tools.graphNumber.value;
    const type = tools[keys[0]][keys[1]].tools.graphType.value.value;

    let formatGraph = { nodes: [], edges: [], links: [] };
    if (graph) {
        if (graph.nodes.length > 0) {
            const nodes = graph.nodes.sort((a, b) => b['is-in'] - a['is-in'] || b.value - a.value).slice(0, type === constants.GRAPHS_TYPE.FORCE ? 999999999 : number);
            const nodesSelected = nodes.map(item => item.id);
            const edges = graph.edges.filter(item => nodesSelected.includes(item.source) && nodesSelected.includes(item.target));
            formatGraph.nodes = nodes;
            formatGraph.edges = edges;
        }
    }
    return formatGraph;
}

export function formatChoropleth(dictValue, translations) {
    let formatChoropleth = [];
    WorldMap.features.forEach((item, index) => {
        if (item.id && item.id in dictValue) {
            formatChoropleth.push({
                _id: index,
                type: translations[item.id] || WorldName[item.id],
                group: item.id,
                value: dictValue[item.id]
            });
        }
    });
    return formatChoropleth;
}

export function formatThesis(dictValue, tools, keys) {
    let formatThesis = [];
    const thesisNames = {
        'gender-thesis-undergraduate': 'Tesis de pregrado',
        'gender-thesis-master': 'Tesis de maestría',
        'gender-thesis-specialization': 'Tesis de especialización',
        'gender-thesis-doctor': 'Tesis de doctorado'
    };
    const chart = tools[keys[0]][keys[1]].tools.chart.value.value;
    if (dictValue.gender_thesis) {
        if (chart === 'quantity') {
            Object.entries(dictValue.gender_thesis).forEach(([item, value], index) => {
                const thesis = thesisNames[item];
                formatThesis.push(
                    {
                        _id: index + 'TF',
                        axis: thesis,
                        type: 'Total femenino',
                        group: 'Femenino',
                        value: dictValue.gender[constants.METRIC_FEMALE_FIELD]
                    },
                    {
                        _id: index + 'DF',
                        axis: thesis,
                        type: 'Directoras',
                        group: 'Femenino',
                        value: value[constants.METRIC_FEMALE_FIELD]
                    },
                    {
                        _id: index + 'TM',
                        axis: thesis,
                        type: 'Total masculino',
                        group: 'Masculino',
                        value: dictValue.gender[constants.METRIC_MALE_FIELD]
                    },
                    {
                        _id: index + 'DM',
                        axis: thesis,
                        type: 'Directores',
                        group: 'Masculino',
                        value: value[constants.METRIC_MALE_FIELD]
                    }
                );
            });
        } else if (chart === 'proportion') {
            Object.entries(dictValue.gender_thesis).forEach(([item, value], index) => {
                const thesis = thesisNames[item];
                formatThesis.push(
                    {
                        _id: index + 'TF',
                        axis: thesis,
                        type: 'No directoras',
                        group: 'Femenino',
                        value: 100 * ((dictValue.gender[constants.METRIC_FEMALE_FIELD] - value[constants.METRIC_FEMALE_FIELD]) / dictValue.gender[constants.METRIC_FEMALE_FIELD])
                    },
                    {
                        _id: index + 'DF',
                        axis: thesis,
                        type: 'Directoras',
                        group: 'Femenino',
                        value: 100 * (value[constants.METRIC_FEMALE_FIELD] / dictValue.gender[constants.METRIC_FEMALE_FIELD])
                    },
                    {
                        _id: index + 'TM',
                        axis: thesis,
                        type: 'No directores',
                        group: 'Masculino',
                        value: 100 * ((dictValue.gender[constants.METRIC_MALE_FIELD] - value[constants.METRIC_MALE_FIELD]) / dictValue.gender[constants.METRIC_MALE_FIELD]),
                    },
                    {
                        _id: index + 'DM',
                        axis: thesis,
                        type: 'Directores',
                        group: 'Masculino',
                        value: 100 * (value[constants.METRIC_MALE_FIELD] / dictValue.gender[constants.METRIC_MALE_FIELD])
                    }
                );
            });
        }
    }
    return formatThesis;
}

export function formatLeaders(dictValue) {
    let formatLeaders = [];
    if (dictValue.gender_leaders) {
        formatLeaders.push(
            {
                _id: 1,
                group: 'Femenino',
                type: 'Líderes femeninas',
                value: dictValue.gender_leaders[constants.METRIC_FEMALE_FIELD],
                percent: formatPercent(dictValue.gender_leaders[constants.METRIC_FEMALE_FIELD] / dictValue.gender[constants.METRIC_FEMALE_FIELD]),
            },
            {
                _id: 2,
                group: 'Femenino',
                type: 'No líderes',
                value: dictValue.gender[constants.METRIC_FEMALE_FIELD] - dictValue.gender_leaders[constants.METRIC_FEMALE_FIELD],
                percent: formatPercent((dictValue.gender[constants.METRIC_FEMALE_FIELD] - dictValue.gender_leaders[constants.METRIC_FEMALE_FIELD]) / dictValue.gender[constants.METRIC_FEMALE_FIELD]),
            },
            {
                _id: 3,
                group: 'Masculino',
                type: 'Líderes masculinos',
                value: dictValue.gender_leaders[constants.METRIC_MALE_FIELD],
                percent: formatPercent(dictValue.gender_leaders[constants.METRIC_MALE_FIELD] / dictValue.gender[constants.METRIC_MALE_FIELD]),
            },
            {
                _id: 4,
                group: 'Masculino',
                type: 'No líderes',
                value: dictValue.gender[constants.METRIC_MALE_FIELD] - dictValue.gender_leaders[constants.METRIC_MALE_FIELD],
                percent: formatPercent((dictValue.gender[constants.METRIC_MALE_FIELD] - dictValue.gender_leaders[constants.METRIC_MALE_FIELD]) / dictValue.gender[constants.METRIC_MALE_FIELD]),
            }
        );

    }
    return formatLeaders;
}

export function formatTypes(dictValue) {
    let formatTypes = [];
    const typesNames = {
        'gender-types-basic': 'Investigación básica',
        'gender-types-applies': 'Investigación aplicada'
    };
    if (dictValue) {
        Object.entries(dictValue).forEach(([item, value], index) => {
            const types = typesNames[item];
            formatTypes.push(
                {
                    _id: index + 'F',
                    axis: types,
                    type: 'Femenino',
                    value: value[constants.METRIC_FEMALE_FIELD]
                },
                {
                    _id: index + 'M',
                    axis: types,
                    type: 'Masculino',
                    value: value[constants.METRIC_MALE_FIELD]
                }
            );
        });
    }
    return formatTypes;
}

export function formatWordcloud(metricsA, tools, keys) {
    const topics = tools[keys[0]][keys[1]].tools.wordCloudType.value.value;
    const language = tools[keys[0]][keys[1]].tools.wordCloudLanguage.value.value ? '_spa' : '_eng';
    const field = topics + language;
    return metricsA ? metricsA[field] : [];
}

export function formatDownload(data) {
    let newData = [];
    const thesis_type = constants.PUBLICATION_THESIS_TYPES;
    data.forEach(item => {
        newData.push({
            ...item,
            [constants.PUBLICATION_PUBNAME_FIELD]: thesis_type.includes(item[constants.PUBLICATION_PUBNAME_FIELD] || '') ? '' : item[constants.PUBLICATION_PUBNAME_FIELD] || item[constants.PUBLICATION_PUBLISHER_FIELD],
            [constants.PUBLICATION_AUTHORS_FIELD]: item[constants.PUBLICATION_AUTHORS_FIELD].map(author => author[constants.AUTHOR_NAME_FIELD]).join(', '),
            [constants.PUBLICATION_ISBN_FIELD]: (item[constants.PUBLICATION_ISBN_FIELD] || []).join(', '),
            [constants.PUBLICATION_ISSN_FIELD]: (item[constants.PUBLICATION_ISSN_FIELD] || []).join(', '),
            [constants.PUBLICATION_LANGUAGE_FIELD]: constants.LANGUAGE_ES[item[constants.PUBLICATION_LANGUAGE_FIELD]] || '',
            [constants.PUBLICATION_DATE_FIELD]: formatDate(item[constants.PUBLICATION_DATE_FIELD]),
            [constants.PUBLICATION_URL_FIELD]: (item[constants.PUBLICATION_URL_FIELD] || []).join('  -  ')
        });
    });
    return newData;
}

export function formatDownloadCsv(id, data) {
    const filteredData = data.rows.map(row => {
        const filteredRow = {};
        data.headers.forEach(header => {
            filteredRow[header.headerName] = row[header.field];
        });
        return filteredRow;
    });
    const csvData = Papa.unparse({
        fields: data.headers.map(h => h.headerName),
        data: filteredData.map(d => data.headers.map(h => d[h.headerName]))
    });
    const utf8Bom = '\uFEFF';
    const blob = new Blob([utf8Bom + csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', id + '.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function formatDownloadScreen(id) {
    html2canvas(document.getElementById(id + 'screen')).then((canvas) => {
        let url = document.createElement('a');
        url.download = id + '.png';
        url.href = canvas.toDataURL();
        url.click();
    });
}

export function formatDownloadPrint(id) {
    const element = document.getElementById(id + 'screen');
    if (element) {
        html2canvas(element).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            printJS({ printable: imgData, type: 'image' });
        });
    }
}

export function formatDate(date) {
    return date ? dayjs.utc(date).format('DD/MM/YYYY') : 'Sin fecha';
}

export function formatDateMonth(date) {
    let dateFormat = date ? dayjs.utc(date).format('MMMM [del] YYYY') : 'Sin fecha';
    dateFormat = dateFormat.charAt(0).toUpperCase() + dateFormat.slice(1)
    return dateFormat;
}

export function formatDateLarge(date) {
    return date ? dayjs.utc(date).format('DD/MM/YYYY hh:mm:ss') : 'Sin fecha';
}

export function formatDateExt(dateInit, dateEnd) {
    return [formatDate(dateInit), formatDate(dateEnd)].join(' - ');
}

export function formatDateText(date) {
    return date ? dayjs.utc(new Date(date)).format('dddd DD [de] MMMM [de] YYYY [a las] h:mm:ss A') : 'Sin fecha';
}

export function formatDateCurator(date) {
    return date ? dayjs.utc(new Date(date)).format('YYYY/MM/DD') : 'Sin fecha';
}

export function formatDateCuratorResumen(init, end) {
    const newInit = dayjs.utc(new Date(init));
    const newEnd = dayjs.utc(new Date(end));
    return dayjs.utc(newEnd.diff(newInit, 'ss')).format('mm:ss:SSS');
}

export function formatText(language, text, text_es, text_en, defaultLabel) {
    if (language === 'es' && text_es && text_es !== '' && text_es !== '0') {
        return text_es;
    } else if (language === 'en' && text_en && text_en !== '' && text_en !== '0') {
        return text_en;
    } else if (text && text !== '' && text !== '0') {
        return text;
    } else {
        return 'Sin ' + defaultLabel + '.';
    }
}

export function formatCurrency(number) {
    return numeral(number).format('$ 0,0');
}

export function formatPercent(number) {
    return numeral(number).format('0.0%');
}

export function formatTLGroups(data, language) {
    const newData = [];
    const dataArray = Object.keys(data || {});
    if (dataArray.length !== 0) {
        dataArray.forEach(item => {
            const category = data[item];
            newData.push({
                title:
                    category === 'R'
                        ? language === 'en' ? 'Recognized' : 'Reconocido'
                        : category === 'G'
                            ? language === 'en' ? 'Registered and Endorsed' : 'Registrado Y Avalado'
                            : language === 'en' ? 'Categorized' : 'Categorizado',
                information: item,
                description: [language === 'en' ? 'Category' : 'Categoría', category].join(' '),
                bgColor: COLORS_GROUP[category],
                icon: category,
                handleClick: {}
            });
        });
    }
    return newData;
}

export function formatTLUsability(data) {
    const namings_usability = {
        init: 'Inicio',
        dependence: 'Dependencia',
        hierarchy: 'Jerarquía',
        resource: 'Recurso',
        subResource: 'Sub Recurso',
    };
    const newData = data.map((item, key) => {
        const duration = formatDateCuratorResumen(item.date, data.length === (key + 1) ? item.date : data[key + 1].date);
        return ({
            title: namings_usability[item.trace.type],
            information: formatDateText(item.date),
            subInformation: ['Duración en [minutos:segundos:mili segundos]', duration],
            description: item.trace.type === 'hierarchy'
                ? [item.trace[item.trace.type].type, ' (', item.trace[item.trace.type].value, ')'].join('')
                : item.trace.type === 'subResource'
                    ? [item.trace[item.trace.type], ' (', item.trace.action, ')'].join('')
                    : item.trace[item.trace.type],
            bgColor: COLORS_USABILITY[item.trace.type],
            icon: <SwipeUpTwoTone color='white' />,
            handleClick: {}
        })
    });
    return newData;
}

export function formatTLCurator(data, field) {
    const newData = [];
    if (data.corrections.length !== 0) {
        newData.push({
            title: 'Fecha de la publicación',
            information: formatDateText(data[field][constants.PUBLICATION_DATE_FIELD]),
            description: 'Fuente: ' + data[field][constants.PUBLICATION_SOURCE_FIELD],
            bgColor: palette.light.primary.main,
            icon: <HistoryEduTwoTone color='white' />,
            handleClick: {}
        });
        sortedArray(data.corrections, 'startDate', 'dsc', (date) => new Date(date)).forEach(item => {
            newData.push({
                title: item.root,
                information: formatDateText(item.startDate),
                description: [
                    'Usuario: ' + item.executor + ' (' + item.provider + ')',
                    'Campo: ' + item.category,
                ].join(' - '),
                bgColor: palette.light[item.category === 'DELETE' ? 'error' : item.state ? 'info' : 'warning'].main,
                icon: <BorderColorTwoTone color='white' />,
                handleClick: {
                    report: item.report,
                    changes: item.changes
                }
            });
        });
        newData.push({
            title: '',
            information: '',
            description: '',
            bgColor: palette.light.primary.main,
            icon: <CloudDoneTwoTone color='white' />,
            handleClick: {}
        });
    }
    return newData;
}

export function formatTFUsability(data) {
    const keysExclude = ['ACU-INI', 'ACU-DEP', 'ACU-HIE', 'ACU-RES'];
    let newData = [];
    function filterObj(arr, parent) {
        arr.forEach(({ children, ...info }) => {
            if (keysExclude.map((key) => (info.name !== key)).reduce((a, b) => a && b)) {
                let newRow = { ...info };
                parent.push(newRow)
                if (children) {
                    let xChilds = [];
                    newRow.children = xChilds;
                    filterObj(children, xChilds);
                }
                if (newRow?.children?.length === 0) {
                    delete newRow.children;
                }
            }
        })
    };
    filterObj(data, newData);
    return newData;
}

export function formatTFHUsability(data) {
    const keysExclude = ['ACU-INI', 'ACU-DEP', 'ACU-HIE', 'ACU-RES'];
    const iconsHierarchy = {
        ip: PersonPinCircleTwoTone,
        date: CalendarMonthTwoTone,
        init: Filter1TwoTone,
        dependence: Filter2TwoTone,
        hierarchyType: Filter3TwoTone,
        hierarchyValue: Filter4TwoTone,
        resource: Filter5TwoTone,
        subResource: Filter6TwoTone,
        action: Filter7TwoTone,
        value: Filter8TwoTone,
    };
    let newData = [];
    let idNumber = 0;
    function filterObj(arr, parent) {
        arr.forEach(({ children, ...info }) => {
            if (keysExclude.map((key) => (info.name !== key)).reduce((a, b) => a && b)) {
                ++idNumber;
                let newRow = {
                    id: idNumber.toString(),
                    label: info.name,
                    value: info?.value ? <Chip label={info.value} color='info' size='small' /> : null,
                    icon: iconsHierarchy[info.type],
                    onClick: null,
                };
                parent.push(newRow)
                if (children) {
                    let xChilds = [];
                    newRow.children = xChilds;
                    filterObj(children, xChilds);
                }
                if (newRow?.children?.length === 0) {
                    delete newRow.children;
                }
            }
        })
    };
    filterObj(data, newData);
    return newData;
}

export function formatCapitalize(string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1).toLowerCase();
}

export function formatMGroups(data, t) {
    let newData = [];
    let counts = {};
    data.forEach(item => {
        counts[item[constants.AUTHOR_POSITION_FIELD]] = counts[item[constants.AUTHOR_POSITION_FIELD]]
            ? counts[item[constants.AUTHOR_POSITION_FIELD]] + 1
            : 1;
    });
    Object.keys(counts).forEach((item, key) => {
        newData.push({
            _id: key,
            type: t('components.graphics.' + item),
            value: counts[item],
        });
    });
    return newData;
}

export function formatOptionsDependences(params, dependence, field) {
    let institution = [];
    let campus = [];
    let faculty = [];
    let uab = [];
    let author = [];

    dependence
        ?.filter(item => !params.institucion || field === 'institution' || item[constants.DEPENDENCE_INSTITUTION_FIELD] === params.institucion)
        ?.forEach(item => {
            item[constants.DEPENDENCE_CHILDREN_FIELD]
                ?.filter(itemCampus => !params.sede || field === 'campus' || itemCampus[constants.DEPENDENCE_CAMPUS_FIELD] === params.sede)
                ?.forEach(itemCampus => {
                    itemCampus[constants.DEPENDENCE_PARENT_FIELD] = item[constants.DEPENDENCE_INSTITUTION_FIELD];
                    itemCampus[constants.DEPENDENCE_CHILDREN_FIELD]
                        ?.filter(itemFaculty => !params.facultad || field === 'faculty' || itemFaculty[constants.DEPENDENCE_FACULTY_FIELD] === params.facultad)
                        ?.forEach(itemFaculty => {
                            itemFaculty[constants.DEPENDENCE_PARENT_FIELD] = itemCampus[constants.DEPENDENCE_CAMPUS_FIELD];
                            itemFaculty[constants.DEPENDENCE_CHILDREN_FIELD]
                                ?.filter(itemUab => !params.departamento || field === 'uab' || itemUab[constants.DEPENDENCE_UAB_FIELD] === params.departamento)
                                ?.forEach(itemUab => {
                                    itemUab[constants.DEPENDENCE_PARENT_FIELD] = itemFaculty[constants.DEPENDENCE_FACULTY_FIELD];
                                    itemUab[constants.DEPENDENCE_CHILDREN_FIELD]
                                        ?.filter(itemAuthor => !params.autor || field === 'author' || itemAuthor[constants.DEPENDENCE_AUTHOR_FIELD] === params.autor)
                                        ?.forEach(itemAuthor => {
                                            itemAuthor[constants.DEPENDENCE_PARENT_FIELD] = itemUab[constants.DEPENDENCE_UAB_FIELD];
                                            author.push(itemAuthor)
                                        });
                                    uab.push(itemUab);
                                });
                            faculty.push(itemFaculty);
                        });
                    campus.push(itemCampus);
                });
            institution.push(item);
        });

    let options = {
        institution: institution.map(item => ({
            label: formatDependencesName(item[constants.DEPENDENCE_INSTITUTION_FIELD]),
            value: item[constants.DEPENDENCE_INSTITUTION_FIELD],
            group: item[constants.DEPENDENCE_PARENT_FIELD]
        })),
        campus: campus.map(item => ({
            label: formatDependencesName(item[constants.DEPENDENCE_CAMPUS_FIELD]),
            value: item[constants.DEPENDENCE_CAMPUS_FIELD],
            group: item[constants.DEPENDENCE_PARENT_FIELD]
        })),
        faculty: faculty.map(item => ({
            label: formatDependencesName(item[constants.DEPENDENCE_FACULTY_FIELD]),
            value: item[constants.DEPENDENCE_FACULTY_FIELD],
            group: item[constants.DEPENDENCE_PARENT_FIELD]
        })),
        uab: uab.map(item => ({
            label: formatDependencesName(item[constants.DEPENDENCE_UAB_FIELD]),
            value: item[constants.DEPENDENCE_UAB_FIELD],
            group: item[constants.DEPENDENCE_PARENT_FIELD]
        })),
        author: author.map(item => ({
            label: item[constants.DEPENDENCE_AUTHOR_FIELD],
            value: item[constants.DEPENDENCE_AUTHOR_FIELD],
            group: item[constants.DEPENDENCE_PARENT_FIELD]
        })),
        identifier: author.map(item => ({
            label: [item[constants.DEPENDENCE_AUTHOR_FIELD], item[constants.DEPENDENCE_AUTHOR_INFO_FIELD][constants.AUTHOR_ID_FIELD]].join(' - '),
            value: item[constants.DEPENDENCE_AUTHOR_INFO_FIELD][constants.AUTHOR_ID_FIELD],
            group: item[constants.DEPENDENCE_PARENT_FIELD]
        })),
        email: author.map(item => ({
            label: [item[constants.DEPENDENCE_AUTHOR_FIELD], item[constants.DEPENDENCE_AUTHOR_INFO_FIELD][constants.AUTHOR_EMAIL_FIELD]].join(' - '),
            value: item[constants.DEPENDENCE_AUTHOR_INFO_FIELD][constants.AUTHOR_EMAIL_FIELD],
            group: item[constants.DEPENDENCE_PARENT_FIELD]
        })),
        gender: [
            { value: constants.METRIC_FEMALE_FIELD, label: 'Femenino' },
            { value: constants.METRIC_MALE_FIELD, label: 'Masculino' }
        ],
        bonding: [
            { value: 'Activo', label: 'Activo' },
            { value: 'Retirado', label: 'Retirado' },
            { value: 'No Reportado', label: 'No Reportado' }
        ],
    };
    return options;
}

export function formatDependencesInv(dependence) {
    let formatDependencesInv = sortedArray(dependence, constants.DEPENDENCE_INSTITUTION_FIELD, 'asc', (str) => str.toString()).map((institution, indIns) => {
        return {
            // id: indIns.toString(),
            id: institution[constants.DEPENDENCE_INSTITUTION_FIELD],
            name: institution[constants.DEPENDENCE_INSTITUTION_FIELD],
            info: {
                level: 'DEPENDENCE_INSTITUTION_FIELD',
                route: {
                    institution: institution[constants.DEPENDENCE_INSTITUTION_FIELD],
                }
            },
            children: sortedArray(institution[constants.DEPENDENCE_CHILDREN_FIELD], constants.DEPENDENCE_CAMPUS_FIELD, 'asc', (str) => str.toString()).map((campus, indCam) => {
                return {
                    id: campus[constants.DEPENDENCE_CAMPUS_FIELD],
                    name: campus[constants.DEPENDENCE_CAMPUS_FIELD],
                    info: {
                        level: 'DEPENDENCE_CAMPUS_FIELD',
                        route: {
                            institution: institution[constants.DEPENDENCE_INSTITUTION_FIELD],
                            campus: campus[constants.DEPENDENCE_CAMPUS_FIELD],
                        }
                    },
                    children: sortedArray(campus[constants.DEPENDENCE_CHILDREN_FIELD], constants.DEPENDENCE_FACULTY_FIELD, 'asc', (str) => str.toString()).map((faculty, indFac) => {
                        return {
                            id: faculty[constants.DEPENDENCE_FACULTY_FIELD],
                            name: faculty[constants.DEPENDENCE_FACULTY_FIELD],
                            info: {
                                level: 'DEPENDENCE_FACULTY_FIELD',
                                route: {
                                    institution: institution[constants.DEPENDENCE_INSTITUTION_FIELD],
                                    campus: campus[constants.DEPENDENCE_CAMPUS_FIELD],
                                    faculty: faculty[constants.DEPENDENCE_FACULTY_FIELD],
                                }
                            },
                            children: sortedArray(faculty[constants.DEPENDENCE_CHILDREN_FIELD], constants.DEPENDENCE_UAB_FIELD, 'asc', (str) => str.toString()).map((uab, indUab) => {
                                return {
                                    id: uab[constants.DEPENDENCE_UAB_FIELD],
                                    name: uab[constants.DEPENDENCE_UAB_FIELD],
                                    info: {
                                        level: 'DEPENDENCE_UAB_FIELD',
                                        route: {
                                            institution: institution[constants.DEPENDENCE_INSTITUTION_FIELD],
                                            campus: campus[constants.DEPENDENCE_CAMPUS_FIELD],
                                            faculty: faculty[constants.DEPENDENCE_FACULTY_FIELD],
                                            uab: uab[constants.DEPENDENCE_UAB_FIELD],
                                        }
                                    },
                                    children: sortedArray(uab[constants.DEPENDENCE_CHILDREN_FIELD], constants.DEPENDENCE_AUTHOR_FIELD, 'asc', (str) => str.toString()).map((author, indAut) => {
                                        return {
                                            id: author[constants.DEPENDENCE_AUTHOR_FIELD] + '_' + author[constants.DEPENDENCE_AUTHOR_INFO_FIELD][constants.AUTHOR_ID_FIELD],
                                            name: author[constants.DEPENDENCE_AUTHOR_FIELD],
                                            info: {
                                                level: 'DEPENDENCE_AUTHOR_FIELD',
                                                route: {
                                                    institution: institution[constants.DEPENDENCE_INSTITUTION_FIELD],
                                                    campus: campus[constants.DEPENDENCE_CAMPUS_FIELD],
                                                    faculty: faculty[constants.DEPENDENCE_FACULTY_FIELD],
                                                    uab: uab[constants.DEPENDENCE_UAB_FIELD],
                                                    author: author[constants.DEPENDENCE_AUTHOR_FIELD],
                                                },
                                                ...author[constants.DEPENDENCE_AUTHOR_INFO_FIELD]
                                            },
                                            children: []
                                        };
                                    })
                                };
                            })
                        };
                    })
                };
            })
        };
    });
    return formatDependencesInv;
}

export function formatDependencesExt(dependence) {
    let formatDependencesExt = sortedArray(dependence, 'name', 'asc', (str) => str.toString()).map((faculty, indFac) => {
        return {
            id: indFac.toString(),
            name: faculty.name,
            info: {
                name: faculty.name,
                level: 'DEPENDENCE_FACULTY_FIELD',
                route: {
                    faculty: faculty.name,
                }
            },
            children: sortedArray(faculty.children, 'name', 'asc', (str) => str.toString()).map((uab, indUab) => {
                return {
                    id: indFac.toString() + indUab.toString(),
                    name: uab.name,
                    info: {
                        name: uab.name,
                        level: 'DEPENDENCE_UAB_FIELD',
                        route: {
                            faculty: faculty.name,
                            uab: uab.name,
                        }
                    },
                    children: []
                };
            })
        };
    });
    return formatDependencesExt;
}

export function formatDependencesName(dependence) {
    return dependence?.replace(/ - \d+/g, '');
}

export function formatDependencesAuthor(data) {
    let newData = [];
    data.forEach((institution) => {
        institution[constants.DEPENDENCE_CHILDREN_FIELD].forEach(campus => {
            campus[constants.DEPENDENCE_CHILDREN_FIELD].forEach(faculty => {
                faculty[constants.DEPENDENCE_CHILDREN_FIELD].forEach(uab => {
                    uab[constants.DEPENDENCE_CHILDREN_FIELD].forEach(author => {
                        newData.push({
                            group: uab[constants.DEPENDENCE_UAB_FIELD],
                            value: author[constants.AUTHOR_ID_FIELD],
                            label: author[constants.AUTHOR_NAME_FIELD],
                            default: author,
                        });
                    });
                });
            });
        });
    });
    return newData;
}

export function formatFeedback(data, metrics) {
    let newData = metrics;
    newData.source = data;
    data.forEach(item => {
        Object.keys(item.form).forEach(subItem => {
            newData[subItem].label = item.form[subItem].label;
            let value = item.form[subItem].value;
            if (value !== '') {
                if (value in newData[subItem].options) {
                    newData[subItem].options[value] = newData[subItem].options[value] + 1;
                } else {
                    newData[subItem].options[value] = 1;
                }
            }
        });
    });
    Object.keys(newData).forEach(item => {
        if (item !== 'source') {
            Object.keys(newData[item].options).forEach((subItem) => {
                newData[item].processed.push({
                    _id: subItem,
                    type: subItem,
                    value: newData[item].options[subItem]
                });
            });
        }
    });
    return newData;
}

export function formatFeedbackDownload(values) {
    const headers = [
        { header: 'Fecha', key: constants.FEEDBACK_DATE_FIELD },
        { header: 'Nombre', key: constants.FEEDBACK_USER_NO_NAME_FIELD },
        { header: 'Correo', key: constants.FEEDBACK_USER_NO_EMAIL_FIELD },
        { header: 'Relación', key: constants.FEEDBACK_USER_NO_POSITION_FIELD },
        { header: 'Sede', key: 'campus' }
    ];
    const data = values.map(item => {
        const form = {};
        Object.keys(item.form).forEach(subItem => {
            if (!Boolean(headers.find(headerItem => headerItem.key === subItem))) {
                headers.push({
                    header: item.form[subItem].label,
                    key: subItem
                });
            }
            form[subItem] = item.form[subItem].value;
        })
        return ({
            date: formatDate(item[constants.FEEDBACK_DATE_FIELD]),
            name: item[constants.FEEDBACK_USER_FIELD]
                ? item[constants.FEEDBACK_USER_INFO_FIELD][constants.AUTHOR_NAME_FIELD]
                : item[constants.FEEDBACK_USER_NO_FIELD][constants.FEEDBACK_USER_NO_NAME_FIELD],
            email: item[constants.FEEDBACK_USER_FIELD]
                ? item[constants.FEEDBACK_USER_INFO_FIELD][constants.AUTHOR_EMAIL_FIELD]
                : item[constants.FEEDBACK_USER_NO_FIELD][constants.FEEDBACK_USER_NO_EMAIL_FIELD],
            relation: item[constants.FEEDBACK_USER_FIELD]
                ? item[constants.FEEDBACK_USER_INFO_FIELD][constants.AUTHOR_POSITION_FIELD]
                : item[constants.FEEDBACK_USER_NO_FIELD][constants.FEEDBACK_USER_NO_POSITION_FIELD],
            campus: item[constants.FEEDBACK_USER_FIELD]
                ? item[constants.FEEDBACK_USER_INFO_FIELD].campus
                : item[constants.FEEDBACK_USER_NO_FIELD].campus,
            ...form
        });
    });
    return {
        headers: headers,
        data: data
    };
}

export function formatDisplay(type, variant) {
    const conditions = {
        'add': {
            'user': 'inline-block',
            'password': 'inline-block',
            'dependence': 'inline-block',
            'names': 'inline-block',
            'surnames': 'inline-block',
            'title': 'inline-block',
            'date': 'inline-block',
            'description': 'inline-block',
            'image': 'inline-block'
        },
        'edit': {
            'user': 'inline-block',
            'password': 'none',
            'dependence': 'inline-block',
            'names': 'inline-block',
            'surnames': 'inline-block',
            'title': 'inline-block',
            'date': 'inline-block',
            'description': 'inline-block',
            'image': 'none'
        },
        'edit-pass': {
            'user': 'none',
            'password': 'inline-block',
            'dependence': 'none',
            'names': 'none',
            'surnames': 'none'
        },
        'delete': {
            'user': 'none',
            'password': 'none',
            'dependence': 'none',
            'names': 'none',
            'surnames': 'none',
            'title': 'none',
            'date': 'none',
            'description': 'none',
            'image': 'none'
        },
        'delete-none': {
            'user': 'none',
            'password': 'none',
            'dependence': 'none',
            'names': 'none',
            'surnames': 'none'
        }
    };
    return conditions?.[type]?.[variant] || 'none';
}

export function formatLowerCase(string) {
    return string?.toLowerCase();
}

export function formatUpperCase(string) {
    return string?.toUpperCase();
}

export function formatAutocomplete(data, prop1, prop2) {
    var newData = [];
    if (data !== undefined) {
        if (Array.isArray(data)) {
            data.forEach(item => {
                if (item) {
                    const value = prop1 ? (prop2 ? item[prop1][prop2] : item[prop1]) : item;
                    newData.push({ value: value, label: value, default: item });
                }
            });
        } else {
            Object.keys(data).forEach(item => {
                if (item) {
                    const value = prop1
                        ? prop1 === 'topics'
                            ? item
                            : (prop2 ? item[prop1][prop2] : item[prop1])
                        : item;
                    const label = prop1
                        ? prop1 === 'topics'
                            ? `${item} (${data[item]})`
                            : (prop2 ? item[prop1][prop2] : item[prop1])
                        : item;
                    newData.push({ value: value, label: label, default: item });
                }
            });
        }
    }
    return newData;
}

export function orderCategories(a, b) {
    const categorySort = ['A1', 'A', 'B', 'C', 'D', 'G', 'R', 'Sin Categoría'];
    const categoryA = categorySort.indexOf(a.axis);
    const categoryB = categorySort.indexOf(b.axis);
    return categoryA - categoryB;
}

export function getParams(dependence, value) {
    let getParams = {
        institution: 'Universidad Nacional de Colombia'
    };
    dependence.forEach(institution => {
        if (institution[constants.DEPENDENCE_INSTITUTION_FIELD] === value) {
            getParams = {
                institution: institution[constants.DEPENDENCE_INSTITUTION_FIELD]
            };
        } else {
            institution[constants.DEPENDENCE_CHILDREN_FIELD].forEach(campus => {
                if (campus[constants.DEPENDENCE_CAMPUS_FIELD] === value) {
                    getParams = {
                        institution: institution[constants.DEPENDENCE_INSTITUTION_FIELD],
                        campus: campus[constants.DEPENDENCE_CAMPUS_FIELD]
                    };
                } else {
                    campus[constants.DEPENDENCE_CHILDREN_FIELD].forEach(faculty => {
                        if (faculty[constants.DEPENDENCE_FACULTY_FIELD] === value) {
                            getParams = {
                                institution: institution[constants.DEPENDENCE_INSTITUTION_FIELD],
                                campus: campus[constants.DEPENDENCE_CAMPUS_FIELD],
                                faculty: faculty[constants.DEPENDENCE_FACULTY_FIELD]
                            };
                        } else {
                            faculty[constants.DEPENDENCE_CHILDREN_FIELD].forEach(uab => {
                                if (uab[constants.DEPENDENCE_UAB_FIELD] === value) {
                                    getParams = {
                                        institution: institution[constants.DEPENDENCE_INSTITUTION_FIELD],
                                        campus: campus[constants.DEPENDENCE_CAMPUS_FIELD],
                                        faculty: faculty[constants.DEPENDENCE_FACULTY_FIELD],
                                        uab: uab[constants.DEPENDENCE_UAB_FIELD]
                                    };
                                } else {
                                    uab[constants.DEPENDENCE_CHILDREN_FIELD].forEach(author => {
                                        if (author[constants.DEPENDENCE_AUTHOR_FIELD] === value) {
                                            getParams = {
                                                institution: institution[constants.DEPENDENCE_INSTITUTION_FIELD],
                                                campus: campus[constants.DEPENDENCE_CAMPUS_FIELD],
                                                faculty: faculty[constants.DEPENDENCE_FACULTY_FIELD],
                                                uab: uab[constants.DEPENDENCE_UAB_FIELD],
                                                author: author[constants.DEPENDENCE_AUTHOR_FIELD]
                                            };
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            });
        }
    });

    return getParamsRoute(getParams);
}

export function getParamsRoute(params) {
    return Object.entries(params)
        .filter(([key, value]) => value !== undefined && value !== null && value !== '')
        .map(([key, value]) => `/${key}/${encodeURIComponent(value)}`)
        .join('');
}

export function getParamsRouteString(string) {
    return encodeURIComponent(string);
}

export function getParamsRequest(params) {
    var paramsRequest = {};
    var paramsRename = {
        institution: 'institucion',
        campus: 'sede',
        faculty: 'facultad',
        uab: 'uab',
        author: 'autor'
    };
    Object.keys(params).forEach(item => {
        paramsRequest[paramsRename[item]] = params[item];
    });
    return paramsRequest;
}

export function getParamsFilter(params, author) {
    var paramsFilter = {};
    var paramsKeys = {
        [constants.DEPENDENCE_INSTITUTION_FIELD]: 'institution',
        [constants.DEPENDENCE_CAMPUS_FIELD]: 'campus',
        [constants.DEPENDENCE_FACULTY_FIELD]: 'faculty',
        [constants.DEPENDENCE_UAB_FIELD]: 'uab',
        [constants.AUTHOR_NAME_FIELD]: 'author'
    };
    if (!author) delete paramsKeys[constants.AUTHOR_NAME_FIELD];
    Object.entries(paramsKeys).forEach(([key, value]) => {
        paramsFilter[value] = params[key];
    });
    return paramsFilter;
}

export function getPositionGroup(author, t) {
    let groups = author[constants.AUTHOR_GROUPS_FIELD].map(group => {
        group[constants.AUTHOR_POSITION_FIELD] = t('components.graphics.Líder');
        group[constants.GROUP_MEMBERS_FIELD].forEach(member => {
            if (member[constants.AUTHOR_ID_FIELD] === author[constants.AUTHOR_ID_FIELD]) {
                group[constants.AUTHOR_POSITION_FIELD] = t('components.graphics.' + member[constants.AUTHOR_POSITION_FIELD]);
            }
        });
        return group;
    });
    return groups;
}

export function getOptionsAuthor(data, dependence) {
    let authorIds = new Set();
    let authorOption = [];
    function traverseAndFilter(node) {
        if (node[constants.DEPENDENCE_AUTHOR_FIELD]) {
            const _id = node[constants.DEPENDENCE_AUTHOR_INFO_FIELD][constants.AUTHOR_ID_FIELD];
            const name = node[constants.DEPENDENCE_AUTHOR_FIELD];
            if (data.includes(_id) && !authorIds.has(_id)) {
                authorOption.push({ label: name, value: _id });
                authorIds.add(_id);
                return node;
            }
            return null;
        } else if (node[constants.DEPENDENCE_CHILDREN_FIELD] && node[constants.DEPENDENCE_CHILDREN_FIELD].length > 0) {
            const filteredChildren = node[constants.DEPENDENCE_CHILDREN_FIELD].map(child => traverseAndFilter(child)).filter(Boolean);
            return filteredChildren.length > 0 ? { ...node, [constants.DEPENDENCE_CHILDREN_FIELD]: filteredChildren } : null;
        }
    }
    const authorOptions = dependence?.map(item => traverseAndFilter(item)).filter(Boolean);
    return {
        authorOptions: formatDependencesInv(authorOptions),
        authorOption: Array.from(authorOption)
    };
}

export function getOptionsAuthorValue(data, type) {
    let authorOption = [];
    if (type === 'tree') {
        function traverse(node) {
            if (node.info.level === 'DEPENDENCE_AUTHOR_FIELD') {
                authorOption.push({ label: node.name, value: node.info._id });
            } else {
                node.children.forEach(child => traverse(child));
            }
        };
        traverse(data);
    } else {
        authorOption = data;
    }
    return authorOption;
}

export function getStepResource(params, resources, navigate, location) {
    const resource = params.resource;
    const defaultResources = resources.map(item => item.step);
    if (!defaultResources.includes(resource)) {
        let newParams = {};
        if ('query' in params) {
            if (location.pathname.includes('extension')) {
                newParams.search = 'extension';
            } else {
                newParams.search = 'research';
            }
            newParams.query = params.query;
        } else {
            newParams = params
        }
        newParams.resource = defaultResources[0];
        const route = getParamsRoute(newParams);
        navigate(route, { state: { key: 'hierarchy' } });
    }
}

export function getDifferencesForm(dataDefault, dataNew) {
    const data = {};
    if (dataDefault[constants.PUBLICATION_TITLE_FIELD] !== dataNew[constants.PUBLICATION_TITLE_FIELD]) {
        data[constants.PUBLICATION_TITLE_FIELD] = dataNew[constants.PUBLICATION_TITLE_FIELD];
    }
    if (dataDefault[constants.PUBLICATION_SPANISH_TITLE_FIELD] !== dataNew[constants.PUBLICATION_SPANISH_TITLE_FIELD]) {
        data[constants.PUBLICATION_SPANISH_TITLE_FIELD] = dataNew[constants.PUBLICATION_SPANISH_TITLE_FIELD];
    }
    if (dataDefault[constants.PUBLICATION_ENGLISH_TITLE_FIELD] !== dataNew[constants.PUBLICATION_ENGLISH_TITLE_FIELD]) {
        data[constants.PUBLICATION_ENGLISH_TITLE_FIELD] = dataNew[constants.PUBLICATION_ENGLISH_TITLE_FIELD];
    }
    if (dataDefault[constants.PUBLICATION_ABSTRACT_FIELD] !== dataNew[constants.PUBLICATION_ABSTRACT_FIELD]) {
        data[constants.PUBLICATION_ABSTRACT_FIELD] = dataNew[constants.PUBLICATION_ABSTRACT_FIELD];
    }
    if (dataDefault[constants.PUBLICATION_SPANISH_ABSTRACT_FIELD] !== dataNew[constants.PUBLICATION_SPANISH_ABSTRACT_FIELD]) {
        data[constants.PUBLICATION_SPANISH_ABSTRACT_FIELD] = dataNew[constants.PUBLICATION_SPANISH_ABSTRACT_FIELD];
    }
    if (dataDefault[constants.PUBLICATION_ENGLISH_ABSTRACT_FIELD] !== dataNew[constants.PUBLICATION_ENGLISH_ABSTRACT_FIELD]) {
        data[constants.PUBLICATION_ENGLISH_ABSTRACT_FIELD] = dataNew[constants.PUBLICATION_ENGLISH_ABSTRACT_FIELD];
    }
    if (
        dataDefault[constants.PUBLICATION_PUBNAME_FIELD][constants.PUBLICATION_ORIGINAL_SUBFIELD] !==
        dataNew[constants.PUBLICATION_PUBNAME_ORIGINAL_FIELD] ||
        dataDefault[constants.PUBLICATION_PUBNAME_FIELD][constants.PUBLICATION_NORMALIZED_SUBFIELD] !==
        dataNew[constants.PUBLICATION_PUBNAME_NORMALIZED_FIELD] ||
        dataDefault[constants.PUBLICATION_PUBNAME_FIELD][constants.PUBLICATION_SCORE_SUBFIELD] !==
        dataNew[constants.PUBLICATION_PUBNAME_SCORE_FIELD]
    ) {
        data[constants.PUBLICATION_PUBNAME_FIELD] = {
            [constants.PUBLICATION_ORIGINAL_SUBFIELD]: dataNew[constants.PUBLICATION_PUBNAME_ORIGINAL_FIELD],
            [constants.PUBLICATION_NORMALIZED_SUBFIELD]: dataNew[constants.PUBLICATION_PUBNAME_NORMALIZED_FIELD],
            [constants.PUBLICATION_SCORE_SUBFIELD]: dataNew[constants.PUBLICATION_PUBNAME_SCORE_FIELD]
        };
    }
    if (
        getValidateDifferencesForm(dataDefault[constants.PUBLICATION_AFFILIATION_FIELD][constants.PUBLICATION_AFFIL_COUNTRY_FIELD], dataNew[constants.PUBLICATION_AFFIL_COUNTRY_FIELD]) ||
        getValidateDifferencesForm(dataDefault[constants.PUBLICATION_AFFILIATION_FIELD][constants.PUBLICATION_AFFIL_CITY_FIELD], dataNew[constants.PUBLICATION_AFFIL_CITY_FIELD]) ||
        getValidateDifferencesFormAffiliation(dataDefault[constants.PUBLICATION_AFFILIATION_FIELD][constants.PUBLICATION_AFFIL_NAME_FIELD], dataNew[constants.PUBLICATION_AFFIL_NAME_FIELD])
    ) {
        data[constants.PUBLICATION_AFFILIATION_FIELD] = {
            [constants.PUBLICATION_AFFIL_COUNTRY_FIELD]: dataNew[constants.PUBLICATION_AFFIL_COUNTRY_FIELD].map(item => (item.isNew ? item.value : item.default)),
            [constants.PUBLICATION_AFFIL_CITY_FIELD]: dataNew[constants.PUBLICATION_AFFIL_CITY_FIELD].map(item => (item.isNew ? item.value : item.default)),
            [constants.PUBLICATION_AFFIL_NAME_FIELD]: dataNew[constants.PUBLICATION_AFFIL_NAME_FIELD].map(item =>
                item.isNew
                    ? [
                        {
                            [constants.PUBLICATION_ORIGINAL_SUBFIELD]: item.value,
                            [constants.PUBLICATION_NORMALIZED_SUBFIELD]: item.value,
                        },
                    ]
                    : item.default
            ),
        };
    }
    if (getValidateDifferencesFormAuthor(dataDefault[constants.PUBLICATION_AUTHORS_FIELD], dataNew[constants.PUBLICATION_AUTHORS_FIELD])) {
        data[constants.PUBLICATION_AUTHORS_FIELD] = dataNew[constants.PUBLICATION_AUTHORS_FIELD].map(item =>
            item.isNew
                ? {
                    name: {
                        ORIGINAL: item.value,
                        NORMALIZED: item.value,
                    },
                    'is-in': item.default ? true : false,
                    _id: item.default ? item.default._id : item.value,
                }
                : item.default
        );
    }
    if (dataDefault[constants.PUBLICATION_DOI_FIELD] !== dataNew[constants.PUBLICATION_DOI_FIELD]) {
        data[constants.PUBLICATION_DOI_FIELD] = dataNew[constants.PUBLICATION_DOI_FIELD];
    }
    let newDates = formatDateCurator(dataNew[constants.PUBLICATION_DATE_FIELD]);
    if (dataDefault[constants.PUBLICATION_DATE_FIELD] !== newDates) {
        data[constants.PUBLICATION_DATE_FIELD] = newDates;
    }
    if (dataDefault[constants.PUBLICATION_LANGUAGE_FIELD] !== dataNew[constants.PUBLICATION_LANGUAGE_FIELD]) {
        data[constants.PUBLICATION_LANGUAGE_FIELD] = dataNew[constants.PUBLICATION_LANGUAGE_FIELD];
    }
    if (dataDefault[constants.PUBLICATION_PUBLISHER_FIELD] !== dataNew[constants.PUBLICATION_PUBLISHER_FIELD]) {
        data[constants.PUBLICATION_PUBLISHER_FIELD] = dataNew[constants.PUBLICATION_PUBLISHER_FIELD];
    }
    if (dataDefault[constants.PUBLICATION_SOURCE_FIELD] !== dataNew[constants.PUBLICATION_SOURCE_FIELD]) {
        data[constants.PUBLICATION_SOURCE_FIELD] = dataNew[constants.PUBLICATION_SOURCE_FIELD];
    }
    let newPageRange = [dataNew[constants.PUBLICATION_RANGE_START_FIELD], dataNew[constants.PUBLICATION_RANGE_END_FIELD]].join('-');
    if (dataDefault[constants.PUBLICATION_RANGE_FIELD] !== newPageRange) {
        data[constants.PUBLICATION_RANGE_FIELD] = newPageRange;
    }
    if (dataDefault[constants.PUBLICATION_SOURCE_TYPE_FIELD] !== dataNew[constants.PUBLICATION_SOURCE_TYPE_FIELD]) {
        data[constants.PUBLICATION_SOURCE_TYPE_FIELD] =
            dataNew[constants.PUBLICATION_SOURCE_TYPE_FIELD];
    }
    if (dataDefault[constants.PUBLICATION_VOLUME_FIELD] !== dataNew[constants.PUBLICATION_VOLUME_FIELD]) {
        data[constants.PUBLICATION_VOLUME_FIELD] = dataNew[constants.PUBLICATION_VOLUME_FIELD];
    }
    if (getValidateDifferencesForm(dataDefault[constants.PUBLICATION_ISBN_FIELD], dataNew[constants.PUBLICATION_ISBN_FIELD])) {
        data[constants.PUBLICATION_ISBN_FIELD] = dataNew[constants.PUBLICATION_ISBN_FIELD].map(item => item.value);
    }
    if (getValidateDifferencesForm(dataDefault[constants.PUBLICATION_ISSN_FIELD], dataNew[constants.PUBLICATION_ISSN_FIELD])) {
        data[constants.PUBLICATION_ISSN_FIELD] = dataNew[constants.PUBLICATION_ISSN_FIELD].map(item => item.value);
    }
    if (dataDefault[constants.PUBLICATION_CITATION_FIELD] !== dataNew[constants.PUBLICATION_CITATION_FIELD]) {
        data[constants.PUBLICATION_CITATION_FIELD] = dataNew[constants.PUBLICATION_CITATION_FIELD];
    }
    if (getValidateDifferencesForm(dataDefault[constants.PUBLICATION_URL_FIELD], dataNew[constants.PUBLICATION_URL_FIELD])) {
        data[constants.PUBLICATION_URL_FIELD] = dataNew[constants.PUBLICATION_URL_FIELD];
    }
    return { validate: Object.keys(data).length, update: data };
}

export function getValidateDifferencesForm(dataDefault, dataNew) {
    if (dataDefault) {
        if (dataDefault.length !== dataNew.length) {
            return true;
        } else {
            let ordDataDefault = JSON.stringify(dataDefault.sort());
            let ordDataNew = JSON.stringify(dataNew.map(item => item.value).sort());
            if (ordDataDefault !== ordDataNew) {
                return true;
            } else {
                return false;
            }
        }
    } else {
        return true;
    }
}

export function getValidateDifferencesFormAuthor(dataDefault, dataNew) {
    if (dataDefault) {
        if (dataDefault.length !== dataNew.length) {
            return true;
        } else {
            let ordDataDefault = JSON.stringify(
                dataDefault.map(item => item[constants.AUTHOR_NAME_FIELD][constants.PUBLICATION_NORMALIZED_SUBFIELD]).sort()
            );
            let ordDataNew = JSON.stringify(dataNew.map(item => item.value).sort());
            if (ordDataDefault !== ordDataNew) {
                return true;
            } else {
                return false;
            }
        }
    } else {
        return true;
    }
}

export function getValidateDifferencesFormAffiliation(dataDefault, dataNew) {
    if (dataDefault) {
        if (dataDefault.length !== dataNew.length) {
            return true;
        } else {
            let ordDataDefault = JSON.stringify(
                dataDefault.map(item => item[constants.PUBLICATION_NORMALIZED_SUBFIELD]).sort()
            );
            let ordDataNew = JSON.stringify(dataNew.map(item => item.value).sort());
            if (ordDataDefault !== ordDataNew) {
                return true;
            } else {
                return false;
            }
        }
    } else {
        return true;
    }
}

export function sortedArray(data, field, type, transform = (x) => x) {
    let sortedArray = [];
    if (data) {
        sortedArray = data.slice().sort((a, b) => {
            const valueA = transform(a[field]);
            const valueB = transform(b[field]);
            if (typeof valueA === 'string' && typeof valueB === 'string') {
                return type === 'asc'
                    ? valueA.localeCompare(valueB)
                    : valueB.localeCompare(valueA);
            } else {
                return type === 'asc'
                    ? valueA - valueB
                    : valueB - valueA;
            }
        });
    }
    return sortedArray;
}

export function sortedArrayMultiple(data, item1, item2) {
    let sortedArray = [];
    if (data) {
        sortedArray = data.slice().sort(function compare(a, b) {
            if (a[item1] > b[item1]) return 1;
            if (a[item1] < b[item1]) return -1;
            else {
                if (a[item2] > b[item2]) return 1;
                else if (a[item2] < b[item2]) return -1;
                return 0;
            }
        });
    }
    return sortedArray;
}

export function sortedArrayValue(data, item1, value) {
    let sortedArray = data.sort((a, b) =>
        (a[item1] === value) > b[item1] ? 1 : a[item1] > (b[item1] === value) ? -1 : 1
    );
    return sortedArray;
}

export function pxToRem(value) {
    return `${value / 16}rem`;
}

export function responsiveFontSizes({ sm, md, lg }) {
    return {
        '@media (min-width:600px)': {
            fontSize: pxToRem(sm),
        },
        '@media (min-width:900px)': {
            fontSize: pxToRem(md),
        },
        '@media (min-width:1200px)': {
            fontSize: pxToRem(lg),
        },
    };
}

export function translateList(list, translations, keys) {
    let translatedList = [];
    list?.forEach(item => {
        let translatedItem = { ...item };
        keys.forEach(key => {
            const translatedKey = translations[item[key]] || item[key];
            translatedItem[key] = translatedKey;
        });
        translatedList.push(translatedItem);
    });
    return translatedList;
}

export function translateDependences(list, keys) {
    let translatedList = [];
    list.forEach(item => {
        let translatedItem = { ...item };
        keys.forEach(key => {
            const translatedKey = formatDependencesName(item[key]);
            translatedItem[key] = translatedKey;
        });
        translatedList.push(translatedItem);
    });
    return translatedList;
}

export function arraysAreEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) return false;
    const set1 = new Set(arr1);
    const set2 = new Set(arr2);
    if (set1.size !== set2.size) return false;
    for (let item of set1) {
        if (!set2.has(item)) return false;
    }
    return true;
}

export function validateEmptyData(data) {
    if (!data || Object.keys(data).length === 0) {
        return true;
    }
    if ('nodes' in data && data.nodes.length === 0) {
        return true;
    }
    if ('mdsDat' in data && data.mdsDat.x.length === 0) {
        return true;
    }
    return false;
}

export function getCleanText(string) {
    return string.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function hexToRgba(hex, alpha) {
    if (hex) {
        const bigint = parseInt(hex.slice(1), 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }
}