import React from 'react';
import PropTypes from 'prop-types';
import { LDAvis } from 'react-ldavis';
import { Box } from '@mui/material';
import 'react-ldavis/dist/index.css';

function customLDAvis(props) {
    const { infoChart } = props;
    return (
        <Box id='overflow-container'>
            <LDAvis
                data={infoChart.data || {}}
                modifyHistory={false}
                style={{ textAlign: 'center' }}
            />
        </Box>
    );
}
customLDAvis.propTypes = {
    infoChart: PropTypes.shape({
        data: PropTypes.object
    })
};
export default customLDAvis;