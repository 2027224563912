import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Box, Grid, Avatar } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserShield, faUser } from '@fortawesome/free-solid-svg-icons';
import { faUser as faUserRegular } from '@fortawesome/free-regular-svg-icons';
import CardGraphics from '../../../../components/card/CardGraphics';
import PieDonut from '../../../../components/graphics/PieDonut';
import ColumnGroup from '../../../../components/graphics/ColumnGroup';
import ColumnStacked from '../../../../components/graphics/ColumnStacked';
import PieHalfStacked from '../../../../components/graphics/PieHalfStacked';
// import Arc from '../../../../components/graphics/Arc';
// import Chord from '../../../../components/graphics/Chord';
import Graph2D from '../../../../components/graphics/Graph2D';
import { requestGet, requestPost } from '../../../../../utils/request';
import { metricsInv, getParamsRequest, formatMessage, objectAssignInv } from '../../../../../utils/utils';
import { constants, URL_API_INV, ACT_DEP } from '../../../../../constants';

function Projects(props) {
    const { investigation, settings, setInvestigation, setSettings } = props;

    const { t } = useTranslation();
    const params = useParams();
    const activeHierarchy = investigation.hierarchy.hierarchy.hierarchy[2];
    const activeReports = activeHierarchy !== 'DEPENDENCE_AUTHOR_FIELD' && !investigation.search.search && ACT_DEP.REP;
    const activeSearch = 'query' in params;
    const activeGraph = investigation.metrics.projects[constants.METRIC_GRAPH_FIELD].tools.graphType.value.value;

    const handleOnChange = (value, state) => {
        const newInvestigation = objectAssignInv(investigation, {
            metrics: { projects: { [state]: value } }
        });
        newInvestigation.filters.rangeTime.type = true;
        setInvestigation(
            metricsInv(
                [state],
                investigation.metrics.projects.source,
                investigation.hierarchy,
                newInvestigation,
                settings
            )
        );
    };

    useEffect(() => {
        setSettings({ backdrop: formatMessage(true, '', t('dependence.investigation.consultingInfoProjects')) });
        if (investigation.hierarchy.metrics.projects || investigation.search.search) {
            const hierarchy = {
                ...investigation.hierarchy,
                resource: 'Proyectos'
            };
            const paramsRequest = getParamsRequest(investigation.hierarchy.params);
            Promise.all([
                requestPost(`${URL_API_INV}/investigation/projects_metrics`, {
                    _id: investigation.hierarchy.metrics.projects,
                    search: investigation.search.search,
                    record: hierarchy
                }),
                requestGet(`${URL_API_INV}/reports/gender`, paramsRequest, activeReports),
                requestGet(`${URL_API_INV}/reports/gender-leaders`, paramsRequest, activeReports),
                requestGet(`${URL_API_INV}/reports/gender-types`, paramsRequest, activeReports)
            ])
                .then(res => {
                    setInvestigation(
                        metricsInv(
                            Object.keys(investigation.metrics.projects),
                            {
                                ...res[0].data,
                                [constants.METRIC_GENDER_LEADERS_FIELD]: {
                                    gender: res[1].data[0],
                                    gender_leaders: res[2].data[0]
                                },
                                [constants.METRIC_GENDER_TYPES_FIELD]: res[3].data[0]
                            },
                            hierarchy,
                            investigation,
                            settings
                        )
                    );
                    setSettings({ backdrop: formatMessage(false, '', '') });
                })
                .catch(err => {
                    setSettings({
                        backdrop: formatMessage(false, '', ''),
                        snackbar: formatMessage(true, 'error', err),
                    });
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [investigation.hierarchy.metrics.projects, investigation.search.search, settings.language]);

    return (
        <Box>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <CardGraphics
                        title={t('dependence.investigation.projectsMain.amountApprovedProjects')}
                        tools={{
                            usability: true,
                            rangeTime: true,
                            gender: false,
                            type: false,
                            accumulate: false,
                            wordCloud: false,
                            graph: false,
                            chart: false,
                            download: true,
                            dialog: true,
                            dataTable: true
                        }}
                        infoDialog={{
                            label: t('dependence.investigation.projectsMain.amountApprovedProjectsInfo'),
                            gif: require('../../../../../assets/images/animation/proyectos_aprobados.gif'),
                            notice: ''
                        }}
                        infoChart={{
                            data: investigation.metrics.projects[constants.METRIC_APPROBATION_FIELD],
                            onChange: (value) => handleOnChange(value, constants.METRIC_APPROBATION_FIELD),
                            columns: {
                                type: t('dependence.investigation.projectsMain.projectType'),
                                value: t('dependence.investigation.projectsMain.projectAmount'),
                            },
                            formatValue: 'number',
                            sortField: 'value'
                        }}
                    >
                        <PieDonut
                            infoChart={{
                                data: investigation.metrics.projects[constants.METRIC_APPROBATION_FIELD].processed,
                            }}
                        />
                    </CardGraphics>
                </Grid>
                <Grid item xs={12} md={6}>
                    <CardGraphics
                        title={t('dependence.investigation.projectsMain.amountApprovedProjectsByYear')}
                        tools={{
                            usability: true,
                            rangeTime: true,
                            gender: false,
                            type: false,
                            accumulate: true,
                            wordCloud: false,
                            graph: false,
                            chart: false,
                            download: true,
                            dialog: true,
                            dataTable: true
                        }}
                        infoDialog={{
                            label: t('dependence.investigation.projectsMain.amountApprovedProjectsByYearInfo'),
                            gif: require('../../../../../assets/images/animation/proyectos_aprobados_year.gif'),
                            notice: ''
                        }}
                        infoChart={{
                            data: investigation.metrics.projects[constants.METRIC_APPROBATION_YEAR_FIELD],
                            onChange: (value) => handleOnChange(value, constants.METRIC_APPROBATION_YEAR_FIELD),
                            columns: {
                                axis: t('dependence.investigation.year'),
                                type: t('dependence.investigation.projectsMain.projectType'),
                                value: t('dependence.investigation.projectsMain.projectAmount'),
                            },
                            formatValue: 'number',
                            sortField: 'axis'
                        }}
                    >
                        <ColumnStacked
                            infoChart={{
                                data: investigation.metrics.projects[constants.METRIC_APPROBATION_YEAR_FIELD].processed,
                                colorGender: false,
                                axis: {
                                    xAxis: t('dependence.investigation.year'),
                                    yAxis: t('dependence.investigation.projectsMain.projectAmount')
                                }
                            }}
                        />
                    </CardGraphics>
                </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: 0.5 }}>
                <Grid item xs={12} md={6}>
                    <CardGraphics
                        title={t('dependence.investigation.projectsMain.amountApprovedProjectStatuses')}
                        tools={{
                            usability: true,
                            rangeTime: true,
                            gender: false,
                            type: false,
                            accumulate: false,
                            wordCloud: false,
                            graph: false,
                            chart: false,
                            download: true,
                            dialog: true,
                            dataTable: true
                        }}
                        infoDialog={{
                            label: 'Muestra la Cantidad de estados de proyectos aprobados.',
                            gif: require('../../../../../assets/images/animation/proyectos_aprobados.gif'),
                            notice: ''
                        }}
                        infoChart={{
                            data: investigation.metrics.projects[constants.METRIC_STATE_FIELD],
                            onChange: (value) => handleOnChange(value, constants.METRIC_STATE_FIELD),
                            columns: {
                                type: t('dependence.investigation.projectsMain.projectStatus'),
                                value: t('dependence.investigation.projectsMain.projectAmount'),
                            },
                            formatValue: 'number',
                            sortField: 'value'
                        }}
                    >
                        <PieDonut
                            infoChart={{
                                data: investigation.metrics.projects[constants.METRIC_STATE_FIELD].processed,
                            }}
                        />
                    </CardGraphics>
                </Grid>
                <Grid item xs={12} md={6}>
                    <CardGraphics
                        title={t('dependence.investigation.projectsMain.amountApprovedProjectStatusesByYear')}
                        tools={{
                            usability: true,
                            rangeTime: true,
                            gender: false,
                            type: false,
                            accumulate: true,
                            wordCloud: false,
                            graph: false,
                            chart: false,
                            download: true,
                            dialog: true,
                            dataTable: true
                        }}
                        infoDialog={{
                            label: 'Muestra la Cantidad de estados de proyectos aprobados por año.',
                            gif: require('../../../../../assets/images/animation/proyectos_fc.gif'),
                            notice: ''
                        }}
                        infoChart={{
                            data: investigation.metrics.projects[constants.METRIC_STATE_YEAR_FIELD],
                            onChange: (value) => handleOnChange(value, constants.METRIC_STATE_YEAR_FIELD),
                            columns: {
                                axis: t('dependence.investigation.year'),
                                type: t('dependence.investigation.projectsMain.projectStatus'),
                                value: t('dependence.investigation.projectsMain.projectAmount'),
                            },
                            formatValue: 'number',
                            sortField: 'axis'
                        }}
                    >
                        <ColumnStacked
                            infoChart={{
                                data: investigation.metrics.projects[constants.METRIC_STATE_YEAR_FIELD].processed,
                                colorGender: false,
                                axis: {
                                    xAxis: t('dependence.investigation.year'),
                                    yAxis: t('dependence.investigation.projectsMain.projectAmount')
                                }
                            }}
                        />
                    </CardGraphics>
                </Grid >
            </Grid >
            <Grid container spacing={3} sx={{ mt: 0.5 }}>
                <Grid item xs={12} md={12}>
                    <CardGraphics
                        title={t('dependence.investigation.projectsMain.durationComparisonByMonth')}
                        tools={{
                            usability: true,
                            rangeTime: true,
                            gender: false,
                            type: false,
                            accumulate: false,
                            wordCloud: false,
                            graph: false,
                            chart: false,
                            download: true,
                            dialog: true,
                            dataTable: true
                        }}
                        infoDialog={{
                            label: t('dependence.investigation.projectsMain.durationComparisonByMonthInfo'),
                            gif: require('../../../../../assets/images/animation/proyectos_duración.gif'),
                            notice: ''
                        }}
                        infoChart={{
                            data: investigation.metrics.projects[constants.METRIC_DURATION_FIELD],
                            onChange: (value) => handleOnChange(value, constants.METRIC_DURATION_FIELD),
                            columns: {
                                group: t('dependence.investigation.projectsMain.projectDurationMonths'),
                                type: t('dependence.investigation.projectsMain.durationType'),
                                value: t('dependence.investigation.projectsMain.projectAmount'),
                            },
                            formatValue: 'number',
                            sortField: 'value'
                        }}
                    >
                        <ColumnGroup
                            infoChart={{
                                data: investigation.metrics.projects[constants.METRIC_DURATION_FIELD].processed,
                                colorGender: false,
                                axis: {
                                    xAxis: t('dependence.investigation.projectsMain.projectDurationMonths'),
                                    yAxis: t('dependence.investigation.projectsMain.projectAmount')
                                }
                            }}
                        />
                    </CardGraphics>
                </Grid>
            </Grid >
            <Grid container spacing={3} sx={{ mt: 0.5 }}>
                <Grid item xs={12} md={6}>
                    <CardGraphics
                        title={t('dependence.investigation.projectsMain.amountProjectsByType')}
                        tools={{
                            usability: true,
                            rangeTime: true,
                            gender: false,
                            type: false,
                            accumulate: false,
                            wordCloud: false,
                            graph: false,
                            chart: false,
                            download: true,
                            dialog: true,
                            dataTable: true
                        }}
                        infoDialog={{
                            label: t('dependence.investigation.projectsMain.amountProjectsByTypeInfo'),
                            gif: require('../../../../../assets/images/animation/tipologias_general.gif'),
                            notice: ''
                        }}
                        infoChart={{
                            data: investigation.metrics.projects[constants.METRIC_TYPOLOGY_FIELD],
                            onChange: (value) => handleOnChange(value, constants.METRIC_TYPOLOGY_FIELD),
                            columns: {
                                type: t('dependence.investigation.projectsMain.projectTypology'),
                                value: t('dependence.investigation.projectsMain.projectAmount'),
                            },
                            formatValue: 'number',
                            sortField: 'value'
                        }}
                    >
                        <PieDonut
                            infoChart={{
                                data: investigation.metrics.projects[constants.METRIC_TYPOLOGY_FIELD].processed,
                            }}
                        />
                    </CardGraphics>
                </Grid>
                <Grid item xs={12} md={6}>
                    <CardGraphics
                        title={t('dependence.investigation.projectsMain.amountProjectsByTypePerYear')}
                        tools={{
                            usability: true,
                            rangeTime: true,
                            gender: false,
                            type: false,
                            accumulate: true,
                            wordCloud: false,
                            graph: false,
                            chart: false,
                            download: true,
                            dialog: true,
                            dataTable: true
                        }}
                        infoDialog={{
                            label: t('dependence.investigation.projectsMain.amountProjectsByTypePerYearInfo'),
                            gif: require('../../../../../assets/images/animation/tipologias_anual.gif'),
                            notice: ''
                        }}
                        infoChart={{
                            data: investigation.metrics.projects[constants.METRIC_TYPOLOGY_YEAR_FIELD],
                            onChange: (value) => handleOnChange(value, constants.METRIC_TYPOLOGY_YEAR_FIELD),
                            columns: {
                                axis: t('dependence.investigation.year'),
                                type: t('dependence.investigation.projectsMain.projectTypology'),
                                value: t('dependence.investigation.projectsMain.projectAmount'),
                            },
                            formatValue: 'number',
                            sortField: 'axis'
                        }}
                    >
                        <ColumnStacked
                            infoChart={{
                                data: investigation.metrics.projects[constants.METRIC_TYPOLOGY_YEAR_FIELD].processed,
                                colorGender: false,
                                axis: {
                                    xAxis: t('dependence.investigation.year'),
                                    yAxis: t('dependence.investigation.projectsMain.projectAmount')
                                }
                            }}
                        />
                    </CardGraphics>
                </Grid >
            </Grid >
            <Grid container spacing={3} sx={{ mt: 0.5, display: activeHierarchy === 'DEPENDENCE_AUTHOR_FIELD' || activeSearch ? 'none' : 'flex' }}>
                <Grid item xs={12} md={6}>
                    <CardGraphics
                        title={t('dependence.investigation.projectsMain.leadersProportionByGender')}
                        tools={{
                            usability: true,
                            rangeTime: true,
                            gender: false,
                            type: false,
                            accumulate: false,
                            wordCloud: false,
                            graph: false,
                            chart: false,
                            download: true,
                            dialog: true,
                            dataTable: true
                        }}
                        infoDialog={{
                            label: t('dependence.investigation.projectsMain.leadersProportionByGenderInfo'),
                            gif: require('../../../../../assets/images/animation/lideres_proyectos.gif'),
                            notice: ''
                        }}
                        infoChart={{
                            data: investigation.metrics.projects[constants.METRIC_GENDER_LEADERS_FIELD],
                            onChange: (value) => handleOnChange(value, constants.METRIC_GENDER_LEADERS_FIELD),
                            columns: {
                                group: t('dependence.investigation.gender'),
                                type: t('dependence.investigation.type'),
                                value: t('dependence.investigation.amount'),
                                percent: t('dependence.investigation.percentage'),
                            },
                            formatValue: 'number',
                            sortField: 'percent'
                        }}
                    >
                        <PieHalfStacked
                            infoChart={{
                                data: investigation.metrics.projects[constants.METRIC_GENDER_LEADERS_FIELD].processed,
                                colorGender: true
                            }}
                        />
                    </CardGraphics>
                </Grid>
                <Grid item xs={12} md={6}>
                    <CardGraphics
                        title={t('dependence.investigation.projectsMain.projectDirectorsByGender')}
                        tools={{
                            usability: true,
                            rangeTime: true,
                            gender: false,
                            type: false,
                            accumulate: false,
                            wordCloud: false,
                            graph: false,
                            chart: false,
                            download: true,
                            dialog: true,
                            dataTable: true
                        }}
                        infoDialog={{
                            label: t('dependence.investigation.projectsMain.projectDirectorsByGenderInfo'),
                            gif: require('../../../../../assets/images/animation/tipologia_proyecto_genero.gif'),
                            notice: ''
                        }}
                        infoChart={{
                            data: investigation.metrics.projects[constants.METRIC_GENDER_TYPES_FIELD],
                            onChange: (value) => handleOnChange(value, constants.METRIC_GENDER_TYPES_FIELD),
                            columns: {
                                axis: t('dependence.investigation.projectsMain.typologyType'),
                                type: t('dependence.investigation.gender'),
                                value: t('dependence.investigation.projectsMain.directorsAmount')
                            },
                            formatValue: 'number',
                            sortField: 'value'
                        }}
                    >
                        <ColumnStacked
                            infoChart={{
                                data: investigation.metrics.projects[constants.METRIC_GENDER_TYPES_FIELD].processed,
                                colorGender: true,
                                axis: {
                                    xAxis: t('dependence.investigation.projectsMain.typologyType'),
                                    yAxis: t('dependence.investigation.projectsMain.directorsAmount')
                                }
                            }}
                        />
                    </CardGraphics>
                </Grid >
            </Grid >
            <Grid container spacing={3} sx={{ mt: 0.5, display: activeHierarchy === 'DEPENDENCE_AUTHOR_FIELD' ? 'block' : 'none' }}>
                <Grid item xs={12} md={12}>
                    <CardGraphics
                        title={t('dependence.investigation.projectsMain.generalCollaborations')}
                        tools={{
                            usability: true,
                            rangeTime: true,
                            gender: false,
                            type: false,
                            accumulate: false,
                            wordCloud: false,
                            // ACTIVAR
                            graph: true,
                            // ACTIVAR
                            chart: false,
                            download: true,
                            dialog: false,
                            dataTable: false
                        }}
                        infoDialog={{
                            label: '',
                            gif: '',
                            notice: ''
                        }}
                        infoChart={{
                            data: investigation.metrics.projects[constants.METRIC_GRAPH_FIELD],
                            onChange: (value) => handleOnChange(value, constants.METRIC_GRAPH_FIELD),
                            columns: {
                                type: '',
                                value: '',
                            },
                            formatValue: 'number',
                            sortField: ''
                        }}
                    >
                        <Graph2D
                            infoChart={{
                                data: investigation.metrics.projects[constants.METRIC_GRAPH_FIELD].processed
                            }}
                        />
                        {/* {activeGraph === constants.GRAPHS_TYPE.FORCE && (
                            <Graph2D
                                infoChart={{
                                    data: investigation.metrics.projects[constants.METRIC_GRAPH_FIELD].processed
                                }}
                            />
                        )}
                        {activeGraph === constants.GRAPHS_TYPE.ARC && (
                            <Arc
                                infoChart={{
                                    data: investigation.metrics.projects[constants.METRIC_GRAPH_FIELD].processed,
                                }}
                            />
                        )}
                        {activeGraph === constants.GRAPHS_TYPE.CHORD && (
                            <Chord
                                infoChart={{
                                    data: investigation.metrics.projects[constants.METRIC_GRAPH_FIELD].processed,
                                }}
                            />
                        )} */}
                    </CardGraphics>
                </Grid>
            </Grid >
        </Box>
    )
}
const mapStateToProps = (state) => ({
    investigation: state.investigation,
    settings: state.settings
});
const mapDispatchToProps = (dispatch) => ({
    setInvestigation(state) {
        dispatch({
            type: 'SET_INVESTIGATION',
            state,
        });
    },
    setSettings(state) {
        dispatch({
            type: 'SET_SETTINGS',
            state,
        });
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(Projects);