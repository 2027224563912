import { G2 } from '@antv/g2plot';

const { registerTheme } = G2;

export const configGraph2D = {
    backgroundColor: '#FFFFFF',
    linkColor: '#F73435'
};

export const configGraph3D = {
    backgroundColor: '#FFFFFF',
    linkColor: '#F73435'
};

export const configDefault = {
    height: 350,
    legend: {
        layout: 'horizontal',
        position: 'bottom',
    },
    theme: 'theme',
};

export const configBarGrouped = {
    ...configDefault,
    isGroup: true,
    xField: 'value',
    yField: 'group',
    seriesField: 'type',
};

export const configColumnStacked = {
    ...configDefault,
    isStack: true,
    xField: 'axis',
    yField: 'value',
    seriesField: 'type',
};

export const configPercent = {
    ...configDefault,
    xField: 'axis',
    yField: 'value',
    seriesField: 'type',
    isPercent: true,
    isStack: true,
};

export const configColumn = {
    ...configDefault,
    xField: 'axis',
    yField: 'value',
};

export const configMultiLine = {
    ...configDefault,
    xField: 'axis',
    yField: 'value',
    seriesField: 'type',
};

export const configLine = {
    ...configDefault,
    xField: 'type',
    yField: 'value',
};

export const configBarRadial = {
    ...configDefault,
    xField: 'type',
    yField: 'value',
    radius: 1,
    innerRadius: 0.3,
    startAngle: Math.PI * 0.5,
    endAngle: Math.PI * 2.5,
    colorField: 'type',
    barStyle: { lineCap: 'round' },
};

export const configColumnGroup = {
    ...configDefault,
    isGroup: true,
    xField: 'group',
    yField: 'value',
    seriesField: 'type',
};

export const configBoxesTree = {
    ...configDefault,
    colorField: 'name',
    interactions: [
        { type: 'view-zoom' },
        { type: 'drag-move' },
        { type: 'treemap-drill-down' }
    ],
    tooltip: {
        formatter: (v) => {
            return {
                title: v.path[1].data.name,
                name: v.path[0].data.name,
                value: v.path[0].data.value + (v.path[0].data?.children ? ' (Clic para abrir el siguiente nivel)' : '')
            };

        },
        showTitle: true
    },
    drilldown: {
        breadCrumb: {
            rootText: 'Inicio'
        }
    },
}
export const configCirclePacking = {
    ...configDefault,
    height: 450,
    padding: [0, 0, 40, 0],
    autoFit: true,
    hierarchyConfig: {
        padding: 0.01,
    },
    label: {
        formatter: ({ name }) => {
            return name !== 'root' ? name : '';
        },
        offsetY: 11,
        style: {
            fontSize: 14,
            textAlign: 'center',
            fontFamily: 'Ancizar sans',
            fill: '#000000'
        },
    }
}
export const configPie = {
    ...configDefault,
    appendPadding: 5,
    angleField: 'value',
    colorField: 'type',
    radius: 0.8,
    label: {
        type: 'inner',
        offset: '-30%',
        content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
        style: {
            fontSize: 16,
            textAlign: 'center',
            fontFamily: 'Ancizar sans',
        },
    },
    interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
};

export const configPieDonut = {
    ...configDefault,
    appendPadding: 10,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.65,
    label: false,
    interactions: [
        { type: 'element-selected' },
        { type: 'element-active' },
        { type: 'pie-statistic-active' },
    ],
};

export const configPieHalf = {
    ...configDefault,
    angleField: 'value',
    colorField: 'type',
    startAngle: Math.PI,
    endAngle: Math.PI * 2,
    radius: 0.8,
    label: {
        type: 'inner',
        offset: '-30%',
        content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
        style: {
            fontSize: 16,
            textAlign: 'center',
            fontFamily: 'Ancizar sans',
        },
    },
    interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
};

export const configPieHalfStacked = {
    ...configDefault,
    height: 175,
    angleField: 'value',
    colorField: 'type',
    startAngle: Math.PI,
    endAngle: Math.PI * 2,
    radius: 0.8,
    label: {
        type: 'inner',
        offset: '-30%',
        content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
        style: {
            fontSize: 16,
            textAlign: 'center',
            fontFamily: 'Ancizar sans',
        },
    },
    interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
};

export const configWordCloud = {
    ...configDefault,
    wordField: 'name',
    weightField: 'value',
    colorField: 'name',
    wordStyle: {
        fontFamily: 'Ancizar sans',
        fontSize: [15, 50],
    },
    random: () => 0.5,
};

export const configChord = {
    ...configDefault,
    height: configDefault.height + 50,
    sourceField: 'source',
    targetField: 'target',
    weightField: 'value',
    padding: [35, 35, 35, 35],
    label: {
        offset: 3,
        style: {
            fill: '#000',
            fontSize: 13.5,
            fontFamily: 'Ancizar sans'
        }
    }
};

export const configColumnGroupStacked = {
    ...configDefault,
    xField: 'axis',
    yField: 'value',
    seriesField: 'type',
    groupField: 'group',
    isGroup: true,
    isStack: true
};

export const configBar = {
    height: 350,
    xField: 'value',
    yField: 'type',
    barWidthRatio: 0.8,
    label: {
        content: (item) => {
            return item.type;
        },
        position: 'left',
        style: {
            fontSize: 14,
            fontFamily: 'Ancizar sans',
            fill: '#000000',
        },
    },
    yAxis: false,
    color: '#1890FF',
    theme: 'theme',
};

export const configColor = [
    '#1890FF',
    '#04297A',
    '#7f2eff',
    '#ff2e44',
    '#2effe8',
    '#74BA43',
    '#00ff80',
    '#006699',
    '#3399cc',
    '#990066',
    '#cc3399',
    '#ff6600',
    '#ff9900',
    '#ff007f',
    '#0000ff',
    '#99cc33',
    '#a6ef52',
    '#70e458',
    '#5668f0',
    '#db2c29',
    '#36e28c',
    '#9832f1',
    '#a6f462',
    '#c851fb',
    '#306adf',
    '#4b69ec',
    '#66f5d4',
    '#e644e9',
    '#2cdd79',
    '#fda22b',
    '#efdf2e',
    '#8b63e9',
    '#60fc27',
    '#eed372',
    '#3df562',
    '#2bb3ee',
    '#be49f8',
    '#55fc8a',
    '#41abf6',
    '#e240e7',
    '#e14133',
    '#67f514',
    '#fb9223',
    '#f566dd',
    '#e963a6',
    '#5dfd98',
    '#4768eb',
    '#266ed9',
    '#4468e9',
    '#3a6ae4',
    '#fb5162',
    '#db3de6',
    '#1bf8de',
    '#ee4fe8',
    '#51fb84',
    '#8c2bee',
    '#fc9827',
    '#63dbe9',
    '#1de720',
    '#a036f2',
    '#e71d6a',
    '#e4b158',
    '#f3f039',
    '#72ed6e',
    '#c34df9',
    '#10f4ca',
    '#41f668',
    '#5da3fd',
    '#49a6f8',
    '#f66add',
    '#3de697',
    '#68f410',
    '#62f718',
    '#e255a7',
    '#f94d61',
    '#f5f53d',
    '#366ae2',
    '#2bfdf6',
    '#ef7c52',
    '#ec4be9',
    '#c730df',
    '#4df97e',
    '#23fbe9',
    '#6a6cf6',
    '#956aec',
    '#3d6ae6',
    '#f84960',
    '#f53d62',
    '#6fe255',
    '#7455e2',
    '#e9206a',
    '#a9eb47',
    '#f57614',
    '#afe133',
    '#4067e7',
    '#8124eb',
    '#ea66a6',
    '#e9c163',
    '#7720e9',
    '#f8861b',
    '#a6f35e',
    '#df3b30',
    '#a6f056',
    '#4e2bfd',
    '#e24736',
    '#d136e2',
    '#14f5d0',
    '#ba29db',
    '#1dbbe7',
    '#36adf2',
    '#39f35c',
    '#f410c2',
    '#336ae1',
    '#fe6264',
    '#62a3fe',
    '#2718f7',
    '#3ae492',
    '#f74563',
    '#60fb23',
    '#e458a7',
    '#cc33e1',
    '#e96244',
    '#815ce6',
    '#19bde6',
    '#b645f7',
    '#ec6e4b',
    '#f25ae2',
    '#f718bb',
    '#e2fe62',
    '#55a6fc',
    '#24b6eb',
    '#e0a752',
    '#73ec6a',
    '#a4f66a',
    '#351ff9',
    '#6ee052',
    '#ef2e65',
    '#f49262',
    '#20e927',
    '#ec6aa7',
    '#32b1f1',
    '#eac566',
    '#fc5964',
    '#f462e1',
    '#52e0e0',
    '#71ea66',
    '#b526d9',
    '#4da6f9',
    '#922eef',
    '#f056e3',
    '#f23662',
    '#f08256',
    '#e3fd5d',
    '#5fdce7',
    '#27b4ec',
    '#59a5fc',
    '#3c23fb',
    '#eb47eb',
    '#6adaec',
    '#e6b85c',
    '#eb2466',
    '#58dfe4',
    '#e6533d',
    '#fd2bb0',
    '#f35ee1',
    '#f2690d',
    '#55e0e2',
    '#45aaf7',
    '#6b19e6',
    '#dd322c',
    '#e6bd19',
    '#69f20d',
    '#e65ca5',
    '#b141f6',
    '#fc5563',
    '#74ee72',
    '#24eb31',
    '#2eef45',
    '#5a69f2',
    '#a9ec4b',
    '#2e1bf8',
    '#5267ef',
    '#fc27b5',
    '#59fc90',
    '#a9ee4f',
    '#b4db29',
    '#f91fb8',
    '#4feeb3',
    '#edf849',
    '#2bee3e',
    '#ef52e5',
    '#66dbea',
    '#d85dfd',
    '#f2875a',
    '#f33962',
    '#eb6847',
    '#e7bc5f',
    '#61f91f',
    '#19e619',
    '#3dabf5',
    '#626bf4',
    '#e8fc55',
    '#6fe75f',
    '#ecd527',
    '#5cfe2f',
    '#9066ea',
    '#f46f10',
    '#36f255',
    '#f1f745',
    '#62f4cd',
    '#fea82f',
    '#d159fc',
    '#f514c1',
    '#afe236',
    '#47eba9',
    '#6ee65c',
    '#70e963',
    '#40e79f',
    '#e7fc59',
    '#52efbb',
    '#2ffefb',
    '#5cdce6',
    '#ade63d',
    '#e2ac55',
    '#f1e432',
    '#711de7',
    '#e9c820',
    '#ebf94d',
    '#ee72a6',
    '#ee2b65',
    '#27fcee',
    '#ab3df5',
    '#61f81b',
    '#ee744f',
    '#ecc96a',
    '#eeda2b',
    '#0df2c4',
    '#f59a66',
    '#18f7d9',
    '#20bae9',
    '#8627ec',
    '#b0df30',
    '#bf2cdd',
    '#5cfd2b',
    '#ed6ea5',
    '#f98c1f',
    '#aae944',
    '#0d0df2',
    '#2eb2ef',
    '#a172ee',
    '#f38d5e',
    '#6ed8ed',
    '#29db73',
    '#4327fc',
    '#e75fa5',
    '#49f878',
    '#39acf3',
    '#5af2c4',
    '#cc55fc',
    '#e052a7',
    '#4becae',
    '#aee43a',
    '#fb23b6',
    '#e75c40',
    '#44e9a4',
    '#d63ae4',
    '#b5d926',
    '#fd5d63',
    '#e44d3a',
    '#edcf6e',
    '#1b14f5',
    '#4f69ee',
    '#45f76f',
    '#fe2faf',
    '#ebd024',
    '#9c6eed',
    '#ec2766',
    '#51a6fb',
    '#6e52e0',
    '#5e6af3',
    '#f2ec36',
    '#1410f4',
    '#e7c21d',
    '#30df81',
    '#2c6ddd',
    '#dc62fe',
    '#33e187',
    '#b4dd2c',
    '#f81bb9',
    '#32f14b',
    '#d92626',
    '#a6f25a',
    '#5ef3c9',
    '#7958e4',
    '#f6a06a',
    '#6af6d8',
    '#f20dc4',
    '#72d8ee',
    '#62fe9e',
    '#1ff9e3',
    '#865fe7',
    '#a4f566',
    '#56f0bf',
    '#27ec38',
    '#666af5',
    '#e6196b',
    '#296ddb',
    '#f13265',
    '#552ffe',
    '#f3f641',
    '#aae740',
    '#a639f3',
    '#eafb51',
    '#f64162',
    '#26d96e',
    '#f78018'
]
export const genderColorMap = {
    Femenino: '#D86666',
    Directoras: '#D86666',
    Masculino: '#207093',
    Directores: '#207093',
    'Total femenino': '#D3D1CE',
    'Total masculino': '#D3D1CE',
    'No directoras': '#D3D1CE',
    'No directores': '#D3D1CE',
    Female: '#D86666',
    'Female directors': '#D86666',
    Male: '#207093',
    'Male directors': '#207093',
    'Total female': '#D3D1CE',
    'Total male': '#D3D1CE',
    'No female directors': '#D3D1CE',
    'No male directors': '#D3D1CE',
    '': '#D3D1CE',
    'F': '#D86666',
    'M': '#207093'
}
export const configColorGender = {
    colorField: 'type',
    color: ({ type }) => genderColorMap[type]
}
export const configColorGenderF = ['#207093', '#D3D1CE'];
export const configColorGenderM = ['#D86666', '#D3D1CE'];
const g2Theme = {
    styleSheet: {
        fontFamily: 'Ancizar sans',
    },
    components: {
        axis: {
            common: {
                label: {
                    style: {
                        fill: '#000000',
                        fontSize: 14,
                        fontFamily: 'Ancizar sans',
                    },
                },
            },
        },
        tooltip: {
            domStyles: {
                'g2-tooltip': {
                    color: '#000000',
                    fontSize: 14,
                    fontFamily: 'Ancizar sans',
                },
            },
        },
        legend: {
            common: {
                itemName: {
                    style: {
                        fill: '#000000',
                        fontFamily: 'Ancizar sans',
                        fontSize: 14,
                        textAlign: 'start',
                    },
                },
            },
            top: {
                layout: 'horizontal',
                padding: [10, 0, 20, 0],
            },
            bottom: {
                layout: 'horizontal',
                padding: [20, 0, 10, 0],
            },
        },
    },
    colors10: configColor.slice(0, 10),
    colors20: configColor,
    genderColor: genderColorMap
};

registerTheme('theme', g2Theme);
export default g2Theme;